/* eslint-disable react/destructuring-assignment */
import { isNil, omitBy } from 'lodash';
import {
  ClientMatchPresentingProblem,
  LDFlags,
  PayerOptionType,
  ServiceType,
} from 'ts-frontend/types';
import {
  getPresentingProblemsByIds,
  getFieldsOfExpertise,
  simplifiedPresentingProblems,
  sleep,
  OUT_OF_POCKET_OPTION,
  ORGANIZATION_OPTION,
} from 'ts-frontend/helpers';
import isEqual from 'lodash/isEqual';
import { debug, enabled as debugEnabled } from 'ts-frontend/utils/dev-console';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Component, createRef, createContext, useContext, RefObject } from 'react';
import moment from 'moment';
import compose from 'lodash/fp/compose';
import { states } from '@talkspace/configs';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import { OUT_OF_STATE_OPTION } from 'ts-frontend/constants';
import { FlagsContext } from 'launchDarkly/FlagsProvider';
import { SetCustomAttributesProp, withSetCustomAttributes } from 'launchDarkly/hooks';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import {
  getGDPRProps,
  getGeolocationFromOneTrustSDK,
  isGDPRCountry,
  storeGDPRProps,
} from 'ts-frontend/helpers/gdpr';
import { fromPageSource } from 'ts-analytics/types';
import { InitialQuestionSkipperProp, withInitialQuestionSkipper } from './InitialQuestionSkipper';
import {
  HomePageActions,
  HomePageState,
  RoomType,
  GenderType,
  PAYFIRST_ANALYTIC_IDS,
  therapistGenderPreferenceDict,
  StepAnswer,
  FiltersHomePageState,
  AnswerValue,
  ChosenPlan,
  FunnelVariation,
  recoveredStateFromHomePageState,
  isAccountType,
  AccountType,
  InsuranceDetails,
} from './types';
import StepLayout from '../StepLayout/StepLayout';
import Page from '../Page/Page';
import getParamByName from '../../utils/queryString';
import { LandingPageCTAClick } from '../../utils/analytics/trackerTypes';
import { VWO } from '../../utils/analytics/eventTracker';
import { trackEvent, setFlowSuperProperty, trackGTMEvent } from '../../utils/analytics/events';
import {
  isMatchesStep,
  isStateCountrySelectionStep,
  isPresentingProblemsStep,
  isLoadingStep,
  isSchedulerStep,
  isCurrentProviderStep,
  isDropdownStep,
  isDispatcherInQMStep,
  isOneFormEligibilityStep,
  isDateOfBirthStep,
  isBHCopayStep,
} from '../../Helpers/stepTypeHelper';
import mapSelfServeFields from '../../Helpers/selfServeFields';
import { countries } from '../../Helpers/locales';
import { getQMFunnelName, isB2BFlow } from '../../Helpers/flowsHelper';
import {
  getInternationalFieldOfExpertise,
  getAdolescentFieldOfExpertise,
} from '../../Helpers/fieldsOfExpertiseHelper';
import {
  checkProviderRematchAvailability,
  getFlowConfigByFlowID,
  getMatchTherapists,
  getPublicAdminConfigJsonValue,
} from '../../Helpers/apiService';
import { ageGroup, ageGroupId, ageGroupLabel } from '../../Helpers/ageHelper';
import {
  FlowStep,
  InternalTarget,
  UpdateStep,
  UpdateStepObj,
  NextQuestionAction,
  NextQuestionActionForTarget,
  PresentingProblemsStep,
  FlowConfig,
} from '../../Flows/types';
import flows, {
  getStepFromFlowID,
  FLOW_90_SELECT_SERVICE,
  FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY,
  FLOW_101_ELIGIBILITY_QUESTIONS_COUPLES_THERAPY,
  FLOW_102_ELIGIBILITY_QUESTIONS_PSYCHIATRY,
  FLOW_103_ELIGIBILITY_QUESTIONS_TEEN_THERAPY,
  FLOW_106_CONSUMER_WALMART,
  FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
  FLOW_200_EXPERIMENTS,
  FLOW_128_NYC_TEENS,
  FLOW_132_CONSUMER_THERAPY,
  getStepFromFlowIDByID,
  FLOW_133_ISRAEL,
  getClientFlow,
  FLOW_148_NEW_USER_JWT_EXPIRED,
} from '../../Flows';
import ReactFrameService from '../../utils/reactFrame/ReactFrameService';
import apiWrapper from '../../core/api/apiWrapper';
import { shouldSkipOrRemovePreferredModality } from '../../Helpers/preferredModalityHelper';
import { getInsuranceVerificationFlowID } from '../../Helpers/insurancePayersHelper';
import {
  PLAN_REVIEW_BH,
  PLAN_REVIEW_EAP_MESSAGE,
  PLAN_REVIEW_EAP_VIDEO,
  PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE,
} from '../../utils/reviewPlan';
import { getParsedUserIDFromQMSession } from '../../Helpers/registrationHelpers';
import { getPresentingProblemList } from '../../Helpers/presentingProblemsHelper';
import { QM_COPY } from '../../utils/qmCopy';
import dataDependencyHandlers from '../../Helpers/dataDependencyHandlers';
import getRedirectTargetID from '../../Helpers/redirect';

const BLUR_CONTENT_RADIUS = 6;
const FORBIDDEN_RESPONSES_KEYS: (keyof UpdateStepObj)[] = [
  'go_to_matches',
  'isNoMatches',
  'accessCode',
  'accessCodeType',
  'organizationFlowID',
  'b2bForkResult',
  'insurancePayer',
];

const MEMBER_AVAILABILITY_STEP = 135;

export const HomePageContext = createContext<HomePageState | null>(null);
export const HomePageActionsContext = createContext<HomePageActions | undefined>(undefined);

interface HomePageProps extends SetCustomAttributesProp, InitialQuestionSkipperProp {
  session: RecoveredSessionState;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
  cognitoActive: boolean;
}

type Props = HomePageProps & RouteComponentProps<{ flowId?: string; stepId?: string }>;

class HomePage extends Component<Props, HomePageState> {
  state: HomePageState = {
    stepAnswers: [],
    countries: [],
    responses: [],
    therapistId: 0,
    livesInTheUS: true,
    isGDPR: undefined,
    shouldResetLivingInfo: false,
    clientAgeTitle: undefined,
    clientAge: undefined,
    clientAgeNumber: undefined,
    clientDateOfBirth: undefined,
    clientGender: undefined,
    clientMaritalStatus: undefined,
    clientLanguage: undefined,
    clientState: undefined,
    clientCountry: undefined,
    clientMatchExpertisePreference: undefined,
    maxResults: 100,
    answerParams: {},
    voucher: '',
    matches: null,
    invalidVoucher: false,
    opacity: 0,
    isMatchesOverlayDismissed: false,
    isFiltersModalVisible: false,
    qmPartnerCode: '',
    cpPartnerCode: '',
    copayCents: '',
    insuranceCode: '',
    redirectFrom: 0,
    flowId: 0,
    stepId: 0,
    step: null,
    flowConfig: undefined,
    exactMatch: false,
    // TODO: Set this to undefined without breaking QM
    chosenPlan: {} as ChosenPlan,
    appointment: undefined,
    therapistFirstName: '',
    therapistLastName: '',
    therapistTitle: '',
    therapistLicenses: null,
    currentProvidersInfo: null,
    hasOnlyPlaceholderProviders: null,
    clientMatchPresentingProblems: [],
    clientMatchGenderPreference: undefined,
    clientMatchEthnicityPreference: undefined,
    isDisabledPreviousButton: false,
    funnelVariation: null,
    service: null,
    currentTier: 0,
    trizettoErrorStatusCode: 0,
    isCouldNotBeVerified: false,
    trizettoRequest: null,
    coupon: new URLSearchParams(window.location.search).get('cc') || '',
    couponApplied: false,
    restoringSession: false,
    keepProvider: false,
    isHideTeenServiceType: false,
    isSkipDirectToBH: false,
    isHideNonBhItems: false,
    skipCapacityCheck: false,
    isCreateRoomFlow: false,
    isReactivationFlow: false,
    isUpdateCoverageFlow: false,
    isMBHIneligibilityFlow: false,
    roomID: null,
    existingUserEmail: '',
    insuranceEligibility: undefined,
    isQMFlowDone: false,
    isPsychiatryRoom: false,
    redirectFromFlowConfig: undefined,
    roomType: 'privateRoom',
    shouldBlurContent: false,
    isConfirmScheduler: false,
    isNoMatches: false,
    loadingMatches: false,
    isVideoOnlyPlan: false,
    organizationFlowID: undefined,
    b2bForkResult: undefined,
    insurancePayer: undefined,
    partnerFlowID: undefined,
    isB2BOutOfNetwork: undefined,
    experimentsVariants: undefined,
    collectReferralSourceOnSignUp: false,
    referralSource: '',
    allowedModalities: [],
    totalSessions: 0,
    stepsToSkip: [],
    hideVWOBar: false,
    reviewPlanDetails: undefined,
    accountType: null,
    manualFlowID: undefined,
    updateCoverageRoomID: undefined,
    oldRoomHasSessions: false,
    sessionsTransferred: undefined,
    sessionsCanceled: undefined,
    eligibilityFiles: null,
    quickEligibilityInfo: {
      firstName: null,
      lastName: null,
      memberID: null,
      groupID: null,
      payerID: null,
      dob: null,
      state: null,
    },
    memberAvailability: undefined,
    manualVerificationEmail: '',
    previousTherapistId: null,
    matchingLanguages: null,
    overriddenBackButtonBehavior: undefined,
    remoteFlowConfigs: undefined,
    isRegistrationError: null,
    therapistIDFromCreateRoom: undefined,
    isPayerOutage: undefined,
    insuranceDetails: {
      firstName: null,
      lastName: null,
      dob: null,
      state: null,
      gediPayerID: null,
      payerName: null,
      memberID: null,
      dependentRelationship: null,
      copayCents: null,
      isEligible: false,
      isVideoOnlyPlan: false,
      verificationSucceeded: true,
    },
    isReturningUserFlow: false,
  };

  pageRef: RefObject<HTMLDivElement>;

  headerRef: RefObject<HTMLDivElement>;

  nextStepSearchRef: string | null = null;

  discardMatches = true;

  constructor(props: Props) {
    super(props);
    const { session } = props;
    const search = new URLSearchParams(this.props.location.search);
    const redirectFrom = Number(search.get('redirectFrom'));
    if (redirectFrom) {
      const redirectFromFlow = this.getFlow(redirectFrom, false);
      if (redirectFromFlow) this.state.redirectFromFlowConfig = redirectFromFlow.flowConfig;
    }
    this.pageRef = createRef();
    this.headerRef = createRef();
    const flow = this.getFlow(this.getFlowId({ overrideRestoringSession: true }), false);

    if (!flow) return;

    this.state.flowConfig = flow.flowConfig;

    if (
      this.getHasRecoveredSession() &&
      (redirectFrom ? session.flowId === Number(props.match.params.flowId) : true)
    ) {
      this.state.restoringSession = true;
      this.state.flowId = flow.flowId;
      this.state.stepId = this.getInitialStepId();
      // Extra fields to restore
      recoveredStateFromHomePageState.forEach((key) => {
        if (session[key] !== undefined) {
          const value = session[key] as HomePageState[typeof key];
          // Couldn't figure out how to narrow down the type of key to be one instance of the union type
          // @ts-ignore
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state[key] = value as any;
        }
      });
      const sessionPath = `/flow/${this.state.flowId}/step/${this.state.stepId}`;
      props.history.replace(`${sessionPath}${search.toString() && `?${search.toString()}`}`);
    } else {
      this.state.opacity = 1;
      this.state.restoringSession = false;
      if (Array.isArray(session.stepAnswers) && session.stepAnswers.length > 0) {
        // Keep track of stepAnswers before redirect
        this.state.stepAnswers = session.stepAnswers;
      }
    }
    this.state.experimentsVariants = session.experimentsVariants;
  }

  componentDidMount() {
    if (!this.state.flowConfig) {
      this.props.history.push('/dispatcher');
      return;
    }
    const stepId = this.getInitialStepId();

    this.setFlowId();
    this.setStep(stepId);
    this.setPartnerCodes();
    this.setRedirectParam();
    this.setClientAge();
    this.setClientAgeToAgeGroup();
    this.syncClientAgeNumberWithStorage();
    this.setClientMatchExpertisePreference();
    this.setClientMatchGenderPreference();
    this.setClientMatchEthnicityPreference();
    this.setCopayCents();
    this.setInsuranceCode();
    this.setClientCountryAndStateFromOneTrust();
    this.setClientCountryAndStateFromParams();
    this.setTrizettoRequest();
    this.setServiceType();
    this.saveSearchInSession();
    this.setRoomType();
    this.setIsVideoOnlyPlan();
    this.setFlowParameters();
    this.setCoupon();
    this.setFunnelVariation();
    this.setCurrentProvidersInfo();
    this.setCollectReferralSourceOnSignUp();
    this.updateAllowedModalities();
    this.setInsurancePayer();
    this.setCountryAndState();
    this.setHideVWOBar();
    this.setUpdateRoom();
    this.setPreviousTherapistId();

    this.trackPageLoad(); // TODO check with SJ this tracker

    if (this.state.restoringSession) this.restoreState(this.props.session);

    if (this.getFlowId() === FLOW_133_ISRAEL) {
      this.setState((state) => {
        return {
          isNoMatches: true,
          responses: [
            ...state.responses,
            {
              flowId: FLOW_133_ISRAEL,
              livesInTheUS: false,
              response_category_id: 6,
              response_prompt: 'Israel',
              response_value: 'IL',
            },
          ],
        };
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Triggered on history change, via browser back button click or a call to this.goBack()
      window.onpopstate = () => {
        this.handleGoBack();
      };

      // remove the custom back button behavior from the state
      this.setOverriddenBackButtonBehavior(undefined);
      this.setIsRegistrationError(null);

      this.setFlowId();
      this.setFlowConfig(() => {
        this.setServiceType();
      });
      this.setStep();
      this.setPartnerCodes();
      this.setRedirectParam();
      this.setClientAge();
      this.setClientAgeToAgeGroup();
      this.syncClientAgeNumberWithStorage();
      this.setClientMatchExpertisePreference();
      this.setClientMatchGenderPreference();
      this.setClientMatchEthnicityPreference();
      this.setCopayCents();
      this.setInsuranceCode();
      this.setClientCountryAndStateFromParams(); // Legacy
      this.setCountryAndState();
      this.setTrizettoRequest();
      this.saveSearchInSession();
      this.setRoomType();
      this.setFunnelVariation();
      this.setIsVideoOnlyPlan();
      this.updateAllowedModalities();
      this.setReviewPlanDetails();
      this.setRedirectFromFlowConfig();
    }
    if (this.state.step !== prevState.step && this.state.step) {
      // Triggered on navigating to a new step
      const { step } = this.state;
      if (isStateCountrySelectionStep(step)) {
        this.setClientCountryFromBrowserLanguage();
      } else if (isLoadingStep(step)) {
        this.getMatches();
      }
    }

    this.verifyFlow200Experiments(
      this.props.match.params.flowId ? parseInt(this.props.match.params.flowId, 10) : null
    );

    if (debugEnabled()) {
      if (!isEqual(this.state, prevState)) {
        debug('HomePage state has changed', this.state);
      }
    }
    this.props.questionSkipperStateRefCallback?.(this.state);

    if (
      !this.state.remoteFlowConfigs ||
      !Object.keys(this.state.remoteFlowConfigs).includes(this.getFlowId().toString())
    ) {
      this.getRemoteFlowConfig();
    }
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  setHomePageState = (partialState: Partial<HomePageState>) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        ...partialState,
      };
    });
  };

  setOverriddenBackButtonBehavior = (onPressBack: (() => void) | undefined) => {
    this.setState({ overriddenBackButtonBehavior: onPressBack });
  };

  setIsRegistrationError = (isError: boolean | null) => {
    this.setState({ isRegistrationError: isError });
  };

  get isLoggedInUser() {
    return (
      this.state.isCreateRoomFlow ||
      this.state.isReactivationFlow ||
      this.state.isUpdateCoverageFlow ||
      this.state.isMBHIneligibilityFlow
    );
  }

  setRedirectFromFlowConfig = () => {
    const search = new URLSearchParams(this.props.location.search);
    const redirectFrom = Number(search.get('redirectFrom'));
    if (redirectFrom) {
      const redirectFromFlow = this.getFlow(redirectFrom, false);
      if (redirectFromFlow) {
        this.setState({ redirectFromFlowConfig: redirectFromFlow.flowConfig });
      }
    }
  };

  setQMFlowDone = () => {
    this.setState({ isQMFlowDone: true });
  };

  setRoomID = (roomID, callback = () => undefined) => {
    this.setState({ roomID }, callback);
  };

  setCreateRoomReturnProps = ({
    oldRoomHasSessions,
    sessionsTransferred,
    sessionsCanceled,
    therapistID,
  }: {
    oldRoomHasSessions: boolean;
    sessionsTransferred: boolean;
    sessionsCanceled: boolean;
    therapistID?: number;
  }) => {
    this.setState((prevState) => {
      return {
        oldRoomHasSessions,
        sessionsTransferred,
        sessionsCanceled,
        therapistIDFromCreateRoom: therapistID || prevState.therapistId,
      };
    });
  };

  setIsCouldNotBeVerified = (isNotVerified: boolean) => {
    this.setState({ isCouldNotBeVerified: isNotVerified });
  };

  /**
   * Each time the component updates, this function checks to see if the user is in flow 90 (service selection)
   * or flow 200 (the experimental flow). If they are, then we ensure that the experimental flags are set just
   * as we originally received them from LaunchDarkly. If they are not, then we forcibly disable any flow 200
   * experiments.
   */
  private verifyFlow200Experiments = (currentFlowId: number | null) => {
    if (!currentFlowId) {
      return;
    }

    if (
      [
        FLOW_90_SELECT_SERVICE,
        FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
        FLOW_128_NYC_TEENS,
      ].includes(currentFlowId)
    ) {
      // The user is in flow 90 (service selection), flow 128 (teens) or flow 200 (the experimental flow).
      // The feature flags as they were received from LaunchDarkly should not be changed.
      this.context.setFlag((currentFlags, originalFlags, overrides) => {
        const res: any = {};
        FLOW_200_EXPERIMENTS.forEach((experiment) => {
          if (!isEqual(currentFlags[experiment], originalFlags[experiment])) {
            res[experiment] = originalFlags[experiment];
          }
        });
        return res;
      });
    } else {
      // The user is in a flow other than flow 90, flow 128 (teens) or flow 200. We should forcibly disable
      // any experimental flow 200 feature flags.
      this.context.setFlag((currentFlags, originalFlags, overrides) => {
        const res: any = {};
        FLOW_200_EXPERIMENTS.forEach((experiment) => {
          const flag = currentFlags[experiment];
          if (typeof flag === 'boolean' && flag !== false) {
            res[experiment] = false;
            // Check that flag exists
          } else if (flag) {
            const newFlag = {
              experimentActive: flag.experimentActive,
              experimentName: flag.experimentName,
              variant: 'control',
            };
            if (!isEqual(currentFlags[experiment], newFlag)) {
              res[experiment] = newFlag;
            }
          }
        });
        return res;
      });
    }
  };

  getIsCreateRoomFlow = () => {
    const { dispatcherState } = this.props.session;
    const search = new URLSearchParams(this.props.location.search);

    const isEligibilitySource =
      search.get('source') === 'eligibility' ? true : Boolean(dispatcherState?.isEligibilitySource);

    return isEligibilitySource;
  };

  getIsReactivationFlow = () => {
    const search = new URLSearchParams(this.props.location.search);

    // No need to check for dispatcherState as that is no longer used by the time of
    // this reactivation flow implementation.
    return search.get('source') === 'reactivation';
  };

  getIsUpdateCoverageFlow = () => {
    const search = new URLSearchParams(this.props.location.search);
    return search.get('source') === 'update-coverage';
  };

  getIsMBHIneligibilityFlow = () => {
    const search = new URLSearchParams(this.props.location.search);
    return search.get('source') === 'mbh-ineligibility';
  };

  getKeepProvider = (): boolean | undefined => {
    const search = new URLSearchParams(this.props.location.search);
    const keepProviderStr = search.get('keepProvider');
    return keepProviderStr ? Boolean(keepProviderStr) : undefined;
  };

  getSkipCapacityCheck = (): boolean | undefined => {
    const search = new URLSearchParams(this.props.location.search);
    if (search.get('skipCapacityCheck') === 'true') return true;
    if (search.get('skipCapacityCheck') === 'false') return false;
    return undefined;
  };

  getIsAmazonHcpOriginated = (): boolean | undefined => {
    const { referralSource } = getSessionStorageValuesFromJson(SESSION_STORAGE_MEMBER_DETAILS_KEY, [
      'referralSource',
    ]);
    return referralSource?.value === 'amazonhcp';
  };

  setCoupon = () => {
    if (this.getHasRecoveredSession()) {
      const { existingCoupon } = this.props.session;

      if (existingCoupon) {
        if (this.state.coupon) {
          trackEvent('Query coupon was preferred over server coupon', {
            coupon: this.state.coupon,
            serverCoupon: existingCoupon,
          });
        } else {
          this.setState({
            coupon: existingCoupon,
          });
        }
      }
    }
  };

  setFlowParameters = () => {
    const { recoveredFields } = this.props.session;
    const memberDetails =
      recoveredFields && recoveredFields.memberDetails && JSON.parse(recoveredFields.memberDetails);
    const { email, returningUserAppointmentData, isReturningUser } = memberDetails || {};
    const {
      preRegisterBookingID,
      therapistID,
      appointmentStartTime,
      appointmentEndTime,
      appointmentDuration,
    } = returningUserAppointmentData || {};
    const isAmazonHcpOriginated = this.getIsAmazonHcpOriginated();

    this.setState({
      isCreateRoomFlow: this.getIsCreateRoomFlow(),
      isReactivationFlow: this.getIsReactivationFlow(),
      isUpdateCoverageFlow: this.getIsUpdateCoverageFlow(),
      isMBHIneligibilityFlow: this.getIsMBHIneligibilityFlow(),
      keepProvider: this.getKeepProvider(),
      skipCapacityCheck: this.getSkipCapacityCheck(),
      isHideTeenServiceType: !!isAmazonHcpOriginated,
      isSkipDirectToBH: !!isAmazonHcpOriginated || !!isReturningUser,
      isHideNonBhItems: !!isAmazonHcpOriginated,
      existingUserEmail: email,
      isReturningUserFlow: !!isReturningUser,
      returningUserData: isReturningUser
        ? {
            preRegisterBookingID,
            therapistID,
            appointmentStartTime,
            appointmentEndTime,
            appointmentDuration,
          }
        : undefined,
    });
  };

  setCurrentProvidersInfo = () => {
    if (ReactFrameService.instance().isInFrame())
      ReactFrameService.instance()
        .sendAsyncMessage(PromiseMessageTypeNames.getQMInitData, undefined)
        .then((qmInitData) => {
          if (qmInitData?.currentProvidersInfo) {
            this.setState({
              currentProvidersInfo: qmInitData.currentProvidersInfo || [],
              hasOnlyPlaceholderProviders: qmInitData.hasOnlyPlaceholderProviders,
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
  };

  setCollectReferralSourceOnSignUp = () => {
    const search = new URLSearchParams(this.props.location.search);
    const redirectFrom = Number(search.get('redirectFrom'));
    const redirectFromFlowConfig =
      (redirectFrom && this.getFlow(redirectFrom, false)?.flowConfig) || undefined;
    const flowConfig = this.getFlowConfig();
    if (
      // This covers the case where redirectFrom is not set
      !redirectFromFlowConfig?.isB2B &&
      flowConfig?.isB2B === false
    ) {
      this.setState({ collectReferralSourceOnSignUp: true });
    }
  };

  saveSearchInSession = () => {
    const { search } = this.props.location;
    const currentFlowId = this.getFlowId();
    const redirectFrom = Number(new URLSearchParams(search).get('redirectFrom'));
    const flowConfig = this.getFlowConfig();
    if (
      flowConfig &&
      !this.state.restoringSession &&
      search &&
      // Only save when flow changes or query string changes
      ((this.props.session.flowId !== currentFlowId && redirectFrom) ||
        this.props.session.search !== search)
    ) {
      this.props.updateAndSave({
        search,
        flowId: currentFlowId,
        stepId: Number(this.props.match.params.stepId) || this.getInitialStepId(),
        flowVersion: flowConfig.version,
      });
    }
  };

  getHasRecoveredSession = () => {
    if (this.props.session.isReady) {
      const { session } = this.props;
      return session.recoveredSession;
    }
    return false;
  };

  getInitialStepId = (): number => {
    if (this.getHasRecoveredSession()) {
      const flowId = this.getFlowId();
      // Find first step in session for this flowId
      const { stepAnswers } = this.props.session;
      const { stepId = this.props.session.stepId } = stepAnswers.find(
        (answer) => answer.flowId === flowId
      ) || { stepId: this.props.session.stepId }; // Or use the last step for this flow (also the first)
      if (stepId) return stepId;
    }
    return 1;
  };

  getFlowId = ({ ignoreSession = false, overrideRestoringSession = false } = {}) => {
    const search = new URLSearchParams(this.props.location.search);
    const redirectFrom = Number(search.get('redirectFrom'));
    const restoringSession = this.state.restoringSession || overrideRestoringSession;

    if (restoringSession && this.props.session.flowId && !ignoreSession && !redirectFrom)
      return Number(this.props.session.flowId);
    return Number(this.props.match.params.flowId);
  };

  getFlow = (flowID: number, includeDeleted = true) => {
    const flow = getClientFlow(flowID, includeDeleted);
    const remoteConfig = this.state.remoteFlowConfigs?.[flowID];

    if (flow?.flowConfig && remoteConfig) {
      flow.flowConfig = { ...flow.flowConfig, ...remoteConfig } as FlowConfig;
    }

    return flow;
  };

  getFlowConfig = (flowID?: number, includeDeleted: boolean = false) =>
    this.getFlow(flowID || this.getFlowId(), includeDeleted)?.flowConfig;

  getMaxCostByFlowConfig = (
    flowConfig?: FlowConfig,
    service: ServiceType = 'psychotherapy'
  ): number => {
    if (flowConfig && 'maximumCost' in flowConfig) {
      return Number(flowConfig.maximumCost?.[service]?.toFixed(2)) || 0;
    }

    return 0;
  };

  getMaxCost = (flowID?: number, service: ServiceType = 'psychotherapy'): number => {
    const flowConfig = this.getFlowConfig(flowID || this.getFlowId());

    return this.getMaxCostByFlowConfig(flowConfig, service);
  };

  getNewStepAnswers = async (
    clientStateChanged: boolean,
    specialtiesStateChanged: boolean,
    providerGenderStateChanged: boolean,
    newSpecialtiesIds: number[],
    clientState: string,
    providerGender?: string
  ): Promise<StepAnswer[]> => {
    const updateStepAnswer = async (
      stepAnswer: StepAnswer,
      newAnswerValue: Partial<AnswerValue>
    ) => {
      const newStepAnswer = {
        ...stepAnswer,
        answerValue: {
          ...stepAnswer.answerValue,
          ...newAnswerValue,
        } as AnswerValue,
      };
      await this.updateStep(
        newStepAnswer.questionId,
        newStepAnswer.answerValue,
        newStepAnswer.chosenTherapist,
        newStepAnswer.chosenPlan,
        { flowId: newStepAnswer.flowId, stepId: newStepAnswer.stepId, updateStateOnly: true }
      );
      return newStepAnswer;
    };
    const newStepAnswers = await Promise.all(
      this.state.stepAnswers.map(async (stepAnswer) => {
        if (!stepAnswer?.answerValue?.stepId) return stepAnswer;
        const { payfirst_step_id: payfirstStepId } = stepAnswer?.answerValue || {};
        let updatedStepAnswer: StepAnswer;
        if (
          payfirstStepId === PAYFIRST_ANALYTIC_IDS.FIELDS_OF_EXPERTISE &&
          specialtiesStateChanged
        ) {
          const flowConfig = this.getFlowConfig();
          const presentingProblems = getPresentingProblemList({
            serviceType: flowConfig?.serviceType,
            eligibilityType: flowConfig?.eligibilityType,
            secondaryServiceSource: this.state.service,
            flowId: this.state.flowId,
            step: getStepFromFlowIDByID(
              this.state.flowId,
              stepAnswer.answerValue?.stepId
            ) as PresentingProblemsStep,
            clientAgeNumber: this.state.clientAgeNumber,
          }).filter((el) => newSpecialtiesIds.includes(el.id as number));
          updatedStepAnswer = await updateStepAnswer(stepAnswer, {
            response_self_serve: {
              field: 'fieldsOfExpertise',
              value: presentingProblems.length
                ? presentingProblems.map((p) => Number(p.expertiseID))
                : [],
            },
            response_value: presentingProblems.length
              ? presentingProblems.map((p) => p.id).join(', ')
              : undefined,
            response_prompt: presentingProblems.length
              ? presentingProblems[0].name
              : // This is the only option that does not map to a presenting problem
                simplifiedPresentingProblems.find((p) => p.id === null)?.name,
          });
        } else if (payfirstStepId === PAYFIRST_ANALYTIC_IDS.CLIENT_STATE && clientStateChanged) {
          updatedStepAnswer = await updateStepAnswer(stepAnswer, {
            response_prompt: states[clientState],
            response_value: clientState,
          });
        } else if (
          payfirstStepId === PAYFIRST_ANALYTIC_IDS.PROVIDER_GENDER_PREFERENCE &&
          providerGenderStateChanged
        ) {
          updatedStepAnswer = await updateStepAnswer(stepAnswer, {
            response_self_serve: {
              field: 'therapistGender',
              value: providerGender ? therapistGenderPreferenceDict[providerGender] : undefined,
            },
            response_value: providerGender,
            response_prompt: providerGender
              ? therapistGenderPreferenceDict[providerGender]
              : undefined,
          });
          // TODO KEVIN NYC-7761 case for insurance payers
        } else {
          return stepAnswer;
        }
        return updatedStepAnswer;
      })
    );

    return newStepAnswers;
  };

  restoreState = async (session: RecoveredSessionState) => {
    const answers = this.getHasRecoveredSession() ? session.stepAnswers : [];
    const flow = this.getFlow(this.getFlowId(), false);
    const isNoMatches = !!answers.find(({ answerValue }) => answerValue?.isNoMatches);
    for await (const answer of answers) {
      const { questionId, answerValue, chosenTherapist, chosenPlan, flowId, stepId } = answer;
      // if (DEBUG) this.compareAnswerState(answer);

      await this.updateStep(questionId, answerValue, chosenTherapist, chosenPlan, {
        flowId,
        stepId,
      });

      // Stop recover on match results is the user previously selected a match
      if (
        this.state.step &&
        (isLoadingStep(this.state.step) || isMatchesStep(this.state.step)) &&
        !isNoMatches
      )
        break;
    }

    const { dispatcherState } = this.props.session;
    const redirectFrom = Number(
      new URLSearchParams(this.props.location.search).get('redirectFrom')
    );
    const step =
      flow && flow.steps && flow.steps.find((s) => s.id === Number(this.props.match.params.stepId));

    if (
      step &&
      step.category === 'introduction1' &&
      dispatcherState &&
      dispatcherState.currentStep === 'goToFlowID' &&
      (dispatcherState.goToFlowID === step.id || dispatcherState.goToFlowID === redirectFrom)
    ) {
      // Skips the welcome screen if the user went through the dispatcher
      // Since the dispatcher has a very similar looking Welcome Screen step
      await this.updateStep(step.buttonTarget);
    }
    setTimeout(() => {
      this.setState({ restoringSession: false, opacity: 1 });
    }, 200);
  };

  // Only needed in DEBUG mode, also uses lodash so commenting the whole function out to prevent adding lodash for DEBUG purposes
  // compareAnswerState = stepAnswer => {
  //   if (!DEBUG) return;
  //   const a = JSON.parse(stepAnswer.currentState);
  //   const b = JSON.parse(
  //     JSON.stringify(_.omit(this.state, 'opacity', 'stepAnswers', 'restoringSession'))
  //   );
  //   const diffKeys = _.reduce(
  //     a,
  //     (result, value, key) => {
  //       return _.isEqual(value, b[key]) ? result : result.concat(key);
  //     },
  //     []
  //   );
  //   if (diffKeys.length) {
  //     // eslint-disable-next-line no-console
  //     console.log(
  //       `%Found different keys!!!\n`,
  //       diffKeys.map(key => `[${key}] ${a[key]} !== ${b[key]}`).join('\n'),
  //       'color:red'
  //     );
  //   }
  //   if (_.isEqual(b, a)) {
  //     // eslint-disable-next-line no-console
  //     console.log(
  //       `%The state for step answer ${stepAnswer.questionId} is the same!!`,
  //       'color:green'
  //     );
  //   }
  // };

  resetLivingInfo = () =>
    this.setState((state) => {
      return {
        shouldResetLivingInfo: false,
        clientCountry: !state.livesInTheUS ? state.clientCountry : 'US',
        clientState: state.livesInTheUS ? state.clientState : '',
      };
    });

  setFlowId = () => {
    if (this.getFlowId() !== this.state.flowId) {
      this.scrollPage({ top: 0 });
    }
    this.setState({ flowId: this.getFlowId() });
  };

  setRemoteFlowConfig = (flowID: number, flowConfig: FlowConfig) =>
    this.setState((prevState) => {
      const currentRemoteFlowConfig = JSON.parse(JSON.stringify(prevState.remoteFlowConfigs || {}));

      currentRemoteFlowConfig[flowID] = flowConfig;

      return { remoteFlowConfigs: currentRemoteFlowConfig };
    });

  setFlowConfig = (callback) => {
    this.setState({ flowConfig: this.getFlowConfig() }, callback);
  };

  setFunnelVariation = () =>
    this.setState((state) => {
      if (state.flowConfig && state.flowConfig.funnelVariation) {
        return { funnelVariation: state.flowConfig.funnelVariation as FunnelVariation };
      }
      if (state.isReactivationFlow) {
        return { funnelVariation: 'reactivation' };
      }
      if (state.isUpdateCoverageFlow) {
        return { funnelVariation: 'update-coverage' };
      }
      if (state.isMBHIneligibilityFlow) {
        return { funnelVariation: 'mbh-ineligibility' };
      }
      if (
        (state.flowId === FLOW_132_CONSUMER_THERAPY ||
          state.flowId === FLOW_106_CONSUMER_WALMART) &&
        state.b2bForkResult === 'b2c' &&
        state.experimentsVariants?.outOfPocketSplit === 'ct'
      ) {
        return { funnelVariation: 'qm_ct_exit' };
      }
      return {
        funnelVariation:
          state.roomType === 'psychiatryRoom' ? 'psychiatry_quickmatch' : 'customer_selected',
      };
    });

  setIsConfirmScheduler = (isConfirmScheduler: boolean) => {
    this.setState({ isConfirmScheduler });
  };

  setStep = async (stepID?: number) => {
    let flowID = this.getFlowId();
    const flowConfig = this.getFlowConfig(flowID);

    if (flowConfig?.isManualFlow) {
      const { manualFlows, excludeFlows } = await getPublicAdminConfigJsonValue(
        'qm_flows_expired_link',
        true
      );

      if (manualFlows && !excludeFlows.includes(flowID)) {
        flowID = FLOW_148_NEW_USER_JWT_EXPIRED;
      }
    }

    const flow = this.getFlow(flowID, false);

    const { organizationFlowID } = this.state;
    if (!flow) {
      // eslint-disable-next-line no-console
      console.error('Not found', window.location.href);
      this.props.history.push('/not-found');
      return;
    }

    const search = new URLSearchParams(this.props.location.search);
    const disableForceStepOne = Number(search.get('disableForceStepOne'));

    if (!disableForceStepOne && stepID === 1 && Number(this.props.match.params.stepId) !== 1) {
      // if this is a fresh load of the page, take them back to step 1
      this.props.history.push(
        `/flow/${this.props.match.params.flowId}/step/1${this.props.location.search}`
      );
      return;
    }

    const step =
      flow.steps && flow.steps.find((s) => s.id === Number(this.props.match.params.stepId));

    if (!step) {
      // eslint-disable-next-line no-console
      console.error('Not found', window.location.href);
      this.props.history.push('/not-found');
      return;
    }
    this.setState((state) => {
      const fromPage = (search.get('fromPage') as fromPageSource) || '';
      const fromPageDisableList: fromPageSource[] = ['home', 'check-coverage'];
      const isInsuranceConfirmationStep =
        step.category === 'insuranceConfirmation' && fromPageDisableList.includes(fromPage);

      const shouldHideBackButton = step.hideBackButton || !!organizationFlowID;

      const isBHCopayStepSkipped = isBHCopayStep(step) && state.skipCreditCard;

      const isDisabledPreviousButton =
        (shouldHideBackButton && !isBHCopayStepSkipped) || isInsuranceConfirmationStep;

      return {
        stepId: Number(this.props.match.params.stepId),
        step,
        // Once the user went through the organization flow, remove the ability to go back
        isDisabledPreviousButton,
      };
    });
  };

  setPartnerCodes = () => {
    const search = new URLSearchParams(this.props.location.search);
    this.setState({
      qmPartnerCode: search.get('qmPartnerCode') || '',
      cpPartnerCode: search.get('cpPartnerCode') || '',
    });
  };

  setClientAge = () => {
    const search = new URLSearchParams(this.props.location.search);
    const ca = search.get('ca');
    if (ca) {
      this.setState({ clientAge: ca });
    }
  };

  setClientAgeToAgeGroup = () => {
    const search = new URLSearchParams(this.props.location.search);
    const clientAge = search.get('clientAge');

    if (clientAge) {
      const age = moment().diff(moment(clientAge), 'years');

      const ageId = ageGroupId(age);
      const ageLabel = ageGroupLabel(age);

      if (!this.state.clientAge) {
        this.setState((state) => {
          const dateOfBirthResponse = {
            payfirst_step_id: 61,
            payfirst_step_prompt: 'What is your age?',
            response_category_id: 5,
            response_prompt: ageLabel,
            response_value: ageId.toString(),
          };
          const responses = state.responses.filter(
            (response) => response.stepId !== dateOfBirthResponse.response_category_id
          );
          const dateOfBirthStepID = getStepFromFlowID(state.flowId, isDateOfBirthStep, true).find(
            (s) => s.response_category_id === dateOfBirthResponse.response_category_id
          )?.id;

          return {
            responses: responses.concat(dateOfBirthResponse),
            stepsToSkip: dateOfBirthStepID
              ? state.stepsToSkip.concat(dateOfBirthStepID)
              : state.stepsToSkip,
          };
        });
      }

      this.setState({
        clientAge: ageId,
        clientAgeTitle: ageLabel,
        clientDateOfBirth: clientAge,
      });
    }
  };

  setClientMatchExpertisePreference = () => {
    const search = new URLSearchParams(this.props.location.search);
    const clientMatchExpertisePreference = search.get('cmep');
    if (clientMatchExpertisePreference) {
      this.setState({
        clientMatchExpertisePreference:
          clientMatchExpertisePreference === 'null' ? '80' : clientMatchExpertisePreference,
      });
    }
  };

  setClientCountryAndStateFromOneTrust = () => {
    const { countryCode, stateCode } = getGeolocationFromOneTrustSDK();
    if (countryCode) {
      const isGDPR = isGDPRCountry({ countryCode });
      this.setState({
        clientCountry: countryCode,
        clientState: stateCode,
        isGDPR,
      });
      storeGDPRProps({ isGDPR });
    }
  };

  setClientCountryAndStateFromParams = () => {
    const search = new URLSearchParams(this.props.location.search);
    const clientCountry = search.get('ccountry');
    const clientState = search.get('cstate');
    if (clientCountry) {
      this.setState({
        clientCountry,
        livesInTheUS: false,
        shouldResetLivingInfo: true,
      });
    } else if (clientState) {
      this.setState({
        clientState,
        clientCountry: 'US',
        livesInTheUS: true,
        shouldResetLivingInfo: true,
      });
    }
  };

  setClientCountryFromBrowserLanguage = () => {
    if (
      this.state.step &&
      isStateCountrySelectionStep(this.state.step) &&
      // For non-psychiatry users,
      // if the selection has not been made,
      // auto select state/country for users with:
      //  - English (UK): 'en-GB' language
      this.state.service !== 'psychiatry' &&
      this.state.clientCountry === undefined &&
      navigator.language.includes('en-GB') &&
      countries.filter((el) => el.value === 'GB').length
    ) {
      this.setState({
        clientState: undefined,
        clientCountry: 'GB',
        livesInTheUS: false,
      });
    }
  };

  syncClientAgeNumberWithStorage = () => {
    // There is a lot of hacks around client age and date of birth in the codebase.
    // The clientAge state is actually historically tracking the string date of birth for whatever reason
    // and this is now engrained into the codebase
    // There is now a new state clientAgeNumber that is actually tracking the age of the client as an integer
    // This method is called on every mount and update to make sure that there is a correct integer age
    // stored in the clientAgeNumber state if 'age' is set in the TSQM_BasicInformation session storage
    const { age } = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
    this.setState({ clientAgeNumber: age });
  };

  removeGenderFilter = () => this.setState({ clientMatchGenderPreference: undefined });

  updateAllowedModalities = () => {
    const search = new URLSearchParams(this.props.location.search);
    if (!search.get('allowedModalities')) {
      return;
    }
    const video = search.get('video');
    const audio = search.get('audio');
    const chat = search.get('chat');
    const messaging = search.get('messaging');
    const totalSessions = search.get('totalSessions');
    const accountType = search.get('accountType');
    const manualFlowID = search.get('manualFlowID');
    const allowedModalities: Array<AllowedModality> = [];
    if (messaging) {
      allowedModalities.push({ name: 'messaging', isDefault: messaging === 'isDefault' });
    }
    if (video) {
      allowedModalities.push({ name: 'video', isDefault: video === 'isDefault' });
    }
    if (audio) {
      allowedModalities.push({ name: 'audio', isDefault: audio === 'isDefault' });
    }
    if (chat) {
      allowedModalities.push({ name: 'chat', isDefault: chat === 'isDefault' });
    }
    if (totalSessions && !Number.isNaN(+totalSessions)) {
      this.setState({ totalSessions: Number(totalSessions) });
    }
    if (accountType && isAccountType(accountType)) {
      this.setState({ accountType });
    }
    if (manualFlowID && !Number.isNaN(+manualFlowID)) {
      this.setState({ manualFlowID: Number(manualFlowID) });
    }
    this.setState({ allowedModalities });
  };

  setInsuranceDetails = async (newInsuranceDetails: InsuranceDetails) => {
    const newInsuranceDetailsWithoutEmpty = omitBy(newInsuranceDetails, isNil);
    let { gediPayerID, payerName, dob } = this.state.insuranceDetails;
    if (
      newInsuranceDetailsWithoutEmpty?.gediPayerID &&
      newInsuranceDetailsWithoutEmpty.gediPayerID !== gediPayerID
    ) {
      ({ gediPayerID } = newInsuranceDetailsWithoutEmpty);

      if (newInsuranceDetailsWithoutEmpty?.payerName) {
        ({ payerName } = newInsuranceDetailsWithoutEmpty);
      }
    }

    if (newInsuranceDetailsWithoutEmpty?.dob) {
      dob = moment(newInsuranceDetailsWithoutEmpty.dob).format('YYYY-MM-DD');
    }
    this.setState((prevState) => {
      return {
        insuranceDetails: {
          ...prevState.insuranceDetails,
          ...{
            ...newInsuranceDetailsWithoutEmpty,
            dob,
            gediPayerID,
            payerName,
          },
        },
      };
    });
  };

  setInsurancePayer = async () => {
    const search = new URLSearchParams(this.props.location.search);
    const insurancePayerStr = search.get('insurancePayer');
    let insurancePayer: PayerOptionType | undefined;

    const dispatcherInQMStep = getStepFromFlowID(this.getFlowId(), isDispatcherInQMStep);
    const oneFormEligibilityStep = getStepFromFlowID(
      this.getFlowId(),
      isOneFormEligibilityStep,
      true
    ).find((s) => s.disableOrganization !== true);
    let stepsToSkip: HomePageState['stepsToSkip'] =
      (dispatcherInQMStep?.id && [dispatcherInQMStep.id]) || [];
    if (oneFormEligibilityStep?.id) {
      stepsToSkip.push(oneFormEligibilityStep.id);
    }
    if (insurancePayerStr) {
      try {
        let b2bForkResult: HomePageState['b2bForkResult'];
        let isB2BOutOfNetwork: HomePageState['isB2BOutOfNetwork'] = false;
        let partnerFlowID: HomePageState['partnerFlowID'];
        let collectReferralSourceOnSignUp: HomePageState['collectReferralSourceOnSignUp'] = true;
        let { isNoMatches } = this.state;
        insurancePayer = JSON.parse(decodeURIComponent(insurancePayerStr)) as PayerOptionType;
        if (insurancePayer.id === OUT_OF_POCKET_OPTION.id) {
          b2bForkResult = 'b2c';
          collectReferralSourceOnSignUp = true;
        } else if (insurancePayer.id === ORGANIZATION_OPTION.id) {
          b2bForkResult = 'b2b';
          // Don't skip dispatcherInQM for Organization
          stepsToSkip = [];
        } else if (insurancePayer.id) {
          const { unifiedFlowForDirectB2B, unifiedFlowV2 } = this.context as LDFlags;
          b2bForkResult = 'b2b';

          if (unifiedFlowForDirectB2B || unifiedFlowV2) {
            // in these experiments, if we have an insurance payer id we skip the "matches" step
            // so default isNoMatches to true
            isNoMatches = true;
          }
          // ensure insurance Payer is available for UnifiedFlow routing
          this.setState({ insurancePayer, b2bForkResult, isNoMatches });

          const { flowID, b2bOutOfNetwork } = await getInsuranceVerificationFlowID(
            insurancePayer,
            this.getFlowConfig()?.serviceType ||
              (unifiedFlowForDirectB2B || unifiedFlowV2 ? 'psychotherapy' : null)
          );

          isB2BOutOfNetwork = b2bOutOfNetwork === true;
          partnerFlowID = flowID || undefined;
          if (partnerFlowID) {
            collectReferralSourceOnSignUp = false;
          } else {
            collectReferralSourceOnSignUp = true;
          }
        } else {
          // Invalid insurancePayer
          insurancePayer = undefined;
        }

        if (b2bForkResult) {
          this.setState((prevState) => {
            return {
              b2bForkResult,
              insurancePayer,
              isNoMatches,
              partnerFlowID,
              isB2BOutOfNetwork,
              collectReferralSourceOnSignUp,
              stepsToSkip: prevState.stepsToSkip.concat(stepsToSkip),
            };
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Error parsing insurance payer', insurancePayerStr);
      }
    }
    // If failed to parse (or missing) and no Flow with no B2BFork step detected
    if (
      !insurancePayer &&
      // Check for flowID in Eligibility Questions in LP experiment
      [
        FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY,
        FLOW_101_ELIGIBILITY_QUESTIONS_COUPLES_THERAPY,
        FLOW_102_ELIGIBILITY_QUESTIONS_PSYCHIATRY,
        FLOW_103_ELIGIBILITY_QUESTIONS_TEEN_THERAPY,
      ].includes(this.getFlowId())
    ) {
      this.setState((prevState) => {
        return {
          b2bForkResult: 'b2c',
          insurancePayer: OUT_OF_POCKET_OPTION,
          stepsToSkip: prevState.stepsToSkip.concat(stepsToSkip),
        };
      });
    }
  };

  setCountryAndState = () => {
    // sync isGDPR in store and state with new state.clientCountry
    const { clientCountry } = this.state;
    if (clientCountry) {
      const isGDPR = isGDPRCountry({ countryCode: clientCountry });
      this.setState({ isGDPR });
      storeGDPRProps({ isGDPR });
    }

    if (this.state.clientState) return;

    const search = new URLSearchParams(this.props.location.search);
    // Currently only support state
    const stateStr = search.get('clientState');
    if (stateStr && stateStr in states) {
      const stateResponse = {
        payfirst_step_id: 63,
        response_category_id: 6,
        response_prompt: QM_COPY.state.title,
        response_value: stateStr,
        livesInTheUS: true,
      };
      this.setState((state) => {
        // overwrite if question has already been answered
        const responses = state.responses.filter(
          (response) => response.stepId !== stateResponse.payfirst_step_id
        );
        const stateStepID = getStepFromFlowID(state.flowId, isDropdownStep, true).find(
          (s) => s.response_category_id === stateResponse.response_category_id
        )?.id;
        return {
          responses: responses.concat(stateResponse),
          clientCountry: 'US',
          clientState: stateStr,
          stepsToSkip: stateStepID ? state.stepsToSkip.concat(stateStepID) : state.stepsToSkip,
        };
      });
    } else if (stateStr === OUT_OF_STATE_OPTION.value) {
      this.setState({
        livesInTheUS: false,
      });
    }
  };

  setHideVWOBar = () => {
    if (getParamByName('hideVWOBar') === 'true') {
      this.setState({ hideVWOBar: true });
    }
  };

  setUpdateRoom = () => {
    const search = new URLSearchParams(this.props.location.search);
    const updateRoomsID = search.get('updateCoverageRoomID');

    if (updateRoomsID) {
      this.setState({ updateCoverageRoomID: +updateRoomsID });
    }
  };

  setReviewPlanDetails = () => {
    const { accountType } = this.state;

    if (AccountType.BH === accountType) {
      this.setState({ reviewPlanDetails: PLAN_REVIEW_BH });
    } else if (AccountType.EAP === accountType) {
      const { allowedModalities } = this.state;
      if (allowedModalities?.length) {
        const isMessaging = allowedModalities.find((modality) => modality.name === 'messaging');
        const isVideo = allowedModalities.find((modality) => modality.name === 'video');
        if (isMessaging && isVideo) {
          this.setState({ reviewPlanDetails: PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE });
        } else if (isVideo) {
          this.setState({ reviewPlanDetails: PLAN_REVIEW_EAP_VIDEO });
        } else if (isMessaging) {
          this.setState({ reviewPlanDetails: PLAN_REVIEW_EAP_MESSAGE });
        }
      }
    }
  };

  /**
   * This function will update homepage state for the passed filter params, and overwrite
   * previous step answer/responses if they are different than the current state values.
   *
   * @param clientState
   * @param insurancePayer
   * @param specialties
   * @param providerGender
   * @param blockGetMatches
   */
  applyFilters = async (
    clientState: string,
    specialties: ClientMatchPresentingProblem[],
    providerGender?: string,
    blockGetMatches?: boolean
  ): Promise<void> => {
    this.setState({ loadingMatches: true });

    const newStateUpdate: FiltersHomePageState = {
      clientState: this.state.clientState,
      clientCountry: this.state.clientCountry,
      livesInTheUS: this.state.livesInTheUS,
      shouldResetLivingInfo: this.state.shouldResetLivingInfo,
      clientMatchGenderPreference: this.state.clientMatchGenderPreference,
      clientMatchPresentingProblems: this.state.clientMatchPresentingProblems,
      stepAnswers: this.state.stepAnswers,
    };
    let clientStateChanged = false;
    let providerGenderStateChanged = false;
    let specialtiesStateChanged = false;

    if (clientState && clientState !== this.state.clientState) {
      clientStateChanged = true;
      newStateUpdate.clientState = clientState;
      newStateUpdate.clientCountry = 'US';
      newStateUpdate.livesInTheUS = true;
      newStateUpdate.shouldResetLivingInfo = true;
    }

    if (providerGender !== this.state.clientMatchGenderPreference) {
      providerGenderStateChanged = true;
      newStateUpdate.clientMatchGenderPreference = providerGender;
    }

    // Check by Ids incase flow is presenting simplified problems
    const newSpecialtiesIds = specialties.map((s) => Number(s.id));
    const currentSpecialtiesIds = this.state.clientMatchPresentingProblems
      ? this.state.clientMatchPresentingProblems.map((p) => p.id)
      : [];

    const shouldChangeSpecialties =
      newSpecialtiesIds.length < currentSpecialtiesIds.length ||
      !newSpecialtiesIds.every((id) => currentSpecialtiesIds.includes(id));

    if (shouldChangeSpecialties || !specialties.length) {
      specialtiesStateChanged = true;
      newStateUpdate.clientMatchPresentingProblems = specialties;
    }

    const shouldUpdateState = Object.keys(newStateUpdate).length > 0;
    const shouldGetMatches = shouldUpdateState && clientStateChanged;
    if (shouldUpdateState) {
      const newStepAnswers = await this.getNewStepAnswers(
        clientStateChanged,
        specialtiesStateChanged,
        providerGenderStateChanged,
        newSpecialtiesIds,
        clientState,
        providerGender
      );
      newStateUpdate.stepAnswers = newStepAnswers;

      this.setState(newStateUpdate, () => {
        if (shouldGetMatches && !blockGetMatches) {
          this.getMatches();
        } else {
          this.setState({ loadingMatches: false, isFiltersModalVisible: false });
        }
      });
    } else {
      this.setState({ loadingMatches: false, isFiltersModalVisible: false });
    }
  };

  setClientMatchGenderPreference = () => {
    const search = new URLSearchParams(this.props.location.search);
    const cmgp = search.get('cmgp');
    if (cmgp) {
      this.setState({ clientMatchGenderPreference: cmgp });
    }
  };

  setClientMatchEthnicityPreference = () => {
    const search = new URLSearchParams(this.props.location.search);
    const cmerp = search.get('cmerp');
    if (cmerp) {
      if (cmerp === 'null') {
        this.setState({ clientMatchEthnicityPreference: undefined });
      } else {
        this.setState({ clientMatchEthnicityPreference: cmerp });
      }
    } else {
      this.setState({ clientMatchEthnicityPreference: undefined });
    }
  };

  /* eslint-disable no-unused-expressions */
  setCopayCents = () => {
    const search = new URLSearchParams(this.props.location.search);
    const cpc = search.get('cpc');
    if (cpc) this.setState({ copayCents: cpc });
  };

  setInsuranceCode = () => {
    const search = new URLSearchParams(this.props.location.search);
    const insuranceCode = search.get('insuranceCode');
    if (insuranceCode) this.setState({ insuranceCode });
  };
  /* eslint-enable no-unused-expressions */

  setRedirectParam = () => {
    const search = new URLSearchParams(this.props.location.search);
    this.setState({
      redirectFrom: Number(search.get('redirectFrom')) || 0,
    });
  };

  setTrizettoRequest = () => {
    const search = new URLSearchParams(this.props.location.search);
    const tr = Number(search.get('tr'));
    if (tr && !isNaN(tr)) {
      this.setState({ trizettoRequest: tr });
    }
  };

  setServiceType = () => {
    let serviceType: ServiceType | undefined;
    // B2B serviceType in URL is prioritized over B2C serviceType in flow config
    // get serviceType from URL
    const search = new URLSearchParams(this.props.location.search);
    const serviceTypeFromSearch = search.get('serviceType') as ServiceType | null;
    if (serviceTypeFromSearch) {
      serviceType = serviceTypeFromSearch;
    }
    // get serviceType from flow config
    const serviceTypeFromFlowConfig = this.state.flowConfig?.serviceType;
    if (!serviceType && serviceTypeFromFlowConfig) {
      serviceType = serviceTypeFromFlowConfig;
    }

    if (!serviceType && !this.state.service) {
      // use default service type otherwise, if is unset from state
      serviceType = 'psychotherapy';
    }
    if (serviceType) {
      if (this.state.service !== serviceType)
        this.props.setCustomAttributes({ qmServiceType: serviceType });
      // if service type is set from URL, from flow config, or default above
      this.setState({
        service: serviceType,
      });
    }
  };

  setRoomType = () => {
    let roomType: RoomType = 'privateRoom';
    if (this.state.service === 'psychiatry') {
      roomType = 'psychiatryRoom';
    } else if (this.state.service === 'therapyCouples') {
      roomType = 'couplesRoom';
    }
    this.setState({
      roomType,
      isPsychiatryRoom: roomType === 'psychiatryRoom',
    });
  };

  setEligibilityFiles = (files: {
    insuranceFront: File;
    insuranceBack: File;
    idFront: File;
    idBack?: File;
  }) => {
    this.setState((state) => {
      return {
        eligibilityFiles: files,
      };
    });
  };

  setQuickEligibilityInfo = (info: Partial<HomePageState['quickEligibilityInfo']>) => {
    this.setState((state) => {
      return {
        quickEligibilityInfo: {
          ...state.quickEligibilityInfo,
          ...info,
        },
        copayCents:
          typeof info?.insuranceEligibility?.copayCents !== 'undefined'
            ? String(info?.insuranceEligibility?.copayCents)
            : '',
        insuranceCode: info?.insuranceEligibility?.insuranceCode,
        trizettoRequest: info?.insuranceEligibility?.trizettoRequestId,
        clientCountry: info?.state !== 'other' ? 'US' : info?.country || state.clientCountry,
        clientState: info?.state && info.state !== 'other' ? info.state : state.clientState,
      };
    });
  };

  setManualVerificationEmail = (email: string) => {
    this.setState((state) => {
      return {
        ...state,
        manualVerificationEmail: email,
      };
    });
  };

  setPreviousTherapistId = () => {
    const search = new URLSearchParams(this.props.location.search);
    const prevTherapistId = search.get('previousTherapistId');

    if (prevTherapistId) {
      this.setState({
        previousTherapistId: Number(prevTherapistId),
      });
    }
  };

  setIsVideoOnlyPlan = () => {
    const isVideoOnlyPlan = getParamByName('isVideoOnlyPlan') === 'true';
    if (isVideoOnlyPlan) this.setState({ isVideoOnlyPlan });
  };

  getAnalyticsPaymentInfo = () => this.state.chosenPlan;

  getRemoteFlowConfig = async () => {
    const flowID = this.getFlowId();

    if (!flowID) {
      return;
    }

    const remoteFlowConfig = await getFlowConfigByFlowID(flowID);

    this.setRemoteFlowConfig(flowID, remoteFlowConfig);
  };

  getMatches = async (matchOptions = {}) => {
    const clientPresentingProblem = this.state.clientMatchPresentingProblems
      ? this.state.clientMatchPresentingProblems.map((p) => p.id)
      : [];
    const insurancePayerID = this.state.insurancePayer?.id;
    const { partnerFlowID } = this.state;

    const params = {
      insurancePayerID: insurancePayerID && partnerFlowID ? insurancePayerID : undefined,
      clientDateOfBirth: this.state.clientDateOfBirth || undefined,
      clientAge: this.state.clientAge || undefined,
      clientLanguage: this.state.clientLanguage || undefined,
      clientGender: this.state.clientGender || undefined,
      clientMaritalStatus: this.state.clientMaritalStatus || undefined,
      clientState: this.state.clientState || undefined,
      clientCountry: this.state.clientCountry || undefined,
      clientMatchExpertisePreference: this.state.clientMatchExpertisePreference || undefined,
      clientPresentingProblem,
      clientMatchGenderPreference: this.state.clientMatchGenderPreference || undefined,
      clientMatchEthnicityPreference: this.state.clientMatchEthnicityPreference,
      accessCode: this.state.qmPartnerCode || this.state.cpPartnerCode || undefined,
      maxResults: this.state.maxResults,
      roomType: this.state.roomType,
      flowID: this.state.flowId,
      ...matchOptions,
    };
    try {
      if (!this.state.loadingMatches) this.setState({ loadingMatches: true });
      const results = await getMatchTherapists(params);
      const { exactMatch, therapists: matches } = results;
      this.setState({
        matches,
        exactMatch,
        isNoMatches: matches.length === 0,
        loadingMatches: false,
      });
    } catch (err) {
      this.setState({
        matches: [],
        loadingMatches: false,
      });
    } finally {
      if (this.state.isFiltersModalVisible) this.handleHideFiltersModal();
    }
  };

  checkProvidersAvailability = async (): Promise<void> => {
    const {
      currentProvidersInfo,
      clientState,
      clientCountry,
      roomType,
      insurancePayer,
      b2bForkResult,
      skipCapacityCheck,
    } = this.state;
    if (currentProvidersInfo?.length && clientCountry && roomType) {
      const newProvidersArray = [...currentProvidersInfo];
      const tsUserID = getParsedUserIDFromQMSession();
      // Only send the clientUserID if we want to skip the capacity check
      // The client user id allows the api to check if this user is already
      // a part of the provider caseload.
      const clientUserID =
        (this.state.isUpdateCoverageFlow || this.state.isMBHIneligibilityFlow) &&
        skipCapacityCheck !== false // if skipCapacityCheck is undefined/null, we default to true here.
          ? tsUserID
          : undefined;
      await Promise.all(
        newProvidersArray.map(async (provider) => {
          const { available } = await checkProviderRematchAvailability(provider.therapistID, {
            clientCountry,
            clientState: clientState || null,
            roomType,
            ...(insurancePayer?.id && { insurancePayerID: insurancePayer?.id }),
            isB2B: b2bForkResult === 'b2b',
            sessionID: apiWrapper.currentUserID(),
            clientUserID,
          }).catch(() => {
            return { available: false };
          });
          // eslint-disable-next-line no-param-reassign
          provider.isAvailable = available;
        })
      );
      this.setState({ currentProvidersInfo: newProvidersArray });
    }
  };

  getSelectedMatch = () => {
    if (this.state.therapistId && this.state.matches) {
      return this.state.matches.find((el) => el.id === this.state.therapistId);
    }
    return undefined;
  };

  getFlowName = (): LandingPageCTAClick['Flow'] => {
    const specialFlowName = this.getFlowConfig()?.specialFlowName;

    if (this.getIsCreateRoomFlow()) {
      return 'Eligibility';
    }
    if (this.getIsReactivationFlow()) {
      return 'Reactivation';
    }
    if (this.getIsUpdateCoverageFlow()) {
      return 'Update Coverage';
    }
    if (this.getIsUpdateCoverageFlow()) {
      return 'MBH Ineligibility';
    }
    if (this.getFlowConfig()?.isB2B) {
      return 'B2B QuickMatch';
    }
    if (specialFlowName) {
      return specialFlowName;
    }
    return 'QuickMatch';
  };

  trackPageLoad = () => {
    const { flowId } = this.props.match.params;

    if (!flowId) {
      return;
    }

    setFlowSuperProperty(flowId);

    if (isB2BFlow(Number(flowId))) {
      // @todo Revise the need for this event. The one below with property Flow = 'B2B QuickMatch' could replace this.
      trackEvent('B2B CTA Click', {
        'Funnel Name': 'B2B QuickMatch',
      });
    }
    if (
      this.getIsCreateRoomFlow() ||
      this.getIsReactivationFlow() ||
      this.getIsUpdateCoverageFlow() ||
      this.getIsMBHIneligibilityFlow()
    ) {
      this.setState({ isDisabledPreviousButton: true });
    }

    const flowName: LandingPageCTAClick['Flow'] = this.getFlowName();
    // Always send this event
    trackEvent('Landing Page CTA Click', { Flow: flowName });

    // upper funnel events for marketing - started Quickmatch
    trackGTMEvent('initiatedQuickmatch', {});

    VWO.trackInitiatedQMGoal();

    const { isGDPR } = getGDPRProps();
    trackEvent('Talkspace Page View', {
      Page: 'QuickMatch Home',
      ver: 2,
      cleanURL: window.location.origin + window.location.pathname,
      isGDPR,
    });
  };

  handleClientDateOfBirth = (clientDateOfBirth: string, updateClientAge = false) => {
    const stateUpdate: Pick<HomePageState, 'clientDateOfBirth' | 'clientAge'> = {
      clientDateOfBirth,
      clientAge: this.state.clientAge,
    };
    if (updateClientAge) {
      const age = moment().diff(moment(clientDateOfBirth), 'years');
      stateUpdate.clientAge = ageGroup(age).value || 1;
    }
    this.setState(stateUpdate);
  };

  handleClientAge = (clientAge: number) => {
    // NOTE: this is a continuation of a odd pattern of saving age group id into state prop named clientAge
    this.setState({ clientAge: ageGroup(clientAge).value });
    // actual age as an integer
    this.setState({ clientAgeNumber: clientAge });
  };

  handleShowCountries = () =>
    this.setState((state) => {
      return {
        livesInTheUS: !state.livesInTheUS,
        shouldResetLivingInfo: true,
      };
    });

  handleReferralSource = (referralSource) => this.setState({ referralSource });

  handleHideFiltersModal = () => this.setState({ isFiltersModalVisible: false });

  handleShowFiltersModal = () => this.setState({ isFiltersModalVisible: true });

  handleSetIsMatchesOverlayDismissed = (isMatchesOverlayDismissed: boolean) =>
    this.setState({ isMatchesOverlayDismissed });

  handleRetargetingSubmit = (values) =>
    this.setState({ ...values, clientAge: values.clientAge === 5 ? 1 : values.clientAge });

  goBack = () => {
    this.props.history.goBack();
  };

  setDiscardMatches = (shouldDiscard: boolean) => {
    this.discardMatches = shouldDiscard;
  };

  handleGoBack = () => {
    // prevent going back to qm flow that was concluded
    if (this.state.isQMFlowDone) {
      window.location.reload();
      return;
    }

    if (this.state.step) {
      const { step } = this.state;
      const { category } = this.state.step;

      if (isPresentingProblemsStep(step) && this.state.isCouldNotBeVerified) {
        this.props.history.goBack();
      }

      // discard matches
      if (isMatchesStep(step)) {
        if (this.discardMatches) {
          if (step.matches !== null) {
            this.setState({ matches: [] });
          }
          this.props.history.goBack();
        } else {
          // Didn't discard matches. Now we can reset the flag to its initial value (true)
          this.discardMatches = true;
        }
      }

      // discard "loading" screen and handle special cases
      if (
        ['loading1', 'registerWithVoucher1', 'scheduler', 'oneFormEligibility'].includes(category)
      ) {
        this.props.history.goBack();
      }
    }
  };

  trackStepChange = () => {
    // Send to GTM for tracking step change events
    trackGTMEvent('QMFlowStepChange', {
      customPagePath: `QuickMatch FlowID: ${this.state.flowId}`,
      customPageTitle: this.state.step ? this.state.step.prompt : 'QuickMatch',
    });
  };

  trackStepAnswer = (step, answer) => {
    if (step.analyticsId || step.isInterstitial) {
      try {
        const specialFlowName = this.getFlowConfig()?.specialFlowName;
        const {
          isCreateRoomFlow,
          isReactivationFlow,
          isUpdateCoverageFlow,
          isMBHIneligibilityFlow,
          qmPartnerCode,
        } = this.state;
        if (step.analyticsId) {
          trackEvent('Answer Questionnaire', {
            ...(specialFlowName && { Flow: specialFlowName }),
            'Step ID': step.id,
            Category: step.category,
            Question: answer && answer.payfirst_step_prompt ? answer.payfirst_step_prompt : '',
            'Funnel Name': getQMFunnelName({
              isCreateRoomFlow,
              isReactivationFlow,
              isUpdateCoverageFlow,
              isMBHIneligibilityFlow,
              isB2B: qmPartnerCode !== '',
            }),
          });
        } else {
          const { isGDPR } = getGDPRProps();
          trackEvent('Interstitial Screen CTA', {
            ...(specialFlowName && { Flow: specialFlowName }),
            'Step ID': step.id,
            Category: step.category,
            'Funnel Name': getQMFunnelName({
              isCreateRoomFlow,
              isReactivationFlow,
              isUpdateCoverageFlow,
              isMBHIneligibilityFlow,
              isB2B: qmPartnerCode !== '',
            }),
            isGDPR,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  getStepForCurrentFlow = (questionId: number) =>
    getStepFromFlowIDByID(this.getFlowId(), questionId);

  getNextQuestionId = async (
    questionId: string | number | null | undefined,
    answer,
    skipping = false
  ) => {
    const redirectTargetID = getRedirectTargetID(this.state);
    if (redirectTargetID) return redirectTargetID;

    let nextQuestionId = questionId;
    /** For the purpose of InitialQuestionSkipper, flowID might not be set in the state on time */
    const urlFlowID =
      Number(this.props.location.pathname.match(/\/flow\/(\d+)/)?.[1]) || this.state.flowId;
    const runNextQuestionActions = async (
      nextQuestionActions: Array<NextQuestionAction> | NextQuestionActionForTarget | undefined = {}
    ) => {
      const finalNextQuestionActions = Array.isArray(nextQuestionActions)
        ? nextQuestionActions
        : (nextQuestionId && nextQuestionActions[nextQuestionId]) || undefined;

      if (Array.isArray(finalNextQuestionActions)) {
        for (let i = 0; i < finalNextQuestionActions.length; i += 1) {
          const action = finalNextQuestionActions[i];
          if (typeof action === 'function') {
            // eslint-disable-next-line no-await-in-loop
            const retValue = await action(this.state, answer, this.context as LDFlags, {
              urlFlowID,
            });
            if (retValue && !isNaN(retValue)) {
              debug(
                `nextQuestionAction [stepID=${questionId}] [name=${
                  action.name || 'anon'
                }] [return=${retValue}]`,
                { action }
              );
              return retValue;
            }
            debug(
              `nextQuestionAction [stepID=${questionId}] [name=${
                action.name || 'anon'
              }] [return=undefined]`,
              { action }
            );
          }
        }
      }
      return nextQuestionId;
    };
    // Check nextQuestionActions for current step first
    if (this.state.step && !skipping) {
      const { nextQuestionActions = {} } = this.state.step;
      nextQuestionId = (await runNextQuestionActions(nextQuestionActions)) || nextQuestionId;
    }
    const nextStep = this.getStepForCurrentFlow(Number(nextQuestionId));

    // Check if next step should be skipped
    const getNextTarget = async (step: FlowStep | null): Promise<InternalTarget | null> => {
      if (!step) return null;
      if (step.nextQuestionActions) return runNextQuestionActions(step.nextQuestionActions);
      if (step.buttonTarget) return step.buttonTarget;
      if ('internalTarget' in step && step.internalTarget) return step.internalTarget;
      // Extend this logic for other step types
      return null;
    };

    if (this.state.stepsToSkip.length && this.state.stepsToSkip.includes(Number(nextQuestionId))) {
      const nextTarget = await getNextTarget(nextStep);
      return this.getNextQuestionId(nextTarget || nextQuestionId, answer, true);
    }

    if (nextStep?.dataDependencies?.length) {
      for (let i = 0; i < nextStep.dataDependencies.length; i += 1) {
        const { key: dependencyKey, options: dependencyOptions } = nextStep.dataDependencies[i];
        const dependencyHandler = dataDependencyHandlers[dependencyKey];
        if (dependencyHandler) {
          // eslint-disable-next-line no-await-in-loop
          const dataForDependency = await dependencyHandler(
            this.state,
            answer,
            this.context,
            dependencyOptions
          );
          if (dataForDependency) {
            this.setState({ [dependencyKey]: dataForDependency });
          }
        }
      }
    }

    if (nextStep?.skipQuestionActions) {
      const skipToTarget = await runNextQuestionActions(nextStep.skipQuestionActions);
      if (skipToTarget && skipToTarget !== nextQuestionId) {
        return this.getNextQuestionId(skipToTarget, answer, true);
      }
    }
    return nextQuestionId;
  };

  updateStateBeforeRouteChange = (
    answerValue?: UpdateStepObj,
    chosenPlan?: ChosenPlan,
    chosenTherapist?,
    previousStep?,
    overrideFlowData: Partial<Record<'flowId' | 'stepId', number>> = {}
  ) => {
    if (chosenPlan) {
      this.setState({ chosenPlan });
    }
    if (chosenTherapist !== undefined) {
      this.setState({
        therapistFirstName: chosenTherapist.firstName,
        therapistLastName: chosenTherapist.lastName,
        therapistTitle: chosenTherapist.title,
        therapistId: chosenTherapist.id,
        therapistLicenses: chosenTherapist.licenses,
        isNoMatches: false,
      });
    }

    if (answerValue) {
      // eslint-disable-next-line no-param-reassign
      answerValue.flowId = overrideFlowData.flowId || this.state.flowId;

      // eslint-disable-next-line no-param-reassign
      answerValue.stepId = overrideFlowData.stepId || this.state.stepId;

      // eslint-disable-next-line no-param-reassign
      answerValue.response_value = answerValue.response_value || undefined;

      if (typeof answerValue.response_value === 'number') {
        // eslint-disable-next-line no-param-reassign
        answerValue.response_value = (answerValue.response_value as number).toString();
      }

      if (!answerValue.discard_answer && !answerValue.appointment) {
        this.setState(
          (state) => {
            // overwrite if question has already been answered
            const responses = state.responses.filter(
              (response) => response.stepId !== answerValue.stepId
            );

            return { responses: responses.concat(answerValue) };
          },
          () =>
            mapSelfServeFields(previousStep, answerValue, {
              stepAnswers: this.state.stepAnswers,
              responses: this.state.responses,
              clientState: this.state.clientState,
            })
        );
      }
      if (shouldSkipOrRemovePreferredModality(this.state)) {
        this.setState((state) => {
          // remove preferred modality response
          const responses = state.responses.filter(
            (response) => response.response_self_serve?.field !== 'sessionModality'
          );

          return { responses };
        });
      }

      // eslint-disable-next-line no-param-reassign
      delete answerValue.discard_answer;

      if (answerValue.appointment !== undefined) {
        this.setState({ appointment: answerValue.appointment });
      }

      if (answerValue.invalidVoucher !== undefined) {
        this.setState({ invalidVoucher: answerValue.invalidVoucher });
      }

      if (answerValue.service !== undefined) {
        this.setState({ service: answerValue.service });
      }

      if ('insurancePayer' in answerValue) {
        this.setState({ insurancePayer: answerValue.insurancePayer });
        this.setInsuranceDetails({
          gediPayerID: answerValue.insurancePayer?.value,
          payerName: answerValue.insurancePayer?.label,
        });
      }

      if ('insuranceEligibility' in answerValue) {
        this.setState({ insuranceEligibility: answerValue.insuranceEligibility });
        if (answerValue.insuranceEligibility) {
          this.setInsuranceDetails(answerValue.insuranceEligibility);
        }
      }
      if (answerValue.trizettoErrorStatusCode || answerValue.trizettoErrorStatusCode === 0) {
        this.setState({
          trizettoErrorStatusCode: answerValue.trizettoErrorStatusCode,
        });
      }

      if ('isCouldNotBeVerified' in answerValue) {
        this.setState({
          isCouldNotBeVerified: !!answerValue.isCouldNotBeVerified,
        });
      }

      if (answerValue.isVideoOnlyPlan !== undefined) {
        this.setState({ isVideoOnlyPlan: answerValue.isVideoOnlyPlan });
        this.setInsuranceDetails({ isVideoOnlyPlan: answerValue.isVideoOnlyPlan });
      }
      if (answerValue.payfirst_step_id === MEMBER_AVAILABILITY_STEP) {
        this.setState({ memberAvailability: JSON.parse(answerValue.response_value!) });
      }

      if (!answerValue.invalidVoucher) {
        if (answerValue.params) {
          this.setState({ answerParams: answerValue.params });
        } else if (answerValue.voucher) {
          this.setState({
            voucher: answerValue.voucher,
            copayCents: answerValue.copayCents,
            insuranceCode: answerValue.insuranceCode,
            trizettoRequest: answerValue.trizettoRequestId,
          });
        } else if (answerValue.insuranceCode && answerValue.trizettoRequestId) {
          this.setState({
            insuranceCode: answerValue.insuranceCode,
            trizettoRequest: answerValue.trizettoRequestId,
          });
        }
      }
      if ('quickEligibilityInfo' in answerValue) {
        if (answerValue?.quickEligibilityInfo) {
          const { quickEligibilityInfo } = answerValue;
          if (quickEligibilityInfo.dob) {
            this.handleClientDateOfBirth(
              moment(quickEligibilityInfo.dob).format('YYYY-MM-DD'),
              true
            );
          }
          this.setQuickEligibilityInfo(quickEligibilityInfo);
          if (quickEligibilityInfo.insuranceEligibility?.isVideoOnlyPlan !== undefined) {
            this.setState({
              isVideoOnlyPlan: quickEligibilityInfo.insuranceEligibility.isVideoOnlyPlan,
            });
          }

          this.setInsuranceDetails({
            firstName: quickEligibilityInfo.firstName,
            lastName: quickEligibilityInfo.lastName,
            state: quickEligibilityInfo.state,
            dob: quickEligibilityInfo.dob,
            gediPayerID: quickEligibilityInfo.insuranceEligibility?.gediPayerID,
            payerName: quickEligibilityInfo.insuranceEligibility?.payerName,
            memberID: quickEligibilityInfo.insuranceEligibility?.memberID,
            dependentRelationship: quickEligibilityInfo.insuranceEligibility?.dependentRelationship,
            copayCents: quickEligibilityInfo.insuranceEligibility?.copayCents,
            isEligible: quickEligibilityInfo.insuranceEligibility?.isEligible,
            isVideoOnlyPlan: quickEligibilityInfo.insuranceEligibility?.isVideoOnlyPlan,
            verificationSucceeded: quickEligibilityInfo.insuranceEligibility?.verificationSucceeded,
          });
        } else {
          this.setState({ quickEligibilityInfo: null });
        }
      }

      if (answerValue.response_category_id === 3) {
        this.setState({ clientMatchGenderPreference: answerValue.response_value });
      }
      if (answerValue.response_category_id === 5) {
        if (Number(answerValue.response_value) === 5) {
          // TODO:  Why does this exist?
          //        This breaks checks for clientAge === 5 unless clientAge is set outside current flow
          this.setState({ clientAge: 1, clientAgeTitle: answerValue.response_prompt });
        } else {
          this.setState({
            clientAge: answerValue.response_value,
            clientAgeTitle: answerValue.response_prompt,
          });
        }
      }
      if (answerValue.response_category_id === 4) {
        const gender = Number(answerValue.response_value);
        this.setState({ clientGender: isNaN(gender) ? undefined : (gender as GenderType) });
      }

      if (answerValue.response_category_id === 2) {
        const { response_value: problemsIds } = answerValue;

        let clientMatchExpertisePreference: string | undefined;
        let clientMatchPresentingProblems: Array<ClientMatchPresentingProblem> = [];

        if (problemsIds) {
          const problemIdsArr = problemsIds.split(',').map(Number);
          clientMatchExpertisePreference =
            getFieldsOfExpertise(problemIdsArr).toString() || undefined;
          const { flowConfig } = this.state;

          if (this.getFlowId() === FLOW_133_ISRAEL) {
            clientMatchPresentingProblems = getPresentingProblemsByIds(
              problemIdsArr,
              'israelPresentingProblems'
            );
          } else if (flowConfig?.serviceType === 'psychiatry') {
            clientMatchPresentingProblems = getPresentingProblemsByIds(
              problemIdsArr,
              'simplifiedPsychiatry'
            );
          } else if (flowConfig?.serviceType === 'therapyTeen') {
            clientMatchPresentingProblems = getPresentingProblemsByIds(
              problemIdsArr,
              'teenPresentingProblems'
            );
          } else {
            clientMatchPresentingProblems = getPresentingProblemsByIds(
              problemIdsArr,
              'simplifiedTherapy'
            );
          }
        }
        this.setState({ clientMatchPresentingProblems, clientMatchExpertisePreference });
      }

      if (answerValue.response_category_id === 6) {
        if (this.state.livesInTheUS) {
          this.setState({ clientState: answerValue.response_value, clientCountry: 'US' });
        } else {
          this.setState({
            clientCountry: answerValue.response_value,
            clientState: undefined,
          });
        }
        // eslint-disable-next-line no-param-reassign
        answerValue.livesInTheUS = this.state.livesInTheUS;
      }

      if ('organizationFlowID' in answerValue) {
        this.setState({ organizationFlowID: answerValue.organizationFlowID });
      }
      if ('partnerFlowID' in answerValue) {
        this.setState({ partnerFlowID: answerValue.partnerFlowID });
      }
      if ('isB2BOutOfNetwork' in answerValue) {
        this.setState({ isB2BOutOfNetwork: answerValue.isB2BOutOfNetwork });
      }
      if (answerValue.b2bForkResult) {
        this.setState({ b2bForkResult: answerValue.b2bForkResult });
      }

      // isNoMatches
      if (answerValue.isNoMatches) {
        this.setState({
          therapistFirstName: '',
          therapistLastName: '',
          therapistTitle: '',
          therapistId: 0,
          therapistLicenses: null,
          isNoMatches: true,
        });
      }
      if ('collectReferralSourceOnSignUp' in answerValue) {
        this.setState({ collectReferralSourceOnSignUp: answerValue.collectReferralSourceOnSignUp });
      }
      if (answerValue?.allowedModalities) {
        this.setState({ allowedModalities: answerValue.allowedModalities });
      }
      if (answerValue?.totalSessions) {
        this.setState({ totalSessions: answerValue.totalSessions });
      }
      if (answerValue?.accountType) {
        this.setState({ accountType: answerValue.accountType });
      }
      if ('manualFlowID' in answerValue) {
        this.setState({ manualFlowID: answerValue.manualFlowID });
      }

      if ('skipCreditCard' in answerValue) {
        this.setState({ skipCreditCard: answerValue.skipCreditCard });
      }

      const searchParams = new URLSearchParams(this.props.location.search);
      // dispatcherInQM successful response
      const {
        accessCode,
        accessCodeType,
        organizationFlowID,
        oneFormClientState,
        oneFormClientAge,
      } = answerValue;
      if (accessCode && accessCodeType) {
        const stateUpdate = {
          [accessCodeType]: accessCode,
        } as Pick<HomePageState, 'qmPartnerCode' | 'cpPartnerCode'>;
        this.setState(stateUpdate);
        searchParams.set(accessCodeType, accessCode);
        searchParams.set('accessCodeType', accessCodeType);
        if (organizationFlowID) {
          searchParams.set('orgFlowId', organizationFlowID.toString());
        }
      }

      if (oneFormClientState && oneFormClientState in states) {
        searchParams.set('clientState', oneFormClientState);
      }
      if (oneFormClientAge && moment(oneFormClientAge).isValid()) {
        searchParams.set('clientAge', String(oneFormClientAge));
      }

      if (searchParams.toString() !== this.props.location.search.replace('?', '')) {
        this.nextStepSearchRef = searchParams.toString() && `?${searchParams.toString()}`;
      }

      FORBIDDEN_RESPONSES_KEYS.forEach((forbiddenKey) => {
        if (Object.keys(answerValue).includes(forbiddenKey)) {
          // eslint-disable-next-line no-param-reassign
          delete answerValue[forbiddenKey];
        }
      });
    }
    this.updateFieldsOfExpertise();
  };

  scrollPage = ({ top, behavior }: { top: number; behavior?: ScrollBehavior }) => {
    if (this.pageRef && this.pageRef.current) this.pageRef.current.scrollTo({ top, behavior });
  };

  changeRouteAndUpdateHistory = (
    nextStepId: string | number | null | undefined,
    replace = false
  ): Promise<void> =>
    new Promise<void>((resolve) => {
      this.setState({ opacity: 0 }, () => {
        const { search } = this.props.location;
        // If restoring session, keep the opacity in 0
        // This boolean is set to false on the last updateStep so that it will put the opacity as 1 again.
        if (this.state.restoringSession) {
          this.props.history.push(
            `/flow/${this.state.flowId}/step/${nextStepId}${this.nextStepSearchRef || search}`
          );
          this.nextStepSearchRef = null;
          resolve();
        } else {
          setTimeout(() => {
            this.scrollPage({ top: 0 });
            const newURL = `/flow/${this.state.flowId}/step/${nextStepId}${
              this.nextStepSearchRef || search
            }`;
            if (replace) {
              this.props.history.replace(newURL);
            } else {
              this.props.history.push(newURL);
            }
            this.nextStepSearchRef = null;

            setTimeout(() => {
              this.setState({ opacity: 1 }, resolve);
            }, 200);
          }, 200);
        }
      });
    });

  saveStepUpdate = (
    stepAnswer,
    newStepId: string | number | null | undefined,
    updateStateOnly = false
  ) => {
    let { stepAnswers } = this.state;
    const previousAnswerIndex = stepAnswers.findIndex(
      (answer) => answer.stepId === stepAnswer.stepId && stepAnswer.flowId === answer.flowId
    );
    // If the step answer was already in the array, replace it with the new answer
    if (previousAnswerIndex >= 0) {
      stepAnswers = [
        ...stepAnswers.slice(0, previousAnswerIndex),
        stepAnswer,
        ...stepAnswers.slice(previousAnswerIndex + 1),
      ];
    } else {
      // Otherwise just append at the end
      stepAnswers.push(stepAnswer);
    }
    this.setState({ stepAnswers });
    // No need to re-call the /session/set API while restoring
    if (this.state.restoringSession || updateStateOnly) return;
    const { flowId } = this.state;
    const { stepId } = this.state;
    // This method does the HTTP call that saves this data on the server
    if (this.state.flowConfig)
      this.props.updateAndSave({
        search: this.props.location.search,
        stepAnswers,
        flowId,
        stepId: Number(newStepId) || stepId,
        flowVersion: this.state.flowConfig.version,
        ...recoveredStateFromHomePageState.reduce((curr, key) => {
          return { ...curr, [key]: this.state[key] };
        }, {}),
      });
  };

  updateStep: UpdateStep = (
    questionId,
    answerValue,
    chosenTherapist?,
    chosenPlan?,
    {
      flowId = this.getFlowId(),
      stepId = this.state.stepId,
      updateStateOnly = false,
      removeStepFromHistory = false,
    } = {}
  ) => {
    debug('HomePage.updateStep', {
      questionId,
      answerValue,
      chosenTherapist,
      chosenPlan,
      flowId,
      stepId,
      updateStateOnly,
      removeStepFromHistory,
    });

    return new Promise(async (resolve) => {
      const stepAnswer = {
        questionId,
        answerValue,
        chosenTherapist,
        chosenPlan,
        flowId,
        stepId,
        // ...(DEBUG && {
        //   currentState: JSON.stringify(
        //     _.omit(this.state, 'opacity', 'stepAnswers', 'restoringSession')
        //   ),
        // }),
      };
      if (flowId !== this.getFlowId()) return resolve();
      if (!this.state.restoringSession) {
        this.trackStepChange();
      }

      if (typeof questionId === 'string' && questionId.includes('http')) {
        window.onbeforeunload = null;
        window.location.href = questionId;
        return resolve();
      }

      if (!window.onbeforeunload && document.domain.endsWith('talkspace.com')) {
        // disabling this message on localhost because it is deeply annoying
        window.onbeforeunload = () => true;
      }

      const flow = flows.find((f) => f.flowId === this.state.flowId);

      if (!flow) throw new Error('Flow missing');

      const previousStep = flow.steps.find(
        (step) => step.id === Number(this.props.match.params.stepId)
      );

      if (!this.state.restoringSession) {
        this.trackStepAnswer(previousStep, answerValue);
      }

      this.updateStateBeforeRouteChange(
        { ...answerValue },
        chosenPlan,
        chosenTherapist,
        previousStep,
        { flowId, stepId }
      );

      const nextQuestionId = await this.getNextQuestionId(questionId, answerValue);
      this.saveStepUpdate(stepAnswer, nextQuestionId, updateStateOnly);

      if (updateStateOnly) return resolve();

      // If target is matchesStep and there is no loading step in the flow,
      // perform get matches here
      if (
        getStepFromFlowID(flowId, isMatchesStep)?.id === Number(nextQuestionId) &&
        !getStepFromFlowID(flowId, isLoadingStep)
      ) {
        // Small sleep to get up-to-date state
        await sleep(10);
        await this.getMatches();
      }

      if (
        (this.state.isReactivationFlow ||
          this.state.isUpdateCoverageFlow ||
          this.state.isMBHIneligibilityFlow) &&
        getStepFromFlowID(flowId, isCurrentProviderStep)?.id === Number(nextQuestionId)
      ) {
        await this.checkProvidersAvailability();
      }

      return resolve(this.changeRouteAndUpdateHistory(nextQuestionId, removeStepFromHistory));
    });
  };

  handleChangeTier = (tier) => this.setState({ currentTier: tier });

  changeCouponText = () => {
    const stepOneText = document.getElementById('coupon-step-1');
    const stepTwoText = document.getElementById('coupon-step-2');
    if (this.state.coupon && stepOneText && stepTwoText) {
      if (
        this.state.couponApplied &&
        this.state.step &&
        this.state.step.category.includes('payment')
      ) {
        stepTwoText.style.display = 'block';
        stepOneText.style.display = 'none';
      } else {
        stepTwoText.style.display = 'none';
        stepOneText.style.display = 'block';
      }
    }
  };

  setBlurParentContent = (bool) => {
    this.setState({
      shouldBlurContent: bool,
    });
  };

  setIsPayerOutage = (bool) => {
    this.setState({
      isPayerOutage: bool,
    });
  };

  updateFieldsOfExpertise() {
    this.setState((state) => {
      const { livesInTheUS } = state;
      let { clientMatchExpertisePreference } = state;
      const internationalFieldOfExpertise = Number(getInternationalFieldOfExpertise());
      const adolescentFieldOfExpertise = Number(getAdolescentFieldOfExpertise());
      const momentDateOfBirth = moment(state.clientDateOfBirth);
      // eslint-disable-next-line react/no-access-state-in-setstate
      const responses = state.responses.map((answer) => {
        if (answer.response_category_id !== 2) {
          return answer;
        }

        if (!answer.response_self_serve) {
          clientMatchExpertisePreference = undefined;
          return answer;
        }

        if (!Array.isArray(answer.response_self_serve.value)) {
          // eslint-disable-next-line no-param-reassign
          answer.response_self_serve.value = [];
        }

        if (!livesInTheUS) {
          if (!answer.response_self_serve.value.includes(internationalFieldOfExpertise)) {
            answer.response_self_serve.value.push(internationalFieldOfExpertise);
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          answer.response_self_serve.value = answer.response_self_serve.value.filter(
            (value) => value !== internationalFieldOfExpertise
          );
        }

        if (
          state.clientDateOfBirth &&
          momentDateOfBirth.isAfter(moment().subtract(18, 'years')) &&
          momentDateOfBirth.isBefore(moment().subtract(13, 'years'))
        ) {
          if (!answer.response_self_serve.value.includes(adolescentFieldOfExpertise)) {
            answer.response_self_serve.value.push(adolescentFieldOfExpertise);
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          answer.response_self_serve.value = answer.response_self_serve.value.filter(
            (value) => value !== adolescentFieldOfExpertise
          );
        }
        clientMatchExpertisePreference = answer.response_self_serve.value.toString() || undefined;

        return answer;
      });
      return { clientMatchExpertisePreference, responses };
    });
  }

  render() {
    // footer needs to stay fixed to the bottom of the page on certain steps with scroll-able containers
    const shouldAnchorFooter = this.state.step
      ? (isMatchesStep(this.state.step) &&
          Array.isArray(this.state.matches) &&
          this.state.matches.length > 0) ||
        (!this.state.isConfirmScheduler && isSchedulerStep(this.state.step))
      : false;
    this.changeCouponText();
    const actions: HomePageActions = {
      setOverriddenBackButtonBehavior: this.setOverriddenBackButtonBehavior,
      setHomePageState: this.setHomePageState,
      getFlow: this.getFlow,
      getFlowConfig: this.getFlowConfig,
      getMaxCost: this.getMaxCost,
      getMaxCostByFlowConfig: this.getMaxCostByFlowConfig,
      setIsRegistrationError: this.setIsRegistrationError,
      setIsPayerOutage: this.setIsPayerOutage,
    };
    return (
      <HomePageContext.Provider value={this.state}>
        <HomePageActionsContext.Provider value={actions}>
          <Page
            step={this.state.step}
            isLoggedIn={this.isLoggedInUser}
            flowId={this.state.flowId}
            stepCategory={this.state.step?.category}
            pageStyles={{
              filter: this.state.shouldBlurContent ? `blur(${BLUR_CONTENT_RADIUS}px)` : 'none',
            }}
            ref={this.pageRef}
            headerRef={this.headerRef}
            shouldAnchorFooter={shouldAnchorFooter}
            isRegistrationError={this.state.isRegistrationError}
            isUpdateCoverageFlow={this.state.isUpdateCoverageFlow}
            skipCreditCard={this.state.skipCreditCard}
          >
            {this.state.step && (
              <StepLayout
                isLoggedInUser={this.isLoggedInUser}
                setIsConfirmScheduler={this.setIsConfirmScheduler}
                hasCoupon={!!this.state.coupon}
                coupon={this.state.coupon}
                redirectFrom={this.state.redirectFrom}
                livesInTheUS={this.state.livesInTheUS}
                opacity={this.state.opacity}
                flowId={this.state.flowId}
                qmPartnerCode={this.state.qmPartnerCode}
                cpPartnerCode={this.state.cpPartnerCode}
                copayCents={this.state.copayCents}
                insuranceCode={this.state.insuranceCode}
                isVideoOnlyPlan={this.state.isVideoOnlyPlan}
                step={this.state.step}
                matchesReceived={Array.isArray(this.state.matches)}
                matches={this.state.matches}
                voucher={this.state.voucher}
                exactMatch={this.state.exactMatch}
                // Prevent individual steps from invoking updateStep during session recovery
                updateStep={this.state.restoringSession ? () => Promise.resolve() : this.updateStep}
                answerParams={this.state.answerParams}
                quickEligibilityInfo={this.state.quickEligibilityInfo}
                memberAvailability={this.state.memberAvailability}
                setEligibilityFiles={this.setEligibilityFiles}
                eligibilityFiles={this.state.eligibilityFiles}
                onShowCountries={this.handleShowCountries}
                handleClientDateOfBirth={this.handleClientDateOfBirth}
                handleClientAge={this.handleClientAge}
                therapistId={this.state.therapistId}
                therapistIDFromCreateRoom={this.state.therapistIDFromCreateRoom}
                therapistFirstName={this.state.therapistFirstName}
                therapistLastName={this.state.therapistLastName}
                therapistTitle={this.state.therapistTitle}
                therapistLicenses={this.state.therapistLicenses}
                chosenPlan={this.state.chosenPlan}
                responses={this.state.responses}
                clientState={this.state.clientState}
                goBack={this.goBack}
                appointment={this.state.appointment}
                invalidVoucher={this.state.invalidVoucher}
                setDiscardMatches={this.setDiscardMatches}
                isFiltersModalVisible={this.state.isFiltersModalVisible}
                getAnalyticsPaymentInfo={this.getAnalyticsPaymentInfo}
                onHideFiltersModal={this.handleHideFiltersModal}
                clientMatchPresentingProblems={this.state.clientMatchPresentingProblems}
                onRetargetingSubmit={this.handleRetargetingSubmit}
                isDisabledPreviousButton={this.state.isDisabledPreviousButton}
                funnelVariation={this.state.funnelVariation}
                service={this.state.service}
                clientDateOfBirth={this.state.clientDateOfBirth}
                clientAgeNumber={this.state.clientAgeNumber}
                clientUnderAge={this.state.clientAgeTitle === '13-17'}
                onChangeTier={this.handleChangeTier}
                currentTier={this.state.currentTier}
                handleShowFiltersModal={this.handleShowFiltersModal}
                trizettoErrorStatusCode={this.state.trizettoErrorStatusCode}
                trizettoRequest={this.state.trizettoRequest}
                resetLivingInfo={this.resetLivingInfo}
                shouldResetLivingInfo={this.state.shouldResetLivingInfo}
                isCreateRoomFlow={this.state.isCreateRoomFlow}
                isReactivationFlow={this.state.isReactivationFlow}
                isUpdateCoverageFlow={this.state.isUpdateCoverageFlow}
                isMBHIneligibilityFlow={this.state.isMBHIneligibilityFlow}
                keepProvider={this.state.keepProvider}
                roomID={this.state.roomID}
                setRoomID={this.setRoomID}
                existingUserEmail={this.state.existingUserEmail}
                insuranceEligibility={this.state.insuranceEligibility}
                setQMFlowDone={this.setQMFlowDone}
                flowConfig={this.state.flowConfig}
                redirectFromFlowConfig={this.state.redirectFromFlowConfig}
                roomType={this.state.roomType}
                clientCountry={this.state.clientCountry}
                setBlurParentContent={this.setBlurParentContent}
                clientMatchGenderPreference={this.state.clientMatchGenderPreference}
                selectedMatch={this.getSelectedMatch()}
                clientGender={this.state.clientGender}
                scrollPage={this.scrollPage}
                applyFilters={this.applyFilters}
                isNoMatches={this.state.isNoMatches}
                loadingMatches={this.state.loadingMatches}
                removeGenderFilter={this.removeGenderFilter}
                isMatchesOverlayDismissed={this.state.isMatchesOverlayDismissed}
                handleSetIsMatchesOverlayDismissed={this.handleSetIsMatchesOverlayDismissed}
                organizationFlowID={this.state.organizationFlowID}
                b2bForkResult={this.state.b2bForkResult}
                insurancePayer={this.state.insurancePayer}
                partnerFlowID={this.state.partnerFlowID}
                isB2BOutOfNetwork={this.state.isB2BOutOfNetwork}
                cognitoActive={this.props.cognitoActive}
                experimentsVariants={this.state.experimentsVariants}
                collectReferralSourceOnSignUp={this.state.collectReferralSourceOnSignUp}
                handleReferralSource={this.handleReferralSource}
                referralSource={this.state.referralSource}
                currentProvidersInfo={this.state.currentProvidersInfo}
                hasOnlyPlaceholderProviders={this.state.hasOnlyPlaceholderProviders}
                allowedModalities={this.state.allowedModalities}
                totalSessions={this.state.totalSessions}
                accountType={this.state.accountType}
                manualFlowID={this.state.manualFlowID}
                hideVWOBar={this.state.hideVWOBar}
                reviewPlanDetails={this.state.reviewPlanDetails}
                updateCoverageRoomID={this.state.updateCoverageRoomID}
                oldRoomHasSessions={this.state.oldRoomHasSessions}
                sessionsCanceled={this.state.sessionsCanceled}
                sessionsTransferred={this.state.sessionsTransferred}
                setCreateRoomReturnProps={this.setCreateRoomReturnProps}
                setIsCouldNotBeVerified={this.setIsCouldNotBeVerified}
                isCouldNotBeVerified={this.state.isCouldNotBeVerified}
                manualVerificationEmail={this.state.manualVerificationEmail}
                setManualVerificationEmail={this.setManualVerificationEmail}
                previousTherapistId={this.state.previousTherapistId}
                buttonsFieldStyle={
                  this.getFlowId() === FLOW_133_ISRAEL ? { textAlign: 'right' } : {}
                }
                matchingLanguages={this.state.matchingLanguages}
                stepAnswers={this.state.stepAnswers}
                headerRef={this.headerRef}
                setInsuranceDetails={this.setInsuranceDetails}
                insuranceDetails={this.state.insuranceDetails}
                isGDPR={this.state.isGDPR}
                skipCreditCard={this.state.skipCreditCard}
              />
            )}
          </Page>
        </HomePageActionsContext.Provider>
      </HomePageContext.Provider>
    );
  }
}

HomePage.contextType = FlagsContext;

export const useHomePageActions = (): HomePageActions => {
  const result = useContext(HomePageActionsContext);
  if (!result) {
    throw new Error('HomePageActionsContext has no value');
  }
  return result;
};

export const useHomePageState = (): HomePageState => {
  const result = useContext(HomePageContext);
  if (!result) {
    throw new Error('HomePageContext has no value');
  }
  return result;
};

export default compose(withRouter, withSetCustomAttributes, withInitialQuestionSkipper)(HomePage);
