import moment from 'moment-timezone';
// eslint-disable-next-line import/no-cycle
import { isTeensFlow } from './flowsHelper';

// Note: using an array of objects because order matters in displaying the labels
const AGE_GROUP_MAPPINGS = [
  { 6: 'Under 13' },
  { 5: '13-17' },
  { 1: '18-25' },
  { 2: '26-35' },
  { 3: '36-49' },
  { 4: '50 +' },
];

export const MIN_AGE_ERROR = 'You must be at least 13';

function labelById(id) {
  const ageGroupById = AGE_GROUP_MAPPINGS.find(
    (item) => Number(Object.keys(item)[0]) === Number(id)
  );

  return ageGroupById && Object.values(ageGroupById)[0];
}

export function ageErrorCopy(flowId, ageLocationEligibility = false) {
  const teenFlowErrorCopy = ageLocationEligibility ? MIN_AGE_ERROR : 'You must be 13 or older';
  return isTeensFlow(flowId) ? teenFlowErrorCopy : 'Age is too young.';
}

export function ageGroups() {
  return AGE_GROUP_MAPPINGS.map((item) => {
    return {
      value: Object.keys(item)[0],
      label: Object.values(item)[0],
    };
  });
}

export function ageGroupId(age) {
  switch (true) {
    case age >= 50:
      return 4;
    case age >= 36 && age <= 49:
      return 3;
    case age >= 26 && age <= 35:
      return 2;
    case age >= 18 && age <= 25:
      return 1;
    case age >= 13 && age <= 17:
      return 5;
    default:
      return 'Under 13';
  }
}

export function ageGroupLabel(age) {
  switch (true) {
    case age >= 50:
      return labelById(4);
    case age >= 36 && age <= 49:
      return labelById(3);
    case age >= 26 && age <= 35:
      return labelById(2);
    case age >= 18 && age <= 25:
      return labelById(1);
    case age >= 13 && age <= 17:
      return labelById(5);
    default:
      return labelById(6);
  }
}

export function ageGroup(age) {
  return {
    value: ageGroupId(age),
    label: ageGroupLabel(age),
  };
}

export function ageGroupLabelById(id) {
  return labelById(id) || labelById(6);
}

export function minimumAge(flowId) {
  return isTeensFlow(flowId) ? 13 : 18;
}

export function isUnderage(dateOfBirth, flowId, format = 'MM/DD/YYYY') {
  const date = moment(dateOfBirth, format);

  return date.isAfter(moment().subtract(minimumAge(flowId), 'years')) && date.isBefore(moment());
}
export function isFutureDate(dateOfBirth, format = 'MM/DD/YYYY') {
  const date = moment(dateOfBirth, format);

  return date.isAfter(moment());
}
export function isOverAge120(dateOfBirth, format = 'MM/DD/YYYY') {
  const date = moment(dateOfBirth, format);

  return date.isBefore(moment().subtract(120, 'years'));
}

export function isUnder18(dateOfBirth) {
  const date = moment(dateOfBirth);

  return date.isAfter(moment().subtract(18, 'years')) && date.isBefore(moment());
}

// Checks whether a date is complete and valid and whether or not it's "valid" for a given qm flow's age restrictions
export function isValidDate(dateOfBirth, flowId, dateFormat = 'YYYY-MM-DD') {
  const date = moment(dateOfBirth, dateFormat, true);
  return (
    date.isValid() &&
    !date.isAfter(moment().subtract(minimumAge(flowId), 'years')) &&
    !date.isBefore(moment().subtract(120, 'years'))
  );
}
