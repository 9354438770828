import { AxiosError } from 'axios';

const bhInsuranceEligibilityErrorParser = (error: AxiosError) => {
  let isEligibilityProviderError;
  let isPayerOutage;
  let eligibilityErrorType;
  let eligibilityErrorDescription;
  let errorMessage;
  if ((error as AxiosError)?.response?.data) {
    ({
      isEligibilityProviderError,
      isPayerOutage,
      eligibilityErrorType,
      eligibilityErrorDescription,
      errorMessage,
    } = (error as AxiosError)?.response?.data || {});
  }

  if (isEligibilityProviderError && eligibilityErrorType) {
    return eligibilityErrorType;
  }
  if (isPayerOutage && eligibilityErrorDescription) {
    return eligibilityErrorDescription;
  }

  return errorMessage;
};

export default bhInsuranceEligibilityErrorParser;
