import { AccountType, HomePageState } from '../Components/HomePage/types';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';
import { OneFormEligibilityStep, QMFlow, UpdateStepObj } from './types';
// eslint-disable-next-line import/no-cycle
import {
  allRegistrationPagesSteps,
  ineligiblePromo,
  initFlowConfig,
  registrationStepsValidation,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
import { QM_COPY } from '../utils/qmCopy';
import { states } from '../Helpers/locales';

const OFFER_ID = 62;
const INITIAL_OFFER_STEP_ID = 24;

export const getRegistrationStepIDByFlowID = (
  state: HomePageState,
  answer?: UpdateStepObj
): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = organizationFlowID || partnerFlowID;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);
    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

const skipModalitySelectionIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const allowedModalities = answer?.allowedModalities || state.allowedModalities || undefined;
    if (shouldSkipOrRemovePreferredModality({ ...state, allowedModalities })) {
      return skipToStepID || getRegistrationStepIDByFlowID(state, answer); // skip to registration if no modalities found.
    }
    return undefined;
  };

const skipReviewPlanIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const accountType = answer?.accountType || state.accountType;

    const isCouldNotBeVerified = answer?.isCouldNotBeVerified || state.isCouldNotBeVerified;

    if (accountType && [AccountType.EAP, AccountType.BH].includes(accountType)) {
      return undefined;
    }
    if (isCouldNotBeVerified) {
      return undefined;
    }
    return skipModalitySelectionIfIrrelevant(skipToStepID)(state, answer) || 26; // go to modalities
  };

// Omitting category for readability
const ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG: Omit<OneFormEligibilityStep, 'id' | 'category'> = {
  analyticsId: 54,
  response_category_id: 9,
  ...ineligiblePromo,
};

export const FLOW_155_THERAPY_GENERAL_SHORT = 155;

const flow: QMFlow = {
  flowId: FLOW_155_THERAPY_GENERAL_SHORT,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychotherapy',
    isB2B: true,
    isTeen: true,
    shouldHideServiceSelection: true,
    moveCoverageEnabled: false,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 135,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: "To begin, tell us why you're looking for help today.",
      progressCurrent: 1,
      progressMax: 11,
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'When are you generally available for therapy?',
      heading2: 'When are you generally available for therapy?',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(11),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 11,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 11,
      progressCurrent: 1.5,
      progressMax: 11,
    },
    // matching questions
    {
      id: 11,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender do you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 12,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 12,
          answerValue: undefined,
        },
      ],
      progressCurrent: 2,
      progressMax: 11,
    },
    {
      id: 12,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 15,
      skipExternalTarget: null,
      progressCurrent: 3,
      progressMax: 11,
    },
    {
      id: 15,
      category: 'oneFormEligibility', // BH
      buttonTarget: 32,
      noCoverageHideEAP: true,
      noCoverageButtonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [
        (state, answer, flags) => {
          const insuranceConfirmationAnswer = state.stepAnswers.find(
            (stepAnswer) =>
              Number(stepAnswer.flowId) === Number(state.flowId) && Number(stepAnswer.stepId) === 1
          );

          if (
            insuranceConfirmationAnswer?.answerValue?.insuranceConfirmation === 'check' &&
            state.quickEligibilityInfo?.insuranceEligibility?.isEligible &&
            state.quickEligibilityInfo?.insuranceEligibility?.verificationSucceeded
          ) {
            return 26;
          }

          if (state.b2bForkResult === 'b2b' && state.isB2BOutOfNetwork) {
            // for OON after filling this form, navigate to QM questions
            return (
              skipIfExperiment({
                experimentName: 'emailLeadCaptureMove',
                variant: ['moveCopy1', 'moveCopy2'],
                nextStepId: 200,
                shouldTrack: true,
              })(state, answer, flags) || 1
            );
          }
          return undefined;
        },
        skipReviewPlanIfIrrelevant(),
      ],
      disableOrganization: true,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    {
      id: 32,
      category: 'reviewPlan',
      buttonTarget: 26,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant()],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
    }),
  ],
};

export default flow;
