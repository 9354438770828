import { useTranslation } from '@talkspace/i18n';
import { useWatch } from 'react-hook-form';
import { RHFInput } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useEffect } from 'react';
import { useContinue, useFormSaver } from './hooks';
import { useEligibilityService, EligibilityServiceFormData } from '@/hooks/useEligibilityService';
import StatusModal from './StatusModal';
import { QuickEligibilityProps } from './types';
import countriesHelper from '@/Helpers/countriesHelper';
import EligibilityForm, {
  AA_TOOLTIP_STYLE,
  AdditionalFieldsParams,
  Styled,
} from './EligibilityForm';
import useMemberIDHint from '@/hooks/useMemberIDHint';
import { trackBHEligibilityScreenSeen } from '../../../utils/analytics/events';

const MemberIdEligibility = ({
  updateStep,
  step,
  insurancePayer,
  setReferralSource,
  referralSource,
  partnerFlowID,
  fillContainerBannerHeight,
  insuranceFieldTop,
  isEditInsuranceInfoScreen,
}: QuickEligibilityProps) => {
  useFormSaver();

  const { bhInsuranceEligibilityError } = useFlags();

  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');

  const { result, done, error, errorType, canRetry, attempts, isSubmitting, submit } =
    useEligibilityService({
      maxAttempts: 3,
    });

  const data = useWatch() as any as EligibilityServiceFormData;

  const memberIDHint = useMemberIDHint(insurancePayer?.partnerID || '');

  useEffect(() => {
    if (bhInsuranceEligibilityError !== undefined) {
      trackBHEligibilityScreenSeen('Check your coverage Seen', 'check-your-coverage');
    }
  }, [bhInsuranceEligibilityError]);

  useContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    attempts,
    insurancePayer,
    error,
  });

  const statusModalIsVisible = (isSubmitting || (attempts > 0 && canRetry)) && !done;
  const shouldRetry = attempts > 0 && !isSubmitting && canRetry;

  const handleOnSubmit = (formData: EligibilityServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));

    const { state: clientState, country } = countriesHelper.getStateAndCountryOverrides(
      parsedFormData.country,
      parsedFormData.state
    );

    parsedFormData.country = country;
    parsedFormData.state = clientState;

    return submit(parsedFormData);
  };

  const additionalFields = (props: AdditionalFieldsParams) => {
    const { setValue, isManualSignUpFlow } = props;
    return [
      <RHFInput
        fieldName="memberID"
        label="Member / Subscriber ID"
        tooltip={tQuickmatchFlow132(
          'step108.toolTipText',
          `Type the full ID, including letters and numbers. Your Member ID is usually printed on the front of your health insurance card. Look for a number that’s labeled "Member ID" or "Subscriber ID." You can also find your Member ID by logging into your health insurance provider’s website or mobile app. Once logged in, go to your profile or coverage details.`,
          undefined
        )}
        placeholder={memberIDHint}
        isRequired
        data-qa="member-id-input"
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
          if (newValue !== e.target.value) {
            setValue('memberID', newValue);
          }
        }}
        labelStyle={{ alignSelf: 'flex-end' }}
        tooltipStyle={AA_TOOLTIP_STYLE}
        isDisabled={isManualSignUpFlow && 'memberID' in data && !!data.memberID}
      />,
    ];
  };

  const eligibilityForm = EligibilityForm({
    done,
    handleOnSubmit,
    isSubmitting,
    data,
    referralSource,
    setReferralSource,
    additionalFields,
    insuranceFieldTop,
    isEditInsuranceInfoScreen,
    partnerFlowID,
  });

  if (result) {
    return null;
  }

  return (
    <Styled.View style={{ marginTop: fillContainerBannerHeight }}>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      {eligibilityForm}
    </Styled.View>
  );
};

export default MemberIdEligibility;
