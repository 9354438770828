import React, { useEffect, useState, VFC } from 'react';
import { CancellationPolicy } from '@talkspace/react-toolkit';
import { BookingConfirmationStep, UpdateStep } from '@/Flows';
import useBackButton from '@/Components/StepLayout/hooks';
import BookingConfirmationContent from './BookingConfirmationContent';

const BookingConfirmationContainer: VFC<{
  step: BookingConfirmationStep;
  updateStep: UpdateStep;
  goBack: () => void;
}> = ({ step, updateStep, goBack }) => {
  const { setOverriddenBackButtonBehavior } = useBackButton(step);
  const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);

  useEffect(() => {
    setOverriddenBackButtonBehavior(() => {
      if (showCancellationPolicy) {
        setShowCancellationPolicy(false);
        return;
      }

      goBack();
    });
  }, [setOverriddenBackButtonBehavior, showCancellationPolicy, setShowCancellationPolicy, goBack]);

  return (
    <>
      {!showCancellationPolicy && (
        <BookingConfirmationContent
          step={step}
          updateStep={updateStep}
          onCancellationPolicy={() => {
            setShowCancellationPolicy(true);
          }}
        />
      )}
      {showCancellationPolicy && <CancellationPolicy variant="liveWithFee" />}
    </>
  );
};

export default BookingConfirmationContainer;
