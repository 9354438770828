import { useState } from 'react';
import {
  View,
  ExtraHuge,
  Large,
  TextInputAuth,
  Button,
  useUniqueID,
  useEmotionTheme,
  Label,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router-dom';
import switchFlowHelper from '@/Helpers/switchFlowHelper';
import { getAccessCodeByLocation } from '@/Helpers/apiService';
import { LocationEligibilityStep, UpdateStep } from '@/Flows/types';
import { trackEvent } from '@/utils/analytics/events';

const TIMEOUT_GEOLOCATION_BROWSER_CLIENT_REQUEST = 9000;

interface GeolocationPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

interface LocationEligibilityProps {
  step: LocationEligibilityStep;
  flowId: number;
  updateStep: UpdateStep;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
}

const LocationEligibility = ({
  step,
  flowId,
  updateStep,
  updateAndSave,
}: LocationEligibilityProps) => {
  const history = useHistory();
  const { isSmall } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const [emailAddress, setEmailAddress] = useState('');
  const handleInputChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const [emailValidated, setEmailValidated] = useState(false);
  const handleValidationChange = (validated: boolean) => {
    setEmailValidated(validated);
  };

  const [isGettingGeolocation, setIsGettingGeolocation] = useState(false);

  const handleLocationError = () => {
    setIsGettingGeolocation(false);
    updateStep(step.internalTarget, {
      payfirst_step_id: step.analyticsId,
      payfirst_step_prompt: step.prompt,
      response_category_id: step.response_category_id,
      response_prompt: 'Check eligibility',
      response_value: emailAddress,
    });
    trackEvent('Check Location Eligibility', {
      Eligible: false,
      'Funnel Name': 'QuickMatch',
    });
  };

  const handleLocationSuccess = async (position: GeolocationPosition) => {
    setIsGettingGeolocation(false);
    // Quickmatch API Request to get: accessCodeType, accessCode, qmFlowID
    try {
      const data = await getAccessCodeByLocation({
        email: emailAddress,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        flowId,
      });
      const { accessCode, accessCodeType, qmFlowID } = data;
      const newFlowSearch = `?${accessCodeType}=${accessCode}&redirectFrom=${flowId}`;
      switchFlowHelper(qmFlowID, history, updateAndSave, {
        stepId: 1, // target flow step id
        newFlowSearch,
      });
      trackEvent('Check Location Eligibility', {
        Eligible: true,
        'Funnel Name': 'QuickMatch',
      });
    } catch (error) {
      handleLocationError();
    }
  };

  const getLocation = () =>
    new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by this browser'));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: TIMEOUT_GEOLOCATION_BROWSER_CLIENT_REQUEST,
          maximumAge: 0,
        });
      }
    });

  const handleSubmit = async () => {
    setIsGettingGeolocation(true);
    try {
      const location = await getLocation();
      handleLocationSuccess(location as GeolocationPosition);
    } catch (error) {
      handleLocationError();
    }
  };

  const inputID = useUniqueID('inputID');
  const subheadID = useUniqueID('headerID');

  return (
    <View style={{ display: 'flex' }}>
      <View
        align="center"
        style={{ minWidth: isSmall ? undefined : 375, maxWidth: 440, alignSelf: 'center' }}
      >
        <ExtraHuge as="h1" style={{ marginTop: '6.5rem' }}>
          Let's confirm your eligibility!
        </ExtraHuge>

        <Large id={subheadID} style={{ margin: 20 }}>
          We need your location and email to get you started on Talkspace for free within minutes.
        </Large>
        <View style={{ width: 340 }}>
          <Label htmlFor={inputID}>Email</Label>
        </View>
        <TextInputAuth
          id={inputID}
          type="email"
          name="email"
          placeholder="Enter email address"
          value={emailAddress}
          onChange={handleInputChange}
          onValidationChanged={handleValidationChange}
          wrapperStyle={{ width: '100%' }}
          style={{ width: 320 }}
          ariaDescribedBy={subheadID}
          roundedFocusStyle
          primaryColor={colors.periwinkleGrey}
          placeholderStyle={{ color: colors.permaGrayForContrastOnWhiteBG }}
        />

        <Button
          style={{ marginTop: 25, width: 320 }}
          disabled={!emailValidated}
          onPress={handleSubmit}
          isLoading={isGettingGeolocation}
          roundedFocusStyle
          primaryColor={colors.green}
        >
          Check eligibility
        </Button>
      </View>
    </View>
  );
};

export default LocationEligibility;
