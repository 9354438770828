import Svg, { Path } from 'svgs';

const AgeLocationIneligibleIllustration = () => {
  const title = 'age location ineligible';
  return (
    <Svg
      aria-label={title}
      width={191}
      height={192}
      viewBox="0 0 191 192"
      fill="none"
      title={title}
    >
      <Path
        d="M0 174.41c15.124-3.576 27.172 3.965 27.172 3.965C15.467 184.397 0 174.41 0 174.41zM41.81 170.606c.223 9.537-6.025 16.281-6.025 16.281-2.188-7.653 6.026-16.281 6.026-16.281zM35.048 188.327c-11.19 2.45-20.59-3.304-20.59-3.304 8.512-4.324 20.59 3.304 20.59 3.304zM20.056 167.206c.171-.126 5.223 6.446 11.285 14.674 6.061 8.229 6.713 9.361 6.566 9.487-.146.126-1.1-.798-7.162-9.032-6.062-8.234-10.861-15.003-10.69-15.129zM28.096 150.71s10.244 14.468 2.92 28.516c.02.01-8.89-12.23-2.92-28.516z"
        fill="#455A64"
      />
      <Path
        d="M0 174.411c15.124-3.576 27.172 3.966 27.172 3.966C15.467 184.398 0 174.411 0 174.411zM41.81 170.604c.223 9.537-6.025 16.281-6.025 16.281-2.188-7.653 6.026-16.281 6.026-16.281zM35.048 188.324c-11.19 2.45-20.59-3.303-20.59-3.303 8.512-4.324 20.59 3.303 20.59 3.303zM20.056 167.206c.171-.126 5.223 6.446 11.285 14.674 6.061 8.229 6.713 9.361 6.566 9.487-.146.126-1.1-.798-7.162-9.032-6.062-8.234-10.861-15.003-10.69-15.129zM28.098 150.71s10.244 14.467 2.92 28.515c.02.01-8.891-12.229-2.92-28.515z"
        fill="#000"
        opacity={0.3}
      />
      <Path
        d="M2.914 149.465s16.852 5.511 19.342 21.15c0 0-14.442-4.516-19.342-21.15z"
        fill="#455A64"
      />
      <Path
        d="M2.914 149.468s16.852 5.511 19.342 21.15c0 0-14.442-4.516-19.342-21.15z"
        fill="#000"
        opacity={0.3}
      />
      <Path d="M129.216 0H34.254v191.368h94.962V0z" fill="#CDE7DD" />
      <Path d="M125.59 3.849H37.882v187.518h87.708V3.849z" fill="#fff" />
      <Path
        d="M110.196 84.191c7.274 11.836 4.253 24.181 4.253 24.181-8.39-8.233-4.253-24.18-4.253-24.18zM125.149 118.617c-8.032 2.864-15.513-.551-15.513-.551 5.875-3.995 15.513.551 15.513.551zM107.273 116.756c-5.228-8.805-2.975-18.387-2.975-18.387 6.056 6.001 2.975 18.387 2.975 18.387zM121.937 99.2c.157.11-4.006 6.238-9.295 13.694s-9.698 13.401-9.855 13.3c-.157-.101 4.006-6.243 9.295-13.694 5.288-7.451 9.698-13.412 9.855-13.3z"
        fill="#455A64"
      />
      <Path
        d="M132.192 79.675s.045 15.851-12.533 22.348c0 0-.227-13.523 12.533-22.348zM138.218 101.443s-9.411 12.755-23.388 10.472c-.015-.026 7.865-11.018 23.388-10.472z"
        fill="#455A64"
      />
      <Path
        d="M110.198 84.19c7.274 11.836 4.253 24.181 4.253 24.181-8.39-8.233-4.253-24.18-4.253-24.18zM125.149 118.621c-8.032 2.864-15.513-.551-15.513-.551 5.875-3.995 15.513.551 15.513.551zM107.273 116.761c-5.228-8.805-2.976-18.387-2.976-18.387 6.057 6.001 2.976 18.387 2.976 18.387zM121.937 99.2c.157.11-4.006 6.238-9.295 13.694s-9.698 13.401-9.855 13.3c-.157-.101 4.006-6.243 9.295-13.694 5.288-7.451 9.698-13.412 9.855-13.3z"
        fill="#000"
        opacity={0.3}
      />
      <Path
        d="M132.192 79.677s.045 15.851-12.533 22.347c0 0-.227-13.522 12.533-22.347zM138.218 101.442s-9.411 12.755-23.388 10.472c-.015-.026 7.865-11.018 23.388-10.472z"
        fill="#000"
        opacity={0.3}
      />
      <Path
        d="M125.458 48.23a12.528 12.528 0 00-1.859-3.254 5.496 5.496 0 00-3.506-1.92c-1.424-.146-3 .506-3.465 1.642-.202-2.57-.636-5.213-2.167-7.45a8.302 8.302 0 00-11.178-2.694 8.302 8.302 0 00-2.966 2.956c-1.374 2.137-1.844 4.597-2.293 6.996-1.096-1.126-3.405-.773-4.547.324-1.141 1.096-1.43 2.626-1.672 4.04h33.885l-.232-.64z"
        fill="#fff"
      />
      <Path
        d="M129.217 0s-.071.09-.223.263l-.682.742-2.677 2.885h-.045c-7.537.045-23.176.07-41.205.07l-32.884-.035-9.967-.046H37.882c.08-.004.162-.004.242 0H41.534l9.967-.04 32.884-.04c18.029 0 33.668.03 41.205.07h-.046l2.723-2.844.702-.727c.157-.222.248-.298.248-.298z"
        fill="#263238"
      />
      <Path
        d="M139.818 131.527s.035 12.816-10.138 18.069a23.073 23.073 0 0110.138-18.069zM106.994 155.376s10.472 7.39 8.865 18.725a23.078 23.078 0 01-8.865-18.725zM158.468 144.264s-11.062 6.471-20.71.318a23.089 23.089 0 0120.71-.318zM136.166 155.232s-6.375 11.113-17.811 10.608a23.084 23.084 0 0117.811-10.608zM160.049 120.764s-.126 12.816-10.366 17.943a23.11 23.11 0 013.057-10.2 23.102 23.102 0 017.309-7.743zM179.756 131.119s-10.103 7.901-20.483 3.102a23.074 23.074 0 0120.483-3.102zM191 92.642s-.884 12.785-11.406 17.296A23.068 23.068 0 01191 92.642zM173.997 102.63s5.794 11.432-.935 20.711a23.07 23.07 0 01.935-20.711z"
        fill="#455A64"
      />
      <Path
        d="M176.315 92.635s.06.096.162.292l.449.88c.192.383.394.883.652 1.46.126.292.237.615.368.954.132.338.263.707.384 1.101a30.268 30.268 0 011.309 5.85c.055.581.141 1.182.161 1.808 0 .313.036.632.051.955v.98c0 .662-.051 1.344-.081 2.046a13.25 13.25 0 01-.288 2.141c-.434 2.93-1.768 5.961-3.687 8.84a36.722 36.722 0 01-7.507 8.083 62.286 62.286 0 01-10.254 6.612c-3.738 1.99-7.734 3.794-11.785 5.713a102.436 102.436 0 00-11.517 6.183 44.996 44.996 0 00-9.345 7.729 36.996 36.996 0 00-5.966 9.193 47.459 47.459 0 00-2.996 9.093 61.666 61.666 0 00-1.192 7.825c-.222 2.323-.268 4.334-.303 5.975-.035 1.642 0 2.92.03 3.784 0 .424 0 .753.026 1.01.025.258 0 .334 0 .334s-.152-1.814-.152-5.102c0-1.647.046-3.663.258-5.991.211-2.64.598-5.262 1.157-7.85a47.055 47.055 0 012.98-9.148 36.863 36.863 0 015.981-9.27 45.316 45.316 0 019.385-7.784 102.12 102.12 0 0111.548-6.203c4.041-1.925 8.042-3.723 11.775-5.703a61.973 61.973 0 0010.224-6.567 36.524 36.524 0 007.456-8.037c1.914-2.859 3.243-5.849 3.687-8.754.152-.717.202-1.429.293-2.121.091-.693.106-1.374.091-2.021v-.98c0-.323-.03-.647-.03-.965 0-.621-.096-1.222-.152-1.798a31.078 31.078 0 00-1.253-5.84 16.332 16.332 0 00-.373-1.096c-.127-.338-.238-.666-.354-.96-.248-.58-.445-1.08-.631-1.47l-.425-.889-.136-.293z"
        fill="#263238"
      />
      <Path
        d="M123.538 140.085s4.254 2.592 4.077 3.723c-.177 1.132-1.051 1.91-1.157 2.632-.126.859-.444.834-1.328.717-.753-.101-2.526.702-3.284-.535-.758-1.238-.151-5.718-.151-5.718l1.843-.819zM122.275 137.267s-.121-4.976.925-5.441c1.045-.465 2.167-.151 2.834-.454.793-.359.944-.076 1.323.727.328.692 1.97 1.758 1.329 3.031-.642 1.273-4.723 3.228-4.723 3.228l-1.688-1.091z"
        fill="#9FBC2A"
      />
      <Path
        d="M121.749 140.929s-.313 4.971-1.394 5.339c-1.081.369-2.177-.035-2.869.208-.819.287-.94 0-1.253-.844-.263-.717-1.803-1.925-1.056-3.157.748-1.233 4.991-2.799 4.991-2.799l1.581 1.253z"
        fill="#9FBC2A"
      />
      <Path
        d="M120.2 139.832s-4.456 2.222-5.32 1.47c-.863-.753-1.055-1.9-1.611-2.374-.662-.566-.47-.824.101-1.516.505-.586.758-2.526 2.203-2.495 1.444.03 4.92 2.919 4.92 2.919l-.293 1.996zM123.821 138.109s4.455-2.217 5.319-1.469c.864.747 1.056 1.904 1.611 2.379.662.566.47.823-.101 1.515-.505.586-.758 2.526-2.202 2.496-1.445-.031-4.92-2.915-4.92-2.915l.293-2.006z"
        fill="#9FBC2A"
      />
      <Path
        d="M120.477 137.958s-4.011-2.945-3.738-4.041c.273-1.096 1.202-1.813 1.369-2.525.202-.844.505-.794 1.389-.602.742.167 2.591-.505 3.228.814.636 1.318-.339 5.713-.339 5.713l-1.909.641z"
        fill="#9FBC2A"
      />
      <Path
        d="M122.452 136.962a2.285 2.285 0 00-2.071 3.68 2.285 2.285 0 003.588-2.828 2.282 2.282 0 00-1.517-.852z"
        fill="#9FBC2A"
      />
      <Path
        d="M121.365 139.398c-.773.241-1.529.533-2.263.874-.769.254-1.521.556-2.253.904 0 .081 1.101-.121 2.364-.621a6.825 6.825 0 002.152-1.157zM118.366 136.087a13.039 13.039 0 003.132 2.425 6.616 6.616 0 00-1.47-1.334c-.869-.667-1.611-1.162-1.662-1.091zM122.648 132.373c-.086 0-.207 1.283-.358 2.869-.152 1.586-.263 2.874-.177 2.884.46-1.881.641-3.819.535-5.753zM126.6 136.804a8.853 8.853 0 00-1.748.843c-.94.505-1.718.879-1.682.96a4.968 4.968 0 001.823-.692 5.06 5.06 0 001.607-1.111zM122.982 139.685a9.225 9.225 0 001.783 1.516c.6.504 1.262.928 1.97 1.263a8.94 8.94 0 00-1.788-1.516 9.286 9.286 0 00-1.965-1.263zM122.053 139.875c-.086 0-.298.884-.475 2.02a7.144 7.144 0 00-.176 2.056c.085 0 .298-.889.474-2.021a7.319 7.319 0 00.177-2.055z"
        fill="#263238"
      />
      <Path
        d="M122.488 138.036a1.125 1.125 0 00-1.124.566 1.13 1.13 0 00.633 1.632 1.132 1.132 0 10.491-2.198z"
        fill="#fff"
      />
      <Path
        d="M184.691 122.697s2.869 1.753 2.753 2.526c-.116.772-.707 1.288-.778 1.773-.086.581-.303.565-.899.505-.505-.066-1.718.475-2.218-.364-.5-.838-.101-3.859-.101-3.859l1.243-.581zM183.852 120.799s-.086-3.359.621-3.672c.707-.314 1.465-.106 1.915-.309.535-.242.636-.05.894.506.217.464 1.328 1.182.894 2.056-.434.873-3.188 2.177-3.188 2.177l-1.136-.758z"
        fill="#9FBC2A"
      />
      <Path
        d="M183.499 123.27s-.212 3.354-.945 3.601c-.732.248-1.47 0-1.934.142-.551.192-.637 0-.844-.571-.177-.48-1.223-1.298-.712-2.132.51-.833 3.364-1.884 3.364-1.884l1.071.844z"
        fill="#9FBC2A"
      />
      <Path
        d="M182.453 122.522s-3.031 1.515-3.591 1.01c-.561-.505-.718-1.283-1.091-1.606-.374-.324-.314-.556.07-1.011.384-.454.506-1.707 1.486-1.687.979.021 3.318 1.97 3.318 1.97l-.192 1.324zM184.892 121.367s3.031-1.516 3.591-1.01c.561.505.713 1.283 1.086 1.606.374.323.319.556-.065 1.01-.384.455-.505 1.708-1.485 1.687-.98-.02-3.324-1.97-3.324-1.97l.197-1.323z"
        fill="#9FBC2A"
      />
      <Path
        d="M182.64 121.266s-2.712-1.985-2.525-2.738c.186-.753.813-1.223.924-1.702.136-.571.349-.536.934-.405.506.107 1.753-.328 2.178.546.424.874-.228 3.854-.228 3.854l-1.283.445z"
        fill="#9FBC2A"
      />
      <Path
        d="M183.968 120.592a1.54 1.54 0 101.344 1.717 1.515 1.515 0 00-1.344-1.717z"
        fill="#9FBC2A"
      />
      <Path
        d="M183.235 122.241c-.519.158-1.025.354-1.515.586-.519.17-1.025.374-1.516.611a4.54 4.54 0 001.596-.419 4.76 4.76 0 001.435-.778zM181.21 119.997c.291.349.631.653 1.01.904.33.303.703.554 1.107.748a4.31 4.31 0 00-1.011-.899 4.535 4.535 0 00-1.106-.753zM184.104 117.495a11.351 11.351 0 00-.242 1.935 11.61 11.61 0 00-.121 1.945 7.857 7.857 0 00.323-1.925 8.197 8.197 0 00.04-1.955zM186.767 120.486a5.694 5.694 0 00-1.177.566 5.37 5.37 0 00-1.137.652 3.485 3.485 0 001.233-.47 3.288 3.288 0 001.081-.748zM184.328 122.431c.359.385.765.725 1.207 1.01.402.34.847.625 1.324.849a12.362 12.362 0 00-2.526-1.864l-.005.005zM183.68 122.557a9.454 9.454 0 00-.434 2.743c.17-.434.277-.89.318-1.354.107-.455.146-.923.116-1.389z"
        fill="#263238"
      />
      <Path
        d="M183.993 121.313a.762.762 0 10.488 1.254.754.754 0 00.156-.702.761.761 0 00-.644-.552z"
        fill="#fff"
      />
      <Path
        d="M177.73 94.752s3.117-.464 3.491.157c.373.621.267 1.349.505 1.748.237.399.121.586-.354.889-.475.303-.95 1.374-1.818 1.07-.869-.302-2.39-2.727-2.39-2.727l.566-1.137zM175.971 93.895s-2.086-2.369-1.768-3.03c.318-.662 1.01-.955 1.197-1.374.238-.505.425-.42.94-.182.434.202 1.667.05 1.884.94.217.888-.985 3.49-.985 3.49l-1.268.156z"
        fill="#9FBC2A"
      />
      <Path
        d="M177.199 95.885s1.869 2.526 1.515 3.162c-.353.637-1.076.87-1.313 1.268-.237.399-.46.379-.95.096-.49-.283-1.656-.197-1.793-1.1-.136-.905 1.288-3.39 1.288-3.39l1.253-.036z"
        fill="#9FBC2A"
      />
      <Path
        d="M175.998 95.981s-1.263 2.89-1.985 2.875c-.722-.015-1.288-.505-1.753-.505-.551 0-.561-.207-.561-.773 0-.505-.661-1.516.056-2.107.717-.59 3.576-.586 3.576-.586l.667 1.096zM177.062 93.68s1.262-2.89 1.985-2.88c.722.01 1.288.505 1.753.505.55 0 .56.212.56.773 0 .56.662 1.54-.055 2.111-.718.571-3.577.582-3.577.582l-.666-1.092z"
        fill="#9FBC2A"
      />
      <Path
        d="M175.377 94.963s-3.147.197-3.466-.454c-.318-.652-.151-1.37-.358-1.784-.248-.505-.071-.59.429-.853s1.061-1.288 1.899-.915c.839.374 2.157 2.91 2.157 2.91l-.661 1.096z"
        fill="#9FBC2A"
      />
      <Path
        d="M175.932 93.678a1.44 1.44 0 00-.613 1.477 1.44 1.44 0 001.124 1.137 1.445 1.445 0 10-.511-2.614z"
        fill="#9FBC2A"
      />
      <Path
        d="M176.391 95.302c-.277.43-.525.88-.743 1.343-.273.434-.516.887-.727 1.354.372-.364.675-.791.894-1.263.279-.438.474-.925.576-1.434zM173.577 94.906c.414.082.838.099 1.257.05.423.015.845-.033 1.253-.14a4.097 4.097 0 00-1.258-.051 4.288 4.288 0 00-1.252.141zM174.159 91.364c.297.53.634 1.037 1.01 1.515.318.523.681 1.016 1.086 1.475a7.698 7.698 0 00-.93-1.581 7.088 7.088 0 00-1.166-1.41zM177.882 91.911c-.211.353-.381.73-.505 1.121a5.207 5.207 0 00-.429 1.152 5.12 5.12 0 00.934-2.273zM177.295 94.784c.986.068 1.976.007 2.945-.181a11.391 11.391 0 00-2.945.181zM176.911 95.256a9.246 9.246 0 001.339 2.243 4.512 4.512 0 00-.586-1.172 4.4 4.4 0 00-.753-1.07z"
        fill="#263238"
      />
      <Path d="M176.386 94.18a.718.718 0 10.77 1.21.718.718 0 00-.77-1.21z" fill="#fff" />
      <Path
        d="M124.976 177.251s3.082 1.879 2.956 2.698c-.127.818-.758 1.384-.839 1.909-.091.621-.323.601-.965.505-.545-.07-1.844.505-2.379-.389-.536-.894-.111-4.147-.111-4.147l1.338-.576zM124.073 175.194s-.091-3.607.667-3.946c.757-.338 1.576-.111 2.061-.328.571-.263.682-.056.954.53.238.506 1.43 1.273.965 2.213-.464.94-3.425 2.334-3.425 2.334l-1.222-.803z"
        fill="#9FBC2A"
      />
      <Path
        d="M123.679 177.861s-.222 3.602-1.01 3.87c-.788.267-1.576-.026-2.076.151-.596.207-.687 0-.91-.611-.192-.505-1.313-1.394-.767-2.288.545-.895 3.621-2.021 3.621-2.021l1.142.899z"
        fill="#9FBC2A"
      />
      <Path
        d="M122.558 177.056s-3.233 1.612-3.859 1.066c-.627-.545-.768-1.379-1.167-1.722-.48-.41-.338-.596.071-1.092.409-.495.55-1.833 1.596-1.813 1.046.02 3.571 2.117 3.571 2.117l-.212 1.444zM125.179 175.816s3.233-1.607 3.859-1.066c.627.54.768 1.379 1.172 1.727.405.349.339.596-.075 1.091-.415.495-.551 1.834-1.597 1.809-1.045-.025-3.571-2.112-3.571-2.112l.212-1.449z"
        fill="#9FBC2A"
      />
      <Path
        d="M122.759 175.698s-2.909-2.132-2.712-2.94c.197-.808.874-1.313 1.01-1.829.142-.616.374-.576 1.01-.439.541.121 1.879-.349 2.339.591.46.939-.247 4.142-.247 4.142l-1.4.475z"
        fill="#9FBC2A"
      />
      <Path
        d="M124.19 174.985a1.646 1.646 0 00-1.635.831 1.654 1.654 0 00.927 2.379 1.658 1.658 0 001.767-.494 1.658 1.658 0 00.045-2.097 1.642 1.642 0 00-1.104-.619z"
        fill="#9FBC2A"
      />
      <Path
        d="M123.402 176.751c-.561.174-1.11.387-1.642.637a12.37 12.37 0 00-1.631.656 7.603 7.603 0 003.273-1.293zM121.229 174.346c.303.376.663.703 1.066.97.36.321.765.589 1.202.793a4.516 4.516 0 00-1.066-.97 4.894 4.894 0 00-1.202-.793zM124.331 171.657a34.935 34.935 0 00-.389 4.172c.335-1.364.466-2.77.389-4.172zM127.195 174.866a20.79 20.79 0 00-2.485 1.308c.47-.08.92-.252 1.324-.505.437-.187.832-.46 1.161-.803zM124.579 176.962c.385.418.821.784 1.298 1.091.432.366.911.671 1.425.909a6.78 6.78 0 00-1.298-1.086 6.502 6.502 0 00-1.425-.914zM123.891 177.093c-.184.467-.3.959-.343 1.46a5.048 5.048 0 00-.126 1.515c.182-.466.298-.956.343-1.455.118-.497.161-1.01.126-1.52z"
        fill="#263238"
      />
      <Path
        d="M124.214 175.76a.822.822 0 00-.91.876.827.827 0 00.56.715.823.823 0 00.875-.243.814.814 0 00.023-1.039.827.827 0 00-.548-.309z"
        fill="#fff"
      />
      <Path d="M110.86 3.854H37.882v187.514h72.978V3.854z" fill="#CDE7DD" />
      <Path
        d="M34.255 0l.227.227.687.692c.606.642 1.515 1.597 2.748 2.895 0 12.346.05 48.493.09 91.107 0 26.621 0 50.721-.035 68.194 0 8.719-.035 15.776-.045 20.655 0 2.435 0 4.33-.025 5.618v-5.618c0-4.879-.026-11.936-.04-20.655 0-17.452-.026-41.553-.041-68.194.04-42.614.08-78.761.09-91.107v.03L35.195.924l-.667-.722C34.32.086 34.254 0 34.254 0zM69.811 113.828v-.505-1.49-5.743c0-5.006-.025-12.27-.04-21.353 0-18.144-.036-43.538-.056-72.538l.126.126H43.59l.131-.131c0 41.654-.04 78.65-.05 101.639l-.08-.08 19.31.045h6.911-6.865l-19.357.04h-.076v-.08c0-22.99-.03-59.986-.055-101.64v-.131h26.484v.131c-.025 29.036-.045 54.46-.06 72.63 0 9.092-.026 16.326-.036 21.321V113.263c0 .338-.035.565-.035.565zM101.237 113.828v-.505-1.49-5.743c0-5.006 0-12.27-.036-21.353 0-18.144-.035-43.538-.06-72.538l.131.126H75.015l.131-.131c0 41.654-.04 78.65-.055 101.639l-.076-.08 19.306.045h6.916-6.865l-19.357.041h-.08v-.081c0-22.99-.031-59.986-.052-101.64v-.131h26.46v.131c-.025 29.036-.046 54.46-.056 72.63 0 9.092-.03 16.326-.04 21.321V113.263c0 .338-.01.565-.01.565zM69.811 187.318v-.308-.894-3.46c0-3.031 0-7.411-.035-12.927 0-11.022-.035-26.55-.06-44.574l.126.127H43.59l.13-.132c0 24.247-.024 46.216-.035 62.163l-.096-.091 19.16.051 5.254.045H69.387h-1.349l-5.208.025-19.195.051h-.09v-.076c0-15.947-.026-37.885-.041-62.163v-.131h26.48v.162c-.026 18.059-.046 33.612-.061 44.659 0 5.506-.026 9.886-.036 12.897v4.313c0 .203-.076.263-.076.263zM101.236 187.318v-.308-.894-3.46c0-3.031 0-7.411-.036-12.927 0-11.022-.035-26.55-.055-44.574l.126.127H75.014l.131-.132c0 24.247-.03 46.216-.04 62.163l-.091-.091 19.155.051 5.228.025h1.384-1.349l-5.203.025-19.195.051h-.096v-.056c0-15.947 0-37.885-.035-62.163v-.131h26.439v.162c0 18.059-.041 33.612-.056 44.659 0 5.506-.03 9.886-.035 12.897v4.314c0 .202-.015.262-.015.262z"
        fill="#263238"
      />
      <Path opacity={0.3} d="M110.863 3.854h-4.324v187.514h4.324V3.854z" fill="#000" />
      <Path
        d="M110.86 3.855c0 .076-16.337.131-36.487.131-20.15 0-36.491-.055-36.491-.131 0-.076 16.336-.131 36.491-.131s36.487.06 36.487.13z"
        fill="#263238"
      />
      <Path
        d="M117.066 74.338s-3.93-4.274-5.148-5.456a7.692 7.692 0 01-1.071-1.323c-.555-.98-1.217-.339-1.151.505.122.45.313.88.565 1.273.321.454.545.97.657 1.515 0 0 .106.642-.687.349 0 0-1.419-.627-1.99-.809 0 0-2.526-1.419-2.9-1.222-.373.197-.368.41-.368.41a2.21 2.21 0 00-1.076-.253c-.374.096-.258.565-.258.565s-.894-.596-1.339-.12c-.444.474.273 1.115.273 1.115.313.223.627.455.935.688a.624.624 0 00-.243.405.63.63 0 00.116.458s2.198 1.783 3.087 2.38c1.692 1.141 5.637 2.47 5.637 2.47 1.107.56 1.93.964 1.93.964l3.031-3.914z"
        fill="#AE7461"
      />
      <Path
        d="M155.023 33.377a2.842 2.842 0 00-1.011-2.965 3.792 3.792 0 00-3.445-.54 5.511 5.511 0 00-4.379-1.748 5.332 5.332 0 00-4.092 2.223 7.575 7.575 0 00-5.991 1.631 5.751 5.751 0 00-2.01 5.274 4.64 4.64 0 00-3.486 3.334 4.162 4.162 0 001.854 4.238c-1.207 1.066-2.248 2.359-2.526 3.85a3.62 3.62 0 001.834 3.924 3.484 3.484 0 00-.914 3.753c.414 1.293 1.369 2.395 2.364 3.41a9.902 9.902 0 003.223 2.394 8.08 8.08 0 004.793.248 14.974 14.974 0 004.365-1.925c6.597-4.04 14.462-5.152 11.694-17.902l-2.273-9.199z"
        fill="#263238"
      />
      <Path
        d="M130.487 175.932l.909 9.426s-10.274 3.839-10.385 5.738l19.422.051v-1.42l-.818-4.849-1.197-9.416-7.931.47z"
        fill="#455A64"
      />
      <Path
        d="M137.24 183.896a.81.81 0 01.566.924.781.781 0 01-.914.566.853.853 0 01-.591-.985.804.804 0 011.01-.48"
        fill="#E0E0E0"
      />
      <Path
        d="M140.433 191.146v-1.419l-18.63.359s-.884.389-.783 1.01l19.413.05zM131.074 185.259c0 .101.505.141.965.454.46.313.732.717.823.677.091-.04-.055-.601-.641-.965-.586-.363-1.167-.257-1.147-.166zM128.993 186.124c-.025.096.394.258.727.667.334.409.425.844.505.839.081-.005.157-.551-.267-1.056-.425-.505-.965-.541-.965-.45zM127.81 188.624c.091 0 .222-.464 0-1.01-.223-.546-.707-.737-.748-.657-.04.081.243.364.44.804.197.439.212.853.308.863zM130.381 182.991c.04.086.505-.05 1.035 0 .531.051.98.207 1.011.122.03-.086-.364-.43-1.011-.46-.646-.03-1.081.263-1.035.338z"
        fill="#263238"
      />
      <Path
        d="M154.381 176.198l.833 9.264s-10.102 3.708-10.234 5.556l19.079.172v-1.384l-.767-4.768-1.127-9.26-7.784.42z"
        fill="#455A64"
      />
      <Path
        d="M160.948 184.063a.787.787 0 01.546.909.765.765 0 01-.899.551.837.837 0 01-.576-.965.793.793 0 011.01-.47"
        fill="#E0E0E0"
      />
      <Path
        d="M164.033 191.208v-1.399l-18.301.237s-.869.374-.773.985l19.074.177zM154.886 185.36c0 .096.48.142.95.45.47.308.717.712.803.672.086-.041-.05-.586-.626-.95s-1.162-.273-1.127-.172zM152.82 186.2c0 .096.394.258.712.662.319.404.42.833.506.828.085-.005.161-.54-.253-1.035-.414-.495-.96-.546-.965-.455zM151.649 188.65c.091 0 .222-.455 0-.98-.223-.525-.687-.733-.728-.652-.04.081.233.359.425.793.192.435.212.829.303.839zM154.21 183.134c.04.086.48-.045 1.01 0 .53.046.96.207 1.01.127.051-.081-.353-.425-1.01-.46-.657-.035-1.056.258-1.01.333zM130.068 93.967s-4.602 13.331-4.758 23.237c-.157 9.905-.319 25.686 1.742 43.088s3.031 24.388 3.031 24.388h10.477c.659-6.858.972-13.745.939-20.635-.156-9.754-.919-12.845 0-23.575.92-10.729 2.42-17.174 3.981-20.433 0 0 4.324-5.137 4.642-12.189a34.386 34.386 0 00-2.177-13.133l-17.877-.748z"
        fill="#263238"
      />
      <Path
        d="M139.377 112.672c.177.535 3.284 22.392 5.557 32.036 2.273 9.643 4.794 12.189 5.238 20.034.505 9.021 2.986 19.953 2.986 19.953h11.153s-1.566-16.67-3.137-29.708c-1.571-13.037-3.834-29.106-3.485-33.925.348-4.819 1.217-10.775 0-17.528-1.218-6.754-3.86-8.537-3.86-8.537l-9.466-.425-4.986 18.1z"
        fill="#263238"
      />
      <Path
        d="M149.879 100.169c.069.106.127.22.172.338.155.325.282.663.379 1.01.322 1.306.446 2.653.369 3.996a26.07 26.07 0 01-.915 5.88 19.269 19.269 0 01-1.217 3.314 23.677 23.677 0 01-2.021 3.197c-1.515 2.086-2.268 4.466-2.833 6.617a47.444 47.444 0 00-1.061 5.83c-.202 1.682-.328 3.031-.419 3.985-.046.455-.081.814-.112 1.086a2.755 2.755 0 01-.05.374 2.18 2.18 0 010-.379c0-.272.035-.631.066-1.091.065-.939.161-2.308.348-3.99a44.867 44.867 0 011.01-5.87 28.131 28.131 0 011.056-3.359c.226-.582.486-1.151.778-1.703.305-.562.643-1.107 1.01-1.631a24.263 24.263 0 002.021-3.167c.505-1.05.913-2.144 1.217-3.269a27.15 27.15 0 00.96-5.829 14.232 14.232 0 00-.293-3.965 8.133 8.133 0 00-.333-1.011 3.663 3.663 0 01-.132-.363z"
        fill="#455A64"
      />
      <Path
        d="M154.825 35.894l-7.406-3.268c-2.934-.743-5.445 2.652-6.061 5.617-.682 3.298-1.384 7.35-1.344 9.89a7.658 7.658 0 004.4 6.886l-.409 3.056c.156 2.389 2.647 2.672 2.647 2.672 6.834.813 7.663-3.006 7.663-3.006l1.798-18.948c.152-.909-.394-2.677-1.288-2.9z"
        fill="#AE7461"
      />
      <Path
        d="M145.471 46.347a4.962 4.962 0 00-1.091-.36c-.177-.045-.339-.1-.349-.226a.887.887 0 01.192-.506c.227-.399.46-.823.712-1.262a23.792 23.792 0 001.667-3.34c-.076-.04-.939 1.39-1.934 3.198l-.687 1.273a1.036 1.036 0 00-.192.677.446.446 0 00.252.298c.093.042.192.071.293.086.37.103.752.157 1.137.162z"
        fill="#263238"
      />
      <Path
        d="M144.415 55.02a16.58 16.58 0 006.354-1.435s-1.47 3.03-6.566 2.95l.212-1.515z"
        fill="#6F4439"
      />
      <Path
        d="M149.168 38.906c.04.197.737.207 1.515.419.778.212 1.415.505 1.546.373.061-.07-.03-.262-.268-.505a2.771 2.771 0 00-2.349-.6c-.282.095-.464.222-.444.313zM142.52 38.155c.101.171.596.075 1.172.151.575.076 1.035.253 1.171.106.061-.07 0-.237-.181-.42a1.653 1.653 0 00-.915-.388 1.622 1.622 0 00-.975.182c-.222.131-.313.283-.272.369z"
        fill="#263238"
      />
      <Path
        d="M138.872 40.436c.1-.792.467-1.527 1.041-2.081.35-.25.688-.518 1.01-.804.636-.687.657-1.727.985-2.601a3.83 3.83 0 012.596-2.324c.97-.242 2.021-.096 2.961-.475.687-.283 1.242-.838 1.944-1.08 1.364-.47 2.819.348 3.981 1.207.151 1.01-.556 1.793-1.233 2.525a3.526 3.526 0 01-2.712 1.157c-.788-.03-1.687-.298-2.294.207-.606.505-.505 1.591-1.05 2.218-.778.899-2.289.379-3.365.879-.833.384-1.252 1.293-1.727 2.07-.475.779-1.243 1.572-2.152 1.44a10.572 10.572 0 01.015-2.338z"
        fill="#263238"
      />
      <Path
        d="M151.269 33.873a17.81 17.81 0 016.597 1.864 3.243 3.243 0 011.546 1.303c.226.563.287 1.178.177 1.773-.202 1.874-1.182 4.102-2.066 5.769l-2.481 1.172c-1.768-.601-.636-3.637-.394-5.193a3.702 3.702 0 01-1.838-3.47 4.097 4.097 0 01-1.541-3.218zM148.172 43.7a.664.664 0 00.541.753.634.634 0 00.667-.27.63.63 0 00.096-.236.664.664 0 00-.541-.752.634.634 0 00-.668.27.646.646 0 00-.095.235zM142.085 43.017a.659.659 0 00.54.752.64.64 0 00.763-.505.66.66 0 00-.54-.753.641.641 0 00-.668.27.628.628 0 00-.095.236zM142.368 40.462c.055.1.591-.192 1.232-.056.642.136 1.011.606 1.122.505.111-.1 0-.212-.162-.424a1.514 1.514 0 00-.874-.535 1.47 1.47 0 00-1.01.156c-.232.177-.333.303-.308.354zM148.51 41.59c.08.11.677-.269 1.459-.183.783.086 1.304.556 1.4.465.096-.09-.026-.232-.253-.455a1.899 1.899 0 00-1.111-.505 1.809 1.809 0 00-1.167.273c-.263.182-.364.359-.328.404z"
        fill="#263238"
      />
      <Path
        d="M148.385 48.13c-.04-.45-.085-.946-.54-.956a3.202 3.202 0 00-1.601.692c-.182.278.161.506.232.819v.07a1.109 1.109 0 001.202.42 1.009 1.009 0 00.707-1.046z"
        fill="#6F4439"
      />
      <Path
        d="M149.446 57.351l-3.788-.303s-9.664 7.694-11.644 17.498c-1.98 9.805-4.243 19.595-4.243 19.595l17.63 2.172c1.835.227 3.692.202 5.521-.076l1.838-.278-1.515-6.223s2.793-11.775 3.885-16.215a19.84 19.84 0 00.07-9.567 8.705 8.705 0 00-7.754-6.603z"
        fill="#9FBC2A"
      />
      <Path
        d="M137.712 76.472l-7.148 14.003c1.854-.066 3.172-.834 4.43-2.192a20.135 20.135 0 002.97-4.678c2.127-4.177 4.173-8.532 4.597-13.204a51.442 51.442 0 00-4.849 6.072z"
        fill="#000"
        opacity={0.3}
      />
      <Path
        d="M156.138 61.434s9.729 20.286 8.996 23.054c-.828 3.122-3.601 4.279-5.501 3.703-1.899-.576-6.678-3.935-6.678-3.935l3.183-22.822zM116.496 72.99c.106.152 10.411 6.062 10.411 6.062s4.824-9.178 6.37-12.386c1.546-3.207 3.425-7.693 5.87-9.077 2.444-1.384 6.511-.54 6.511-.54L144.011 69l-3.996 8.613s-4.748 9.486-7.349 11.694c-2.375 2.02-6.062 1.207-7.739.222-1.677-.985-12.684-10.466-12.684-10.466l4.253-6.072z"
        fill="#9FBC2A"
      />
      <Path
        d="M144.01 69.139a1.133 1.133 0 01-.101.283c-.091.207-.202.475-.344.803-.298.687-.748 1.682-1.313 2.904a199.978 199.978 0 01-4.718 9.416 36.836 36.836 0 01-2.986 4.981 10.168 10.168 0 01-1.778 1.824 6.086 6.086 0 01-1.975 1.01 6.674 6.674 0 01-3.182.167 6.426 6.426 0 01-.844-.228 1.102 1.102 0 01-.278-.121s.399.131 1.137.253a6.766 6.766 0 003.117-.233 5.936 5.936 0 001.899-1.01 10.346 10.346 0 001.733-1.798 38.877 38.877 0 002.945-4.966 273.664 273.664 0 004.773-9.37 592.278 592.278 0 011.389-2.874l.379-.784c.041-.09.09-.176.147-.257zM157.41 69.412c.008.23-.009.46-.05.687-.056.434-.147 1.07-.268 1.848a175.011 175.011 0 01-1.056 6.062c-.449 2.344-.899 4.511-1.268 6.062a35.94 35.94 0 01-.459 1.818 3.768 3.768 0 01-.207.657c.021-.228.06-.455.116-.677l.389-1.834c.318-1.545.742-3.687 1.192-6.061.45-2.374.828-4.47 1.126-6.062.137-.743.253-1.364.344-1.839.031-.223.079-.444.141-.661zM146.651 49.139c.25.083.516.111.778.08a1.014 1.014 0 00.661-.429 1.054 1.054 0 00.132-.934 3.345 3.345 0 00-.228-.506c-.04-.06-.06-.07-.111-.07a.695.695 0 00-.212.055l-.848.298c-.257.114-.53.184-.809.207.228-.162.478-.292.743-.384l.828-.348a.813.813 0 01.293-.08.39.39 0 01.334.18c.102.175.188.357.257.546a1.24 1.24 0 01-.182 1.162 1.143 1.143 0 01-.833.475c-.566.056-.834-.242-.803-.252zM153.369 30.909a3.459 3.459 0 013.612-1.233 4.041 4.041 0 012.652 3.066 3.657 3.657 0 013.283 1.4 4.931 4.931 0 01.925 3.859 3.702 3.702 0 013.839 1.323 5.084 5.084 0 01.823 4.486 4.743 4.743 0 011.97 4.112 4.584 4.584 0 01-2.339 3.854 5.362 5.362 0 01.425 5.082 4.123 4.123 0 01-3.895 2.495 3.436 3.436 0 01-.187 3.309 5.948 5.948 0 01-2.41 2.116c-2.278 1.238-4.899 1.778-7.354 1.117a8.198 8.198 0 01-5.557-5.34 5.936 5.936 0 01-.061-3.753c.435-1.182 1.486-2.106 2.602-2.02a8.724 8.724 0 01.662-3.97 3.74 3.74 0 012.914-1.986c-.975-2.571-.202-4.193 1.309-6.4a9.172 9.172 0 01-4.521-5.279c-.718-2.394-.051-5.405 1.869-6.663"
        fill="#263238"
      />
      <Path
        d="M154.511 45.286c.172-1.066 1.515-1.146 2.384-.505.475.354.798 1.01.622 2.127-.47 3.03-3.41 1.93-3.4 1.839.01-.091.187-2.208.394-3.46z"
        fill="#AE7461"
      />
      <Path
        d="M155.219 47.746s.046.045.132.1c.116.071.255.096.389.072a1.332 1.332 0 00.788-1.076 1.808 1.808 0 00-.031-.778.627.627 0 00-.333-.47.279.279 0 00-.333.096c-.056.08-.046.146-.061.146s-.056-.06 0-.181a.393.393 0 01.151-.172.411.411 0 01.304-.03.748.748 0 01.474.556c.092.28.109.579.051.868a1.383 1.383 0 01-.97 1.187.56.56 0 01-.47-.157c-.096-.085-.101-.156-.091-.161z"
        fill="#6F4439"
      />
      <Path
        d="M134.896 61.275a1.038 1.038 0 01-.162-.116c-.106-.081-.258-.197-.449-.359a17.58 17.58 0 01-1.587-1.515 9.57 9.57 0 01-1.879-2.834 4.601 4.601 0 01-.283-2.091c.028-.393.152-.773.359-1.107.231-.343.541-.625.904-.823v.197a4.613 4.613 0 01-1.515-1.516 3.652 3.652 0 01-.419-2.268 6.285 6.285 0 01.793-2.293 4.448 4.448 0 011.758-1.823v.227a4.86 4.86 0 01-.768-.627 3.998 3.998 0 01-1.076-4.005 4.668 4.668 0 011.066-1.753c.447-.487.986-.88 1.586-1.157.151-.044.299-.097.444-.157l.212-.1h.041v-.092c.058-.29.087-.587.086-.884.013-.59.135-1.171.358-1.717a6.316 6.316 0 014.001-3.844 6.366 6.366 0 011.915-.323 4.725 4.725 0 011.636.212 8.977 8.977 0 00-1.631-.111 6.505 6.505 0 00-4.042 1.631 6.425 6.425 0 00-1.682 2.526 4.742 4.742 0 00-.333 1.647c.007.308-.018.616-.076.92 0 .034 0 .065-.03.13a.205.205 0 01-.086.106l-.066.03-.202.102c-.151.07-.303.116-.444.172a4.734 4.734 0 00-1.516 1.1 4.552 4.552 0 00-1.01 1.658 3.774 3.774 0 001.01 3.748c.217.211.454.401.708.566l.186.126-.202.1a4.32 4.32 0 00-1.651 1.718 6.168 6.168 0 00-.768 2.198c-.105.73.029 1.474.384 2.121a4.427 4.427 0 001.439 1.46l.157.101-.162.096c-.334.176-.62.431-.833.743-.195.303-.311.65-.339 1.01-.07.684.015 1.374.248 2.02a9.665 9.665 0 001.808 2.81c.477.541.983 1.057 1.516 1.545l.424.389c.126.05.177.1.172.106z"
        fill="#fff"
      />
      <Path
        d="M150.339 29.833a6.61 6.61 0 00-1.824-1.222 5.472 5.472 0 00-3.688-.223c-.476.146-.929.36-1.343.637a3.41 3.41 0 00-1.086 1.217c-.24.433-.303.941-.177 1.42l.04.136h-.141a2.965 2.965 0 00-1.516.662 3.27 3.27 0 00-.833 1.01 5.05 5.05 0 00-.359 1.132.945.945 0 010-.319c.029-.302.107-.597.233-.873.196-.43.482-.813.838-1.122a3.094 3.094 0 011.642-.742l-.101.151a2.144 2.144 0 01.177-1.606c.274-.527.673-.98 1.162-1.319a5.254 5.254 0 011.419-.666c.471-.129.956-.2 1.445-.213a5.674 5.674 0 012.394.505c.505.229.967.543 1.364.93.144.149.263.32.354.505zM136.101 61.425c-.083.25-.186.494-.308.727l-.935 1.945a527.763 527.763 0 01-3.167 6.365 724.318 724.318 0 01-3.233 6.334c-.409.773-.748 1.415-1.01 1.905a4.842 4.842 0 01-.399.681 4.69 4.69 0 01.308-.727l.934-1.945a544.737 544.737 0 013.167-6.364 724.107 724.107 0 013.233-6.335l1.011-1.904c.115-.238.248-.466.399-.682zM126.407 78.99a41.002 41.002 0 01-4.122-2.112 40.151 40.151 0 01-3.991-2.349 40.184 40.184 0 014.117 2.117 41.91 41.91 0 013.996 2.344z"
        fill="#fff"
      />
    </Svg>
  );
};

export default AgeLocationIneligibleIllustration;
