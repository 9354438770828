/* eslint-disable import/prefer-default-export */
import { useMemo, useState } from 'react';
import { useOnSubmit } from './useOnSubmit';
import {
  UseDiscoveryServiceOptions,
  UseDiscoveryServiceAPI,
  UseDiscoveryServiceState,
} from './types';
import { useHomePageState } from '@/Components/HomePage';

export const useDiscoveryService = ({
  maxAttempts = 1,
  discoveryConfiguration,
  previousAttempts,
}: UseDiscoveryServiceOptions): UseDiscoveryServiceAPI => {
  const { flowConfig, insurancePayer, service } = useHomePageState();

  if (!flowConfig) {
    throw new Error('flowConfig is required');
  }

  if (!insurancePayer) {
    throw new Error('insurancePayer is required');
  }

  if (!service) {
    throw new Error('service is required');
  }

  const [state, setState] = useState<UseDiscoveryServiceState>({
    result: null,
    error: null,
    attempts: previousAttempts || 0,
    isSubmitting: false,
    errorType: null,
    canRetry: true,
    done: false,
  });

  const submit = useOnSubmit({
    state,
    setState,
    service,
    insurancePayer,
    maxAttempts,
    discoveryConfiguration,
  });

  return useMemo(() => {
    return {
      result: state.result,
      error: state.error,
      errorType: state.errorType,
      attempts: state.attempts,
      canRetry: state.canRetry,
      isSubmitting: state.isSubmitting,
      done: state.done,
      submit,
    };
  }, [
    state.result,
    state.error,
    state.attempts,
    state.canRetry,
    state.isSubmitting,
    state.errorType,
    state.done,
    submit,
  ]);
};
