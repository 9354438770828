export interface TrackerScope {
  userID: number | string | null;
  calledSetUserID: boolean;
}

export type TsAnalyticsProviderTrackEvent = <EventName, Attributes>(
  eventName: EventName,
  attributes?: Attributes,
  messageBody?: Record<string, any>
) => Promise<unknown>;

export type Target = 'mixpanel' | 'mixpanelProvider' | 'braze';

export interface Tracker {
  scope: TrackerScope;
  init: (...args: any[]) => void;
  setUserID: (userID: number | string) => void;
  trackEvent: (...args: any[]) => Promise<any>;
  completedSetup: () => boolean;
  trackMPEventGeneric: (...args: any[]) => Promise<any>;
  targetsEnum: Record<string, Target>;
}

export const fromPageToUpdateCoverageSource = {
  home: 'Support team card',
  'check-coverage': 'EAP prompt',
  'account-dropdown': 'Account drop down',
} as const;

export type fromPageSource = keyof typeof fromPageToUpdateCoverageSource;

export type UpdateCoverageSource = typeof fromPageToUpdateCoverageSource[fromPageSource];

export type Platforms = 'web' | 'iOS' | 'Android';

export type PageName =
  | 'nav'
  | 'home'
  | 'schedule'
  | 'clinical-progress'
  | 'clinical-progress-select'
  | 'account'
  | 'in-room-scheduling'
  | 'messages'
  | 'post-lvs-checkin-booking-first'
  | 'switch-wizard'
  | 'switch-wizard-no-available-providers'
  | 'meet-recommended-match'
  | 'available-providers'
  | 'provider-profile'
  | 'therapist-scheduler'
  | 'chat-history-sharing-preferences'
  | 'switch-wizard-switch-reason'
  | 'cant-find-insurance'
  | 'edit-insurance-info'
  | 'couldnt-verify-coverage'
  | 'check-your-coverage';

export type BHEligibilityFormFieldName =
  | 'Insurance'
  | 'First Name'
  | 'Last Name'
  | 'Date of Birth'
  | 'Location'
  | 'MemberID';

export type BHEligibilityButtonText =
  | 'Edit Info'
  | 'Continue with this information'
  | 'Continue'
  | 'Continue without insurance'
  | 'See if I’m covered by an employer'
  | 'I’m sure my plan covers Talkspace';

export type BHEligibilityErrorReasons =
  | 'DOB is in the future'
  | 'Age is under 13'
  | 'Age is over 120';

export type BHInsuranceEligibilityEvents =
  | "Can't find insurance Seen"
  | 'Edit Insurance Info Seen'
  | "Couldn't verify coverage Seen"
  | 'Check your coverage Seen'
  | 'Error Seen';
