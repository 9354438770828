import { Path, Svg } from 'svgs';
import { useEmotionTheme } from '../..';

const QuestionMagnifyingGlass = ({ width = 48, height = 48 }) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'Magnifying Glass with Question Mark';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText}>
      <Path
        d="M33.552 11.838a.637.637 0 00.834-.336l1.862-4.375a.637.637 0 00-1.17-.5l-1.862 4.377a.636.636 0 00.336.835zM36.204 13.667a15.364 15.364 0 003.394-1.864.636.636 0 10-.748-1.028 14.091 14.091 0 01-3.112 1.708.636.636 0 00.466 1.184zM36.19 15.667a.636.636 0 00.535.724l2.876.432a.636.636 0 00.724-.534.642.642 0 00-.534-.724l-2.877-.432a.636.636 0 00-.723.534z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.422 38.077c-2.366-3.005-3.635-5.072-6.089-8.192.499-1.31.243-2.702-.954-3.393 5.44-8.23-.364-18.926-9.408-19.565l-1.578-.026c-2.012.067-3.736.55-5.397 1.384-1.84.971-3 2.086-4.3 3.595-.01-.001-.01-.001-.013.018-.476.543-.987 1.223-1.545 2.229l-.633 1.29c-.902 2.107-1.166 4.23-.855 6.487.194 1.3.344 1.999.815 3.102 1.203 3.03 3.444 5.407 5.451 6.468 3.667 2.14 8.407 2.294 12.233.482.274.323.608.576.96.692 8.277 10.117 7.167 9.21 8.698 9.412.7.092 1.918-.28 2.35-.599.944-.697.943-2.526.265-3.384zm-1.19 2.132c.15-.158.16-.924-.033-1.169-2.3-2.923-3.21-4.526-5.758-7.769-.417.45-.841.802-1.297 1.05 2.5 3.188 3.527 5.058 5.85 8.007.169.136.947.095 1.238-.119zm-8.202-9.09c.532-.189.996-.547 1.502-1.157.601-.726.745-1.974-.104-2.224a12.72 12.72 0 01-3.347 2.777c.281.854 1.157.886 1.949.605zm-2.85-1.88c5.524-3.047 7.512-10.214 3.945-15.75-4.471-6.873-14.375-6.57-18.521-.134l-.095.141c-4.008 5.895-.928 14.385 5.57 16.471v.002c2.912 1.036 6.206.877 9.101-.73z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M22.782 25.668c.299-.361.377-.866.199-1.286-.31-.735-1.297-1.054-1.941-.545a1.466 1.466 0 00-.508.818c-.349 1.465 1.42 2.014 2.25 1.013zM19.008 15.966c.433.113.887-.118 1.012-.526.132-.429 1.145-1.399 1.825-1.399.854.002 1.664.399 2.064 1.011.394.606.34 1.456-.123 1.935-.413.428-1.19.697-1.833 1.253-.945.819-1.362 2.106-1.063 3.28.104.405.536.668.986.569.44-.1.71-.514.605-.927-.161-.631.072-1.35.58-1.79.203-.176.464-.33.742-.49.388-.227.829-.484 1.194-.862.956-.99 1.085-2.576.306-3.77-1.18-1.811-3.566-1.773-3.457-1.746-1.5 0-3.094 1.522-3.397 2.51-.125.408.125.835.56.952z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
    </Svg>
  );
};

export default QuestionMagnifyingGlass;
