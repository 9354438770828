import {
  View,
  GeolocationFailure,
  ExtraHuge,
  Large,
  Button,
  useUniqueID,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { LocationEligibilityErrorStep, UpdateStep } from '@/Flows/types';
import { trackEvent } from '@/utils/analytics/events';

interface LocationEligibilityErrorProps {
  step: LocationEligibilityErrorStep;
  updateStep: UpdateStep;
}

const LocationEligibilityError = ({ step, updateStep }: LocationEligibilityErrorProps) => {
  const { isSmall } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const handleSubmit = () => {
    updateStep(step.internalTarget, {
      payfirst_step_id: step.analyticsId,
      payfirst_step_prompt: step.prompt,
      response_category_id: step.response_category_id,
      response_prompt: 'Continue',
    });
    trackEvent('Continue to Access Code Verification', {
      'Funnel Name': 'QuickMatch',
    });
  };

  const subheadID = useUniqueID('headerID');

  return (
    <View style={{ display: 'flex' }}>
      <View
        align="center"
        style={{ minWidth: isSmall ? undefined : 375, maxWidth: 440, alignSelf: 'center' }}
      >
        <GeolocationFailure width={100} />
        <ExtraHuge as="h1" style={{ marginTop: 20 }}>
          Something went wrong
        </ExtraHuge>

        <Large as="h2" style={{ margin: 20 }} id={subheadID}>
          We weren't able to confirm your eligibility based on location, but you can still access
          the service by using a one-time access code.
        </Large>

        <Button
          style={{ marginTop: 15, width: 320 }}
          onPress={handleSubmit}
          aria-describedby={subheadID}
          roundedFocusStyle
          primaryColor={colors.green}
        >
          Continue
        </Button>
      </View>
    </View>
  );
};

export default LocationEligibilityError;
