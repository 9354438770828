import { Svg, Path } from 'svgs';

const CheckmarkCircle = ({ size }: { size: 32 | 60 }) => {
  const title = 'green circular checkmark';
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 33 32"
      fill="none"
      title={title}
      aria-label={title}
    >
      <Path
        d="M16.5 32C20.7435 32 24.8131 30.3143 27.8137 27.3137C30.8143 24.3131 32.5 20.2435 32.5 16C32.5 11.7565 30.8143 7.68687 27.8137 4.68629C24.8131 1.68571 20.7435 0 16.5 0C12.2565 0 8.18687 1.68571 5.18629 4.68629C2.18571 7.68687 0.5 11.7565 0.5 16C0.5 20.2435 2.18571 24.3131 5.18629 27.3137C8.18687 30.3143 12.2565 32 16.5 32ZM23.5625 13.0625L15.5625 21.0625C14.975 21.65 14.025 21.65 13.4438 21.0625L9.44375 17.0625C8.85625 16.475 8.85625 15.525 9.44375 14.9438C10.0312 14.3625 10.9812 14.3563 11.5625 14.9438L14.5 17.8813L21.4375 10.9375C22.025 10.35 22.975 10.35 23.5562 10.9375C24.1375 11.525 24.1437 12.475 23.5562 13.0562L23.5625 13.0625Z"
        fill="#9FBC2A"
      />
    </Svg>
  );
};

export default CheckmarkCircle;
