/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import { InsuranceEligibilityPayer } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { AxiosError } from 'axios';
import { QuickEligibilityStep, QuickEligibilityEnterMemberIDStep, UpdateStep } from '@/Flows';
import {
  EligibilityResponse,
  EligibilityServiceFormData,
  UseEligibilityServiceErrorType,
} from '@/hooks';

type UseContinueParams = {
  result: EligibilityResponse | null;
  data: EligibilityServiceFormData;
  insurancePayer: InsuranceEligibilityPayer | null | undefined;
  updateStep: UpdateStep;
  step: QuickEligibilityStep | QuickEligibilityEnterMemberIDStep;
  done: boolean;
  attempts: number;
  errorType: UseEligibilityServiceErrorType | null;
  error?: Error | AxiosError | null;
};

export const useContinue = ({
  result,
  data,
  insurancePayer,
  updateStep,
  step,
  done,
  attempts,
  errorType,
  error,
}: UseContinueParams) => {
  const processedRef = useRef({
    hasProcessed: false,
  });
  const { bhInsuranceEligibilityError } = useFlags();

  useEffect(() => {
    if (processedRef.current.hasProcessed) {
      return undefined;
    }
    if (!done) {
      return undefined;
    }
    processedRef.current.hasProcessed = true;
    if (!errorType && result?.isEligible) {
      const insuranceEligibility = {
        ...result,
        verificationSucceeded: true,
      };
      const newState = {
        quickEligibilityInfo: {
          ...data,
          insurancePayer: data.insurance || insurancePayer || undefined,
          insuranceEligibility,
          eligibilityCheckAttempts: attempts,
          error,
        },
        insuranceEligibility,
        insurancePayer: data.insurance || insurancePayer || undefined,
      };
      updateStep(step.inNetworkTarget, newState);
      return undefined;
    }
    const insuranceEligibility = {
      isEligible: false,
      verificationSucceeded: false,
      isVideoOnlyPlan: false,
    };
    const newState = {
      quickEligibilityInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        memberID: data.memberID,
        payerID: insurancePayer?.id || 0,
        state: data.state,
        country: data.country,
        insurancePayer: data.insurance || insurancePayer || undefined,
        insuranceEligibility,
        eligibilityCheckAttempts: attempts,
        error,
      },
      insuranceEligibility,
      insurancePayer: data.insurance || insurancePayer || undefined,
    };
    switch (errorType) {
      case 'TIMEOUT':
        updateStep(step.timeoutTarget, newState);
        break;
      case 'VERIFICATION_FAILURE':
      default:
        // for non-timeout errors, continue to no insurance screen
        updateStep(
          bhInsuranceEligibilityError ? step.inNetworkDoubleCheckTarget : step.inNetworkTarget,
          newState
        );
    }
    return undefined;
  }, [
    result,
    data,
    updateStep,
    step,
    done,
    attempts,
    errorType,
    insurancePayer,
    bhInsuranceEligibilityError,
    error,
  ]);
};
