import { useCallback } from 'react';
import {
  View,
  Button,
  Card,
  TextDS,
  BaseButton,
  StickyDrawer,
  StickyDrawerParentContainer,
  styled,
} from '@talkspace/react-toolkit';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from '@talkspace/i18n';
import { useRecoveredSessionActions } from '../../hooks/recoveredSessionContext';
import AgeLocationIneligibleIllustration from '../assets/AgeLocationIneligibleIllustration';
import sessionStorage from '../../core/storage/sessionStorage';
import { UpdateStep, AgeLocationIneligibleStep } from '../../Flows';
import switchFlowHelper from '../../Helpers/switchFlowHelper';

type AgeLocationIneligibleProps = {
  step: AgeLocationIneligibleStep;
  updateStep: UpdateStep;
};

const Styled = {
  Text: styled(View)(({ theme: { spacing } }) => {
    return {
      paddingLeft: spacing('space200'),
      width: '343px',
    };
  }),
  Edit: styled(View)(({ theme: { spacing } }) => {
    return {
      padding: `10px ${spacing('space100')}px`,
    };
  }),
  Illustration: styled(View)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space400'),
      marginBottom: spacing('space400'),
    };
  }),
  PrimaryButton: styled(Button)(() => {
    return {
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }),
  SecondaryButton: styled(BaseButton)(({ theme: { spacing } }) => {
    return {
      padding: '18px 18px',
      marginTop: spacing('space050'),
    };
  }),
  Card: styled(Card)(({ theme: { spacing } }) => {
    return {
      marginBottom: spacing('space400'),
    };
  }),
};

const AgeLocationIneligible = ({ step, updateStep }: AgeLocationIneligibleProps) => {
  const { updateAndSave } = useRecoveredSessionActions();
  const history = useHistory();
  const location = useLocation();
  const { t: quickmatchFlow147t } = useTranslation('quickmatch.flow147');

  const onPrimaryButtonClick = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('clientAge');
    history.replace({ search: searchParams.toString() });
    switchFlowHelper(140, history, updateAndSave, { stepId: 15 });
  }, [history, updateAndSave, location.search]);

  const onSecondaryButtonClick = useCallback(() => {
    updateStep(1, {
      insuranceConfirmation: 'orgEap',
      b2bForkResult: 'b2b',
      isB2BOutOfNetwork: undefined,
      isCouldNotBeVerified: true,
      insurancePayer: undefined,
      insuranceEligibility: undefined,
      partnerFlowID: undefined,
      organizationFlowID: undefined,
    });
    switchFlowHelper(132, history, updateAndSave, { stepId: 1 });
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('clientAge');
    searchParams.delete('clientState');
    history.replace({ search: searchParams.toString() });
  }, [updateStep, history, updateAndSave, location.search]);

  const { age, zipCode } = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');

  return (
    <View align="center">
      <StickyDrawerParentContainer style={{ width: '375px' }}>
        <View>
          <Styled.Card padding="slim">
            <View row>
              <Styled.Text flex={1} align="start">
                <TextDS variant="bodySm">
                  {quickmatchFlow147t('step192.buttonOneText', 'Age', undefined)}: {age}
                </TextDS>
                <TextDS variant="bodySm">
                  {quickmatchFlow147t('step192.buttonTwoText', 'Zip code', undefined)}: {zipCode}
                </TextDS>
              </Styled.Text>
              <Styled.Edit align="end">
                <BaseButton dataQa="editButton" onPress={() => history.goBack()}>
                  <TextDS dataQa="editButtonText" colorRole="textBrandDefault" variant="headingMd">
                    {quickmatchFlow147t('step192.ButtonThreeText', 'Edit', undefined)}
                  </TextDS>
                </BaseButton>
              </Styled.Edit>
            </View>
          </Styled.Card>
          <Styled.Illustration align="center">
            <AgeLocationIneligibleIllustration />
          </Styled.Illustration>
        </View>
        <StickyDrawer noBorder>
          <View align="center">
            <Styled.PrimaryButton
              dataQa="checkInsuranceButton"
              text={step.buttons[0].text}
              onPress={onPrimaryButtonClick}
            />
            <Styled.SecondaryButton
              dataQa="checkEmployerCoverageButton"
              onPress={onSecondaryButtonClick}
            >
              <TextDS variant="headingMd" colorRole="textBrandDefault">
                {step.buttons[1].text}
              </TextDS>
            </Styled.SecondaryButton>
          </View>
        </StickyDrawer>
      </StickyDrawerParentContainer>
    </View>
  );
};

export default AgeLocationIneligible;
