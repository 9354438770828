/* eslint-disable import/prefer-default-export */
import { HomePageState, ReturningUserData, useHomePageState } from '@/Components/HomePage';

const isReturningUserDataExists = (
  state: HomePageState
): state is HomePageState & {
  returningUserData: ReturningUserData;
} => state.returningUserData !== undefined;

export const useReturningUserData = (): ReturningUserData => {
  const state = useHomePageState();

  if (!isReturningUserDataExists(state)) {
    throw new Error('returningUserData is required for this operation.');
  }

  return state.returningUserData;
};
