import { isPhoneNumberValid, parseFullPhoneNumber, PhoneNumber } from '@talkspace/react-toolkit';
import sessionStorage from '@/core/storage/sessionStorage';
import countriesHelper from '@/Helpers/countriesHelper';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

export const getPhoneNumberFromSessionStorage = ():
  | { country: string; nationalNumber: string }
  | PhoneNumber => {
  let phoneNumberWithCountryCode = '';
  let parsedPhoneNumber = { country: '', nationalNumber: '' };
  const basicInformationData = sessionStorage.getItem('TSQM_BasicInformation');
  const parsedBasicInformationData = basicInformationData && JSON.parse(basicInformationData);
  if (parsedBasicInformationData && parsedBasicInformationData.phone)
    phoneNumberWithCountryCode = parsedBasicInformationData.phone;
  if (
    isPhoneNumberValid(phoneNumberWithCountryCode) &&
    parseFullPhoneNumber(phoneNumberWithCountryCode)
  ) {
    parsedPhoneNumber = parseFullPhoneNumber(phoneNumberWithCountryCode);
  }
  return parsedPhoneNumber;
};

export const getEmailFromSessionStorage = () => {
  const memberDetailsSessionData = sessionStorage.getItem('TSQM_MemberDetails');
  const parsedMemberDetailsSessionData =
    memberDetailsSessionData && JSON.parse(memberDetailsSessionData);
  const email = (parsedMemberDetailsSessionData && parsedMemberDetailsSessionData.email) || null;
  return email;
};

export const getMemberDetailsFromSessionStorage = () => {
  const memberDetailsSessionData = sessionStorage.getItem('TSQM_MemberDetails');
  const parsedMemberDetailsSessionData =
    memberDetailsSessionData && JSON.parse(memberDetailsSessionData);
  return parsedMemberDetailsSessionData;
};

export const getMarketingConsentFromSessionStorage = () => {
  const { consent } = JSON.parse(sessionStorage.getItem('TSQM_RegistrationInformation') || '{}');
  return consent;
};

export const getRegistrationInformationFromStorage = () =>
  JSON.parse(sessionStorage.getItem('TSQM_RegistrationInformation') || '{}');

export const getCustomerInformationFromSessionStorage = (): {
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  state: string;
  unitAddress: string;
  zipCode: string;
} => {
  const basicInformation = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
  const { firstName, lastName, phone, address, city, country, state, unitAddress, zipCode, age } =
    basicInformation;

  const { state: actualState, country: actualCountry } =
    countriesHelper.getStateAndCountryOverrides(country, state);
  const customerInformation = {
    firstName,
    lastName,
    phone,
    address,
    city,
    country: actualCountry,
    state: actualState,
    unitAddress,
    zipCode,
    age,
  };
  return customerInformation;
};

export const setUpdateCoverageComplete = () => {
  ReactFrameService.instance().updateCoverageComplete();
};
