import { View, styled } from '@talkspace/react-toolkit';

import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import PartnerLogo from './PartnerLogo';
import CheckmarkCircle from './assets/CheckmarkCircle';

interface Props {
  partnerID: number;
}

const Styled = {
  Container: styled(View)(({ theme: { colorRoles } }) => {
    return {
      position: 'relative',
      width: 280,
      height: 112,
      borderRadius: 16,
      border: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
    };
  }),
  FloatingCheckmark: styled(View)(() => {
    return {
      position: 'absolute',
      top: -20,
      left: 'auto',
      right: 'auto',
    };
  }),
  InnerContainer: styled(View)(({ theme: { colorRoles } }) => {
    return {
      width: 264,
      height: 96,
      borderRadius: 10,
      background: colorRoles.surfaces.surfaceNeutralDefault,
    };
  }),
};

const PartnerBadge = ({ partnerID }: Props) => (
  <Styled.Container align="center" justify="center" style={{}}>
    <Styled.InnerContainer align="center" justify="center" style={{}}>
      <PartnerLogo size="standard" partnerID={partnerID} />
    </Styled.InnerContainer>
    <Styled.FloatingCheckmark>
      <CircleUnderlay customSize={40} colorRole="defaultSubtle">
        <CheckmarkCircle size={32} />
      </CircleUnderlay>
    </Styled.FloatingCheckmark>
  </Styled.Container>
);

export default PartnerBadge;
