/* eslint-disable no-console */
import {
  PostMessageFuncType,
  PromiseMessageEvent,
  PromiseMessageTypeNames,
  PROMISE_MESSAGE_TYPE,
  postPromiseMessage,
  receiveMessagePromiseHandler,
} from 'ts-promise-message';
import { PromiseMessageTypes } from '@/utils/promiseMessage/promiseMessageEvents';
import promiseMessageHandlers from '@/utils/promiseMessage/promiseMessageHandlers';
import { typeNames, ReactFrameListener, ClosePopupAction } from './ReactFrameTypes';
import getParamByName from '../queryString';
import parseJwt from '../jwt';
import { isiOS, isAndroid, isMobile, getParentOrigin, isIonicParent } from './helpers';

const DEBUG_LOG = false;

export type Listener = (token: string, userID: number) => void;

let instance: ReactFrameService;

type AuthMethod = 'jwt' | 'frame' | null;

type PromiseMessageListener =
  | ((
      postMessage: PostMessageFuncType<typeof PROMISE_MESSAGE_TYPE>,
      event: PromiseMessageEvent
    ) => Promise<void>)
  | undefined;

export default class ReactFrameService {
  currentToken = '';

  currentUserID = '';

  tokenExpiry?: number;

  parentOrigin = '';

  tokenListeners: ReactFrameListener[] = [];

  refreshedTokenListeners: Function[] = [];

  initialized = false;

  authMethod: AuthMethod = null;

  promiseMessageListener: PromiseMessageListener = undefined;

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    this.initService();
  }

  static instance(): ReactFrameService {
    return new ReactFrameService();
  }

  public isUsingJWT = () => this.initialized && this.authMethod === 'jwt';

  public isInFrame = () => this.initialized && this.authMethod === 'frame';

  public isMobileFrame = () => this.isInFrame() && (isMobile() || isIonicParent());

  public setPromiseMessageListener = (listener: PromiseMessageListener) => {
    this.promiseMessageListener = listener;
  };

  static isiOS = isiOS;

  static isAndroid = isAndroid;

  static isMobile = isMobile;

  static isIonicParent = isIonicParent;

  private static isIFrame = () => window.location !== window.parent.location;

  private handleToken = (refreshData) => {
    if (this.currentToken !== refreshData.token) {
      this.currentToken = refreshData.token;
      this.currentUserID = (refreshData.userID && String(refreshData.userID)) || '';
      this.notifyTokenListeners();
    }
  };

  private receiveMessage = (event: Pick<MessageEvent, 'origin' | 'data'>) => {
    // check origin
    if (this.parentOrigin !== event.origin) {
      return;
    }
    if (event.data && event.data.type === 'refreshToken') {
      this.handleToken(event.data.data);
    }
    if (event.data && event.data.type === PROMISE_MESSAGE_TYPE) {
      if (this.promiseMessageListener) {
        this.promiseMessageListener(this.postMessage, event);
      } else {
        receiveMessagePromiseHandler(this.postMessage, event, promiseMessageHandlers);
      }
    }
  };

  private listenToFrameEvents = () => {
    if (ReactFrameService.isMobile()) {
      window.postClientMessage = (data) => this.receiveMessage({ data, origin: 'mobileClient' });
    } else {
      window.addEventListener('message', this.receiveMessage, false);
    }
  };

  private initService = () => {
    // window.webkit only gets injected form ios apps

    const parentOrigin = getParentOrigin();
    if (parentOrigin && (ReactFrameService.isMobile() || ReactFrameService.isIFrame())) {
      this.parentOrigin = parentOrigin;
      this.listenToFrameEvents();

      // The page is in an iframe
      if (DEBUG_LOG) console.log('The page is in an iframe', this.parentOrigin);

      this.finishLoading('frame');
    } else {
      // try to get a token from querystring
      const jwtToken = getParamByName('token');
      if (jwtToken) {
        let tokenData;
        try {
          tokenData = parseJwt(jwtToken);
        } catch (error) {
          if (DEBUG_LOG) console.error(error);
          return;
        }

        if (tokenData.userID) {
          if (DEBUG_LOG) console.info('received token data', tokenData);
          this.tokenExpiry = tokenData.exp * 1000;
          this.currentToken = jwtToken;
          this.currentUserID = String(tokenData.userID);
          this.notifyTokenListeners();
          this.finishLoading('jwt');
        }
      }
    }
  };

  isJWTTokenExpired = () => this.tokenExpiry && Date.now() - this.tokenExpiry > 0;

  private postMessage = (type: typeNames, data: unknown) => {
    if (!this.parentOrigin) return;
    if (ReactFrameService.isiOS(type)) {
      window.webkit.messageHandlers[type].postMessage(data, this.parentOrigin);
    } else if (ReactFrameService.isAndroid()) {
      window.toAndroidDevice.postMessage(
        JSON.stringify({
          type,
          data,
        }),
        this.parentOrigin
      );
    } else {
      window.parent.postMessage(
        {
          type,
          data,
        },
        this.parentOrigin
      );
    }
  };

  public sendAsyncMessage = <K extends PromiseMessageTypeNames>(
    name: K,
    data: PromiseMessageTypes[K]['postData'],
    createPromise?: boolean
  ) => postPromiseMessage(this.postMessage, name, data, createPromise);

  public closePopup = (data?: ClosePopupAction) => {
    this.postMessage('closePopup', { return: { ...data } });
  };

  private finishLoading = (authMethod: AuthMethod) => {
    // Add this on session storage
    // this.addTokenListeners(setTokenAndUserID);
    this.postMessage('finishLoading', { return: { ready: true } });
    this.initialized = true;
    this.authMethod = authMethod;
  };

  public refreshTokenEvent = (cb: Function) => {
    if (DEBUG_LOG) console.info('refreshTokenEvent called');

    if (typeof cb !== 'function') return;
    this.refreshedTokenListeners.push(cb);
    if (this.refreshedTokenListeners.length === 1)
      this.postMessage('authToken', { return: { expired: true } });
  };

  private notifyTokenListeners = (): void => {
    this.tokenListeners.forEach((fn) => fn(this.currentToken, this.currentUserID));

    this.refreshedTokenListeners.forEach((fn) => fn(true));
    this.refreshedTokenListeners = [];

    if (DEBUG_LOG) console.log('token listeners notified', this.currentToken, this.currentUserID);
  };

  public hideFrameHeader = (): void => {
    this.postMessage('frameHeaderChange', { return: { hide: true } });
  };

  public showFrameHeader = (): void => {
    this.postMessage('frameHeaderChange', { return: { hide: false } });
  };

  public updateCoverageComplete = (): void => {
    this.postMessage('updateCoverageComplete', {});
  };

  /**
   * Register callback to socket server state connected or not,
   * the callback will be called immediately with current state
   * @param  {Function} fn callbacks with a boolean parameter
   * @returns void
   */
  public addTokenListeners = (fn: ReactFrameListener): void => {
    if (typeof fn !== 'function') return;
    this.tokenListeners.push(fn);
    if (this.currentUserID) fn(this.currentToken, this.currentUserID);
  };
}
