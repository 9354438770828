import { Switch, Route, Redirect } from 'react-router-dom';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { AllLaunchDarklyExperiments, PayerOptionType } from 'ts-frontend/types';
import HomePageSessionWrapper from './Components/HomePageSessionWrapper';
import AccountActivationPage from './Components/Users/AccountActivation/AccountActivationPage';
import NotFoundPage from './Components/NotFoundPage/NotFoundPage';
import 'url-search-params-polyfill';
import {
  DEFAULT_FLOWS,
  FLOW_90_SELECT_SERVICE,
  FLOW_141_NEW_USER_HOLD,
  FLOW_142_NEW_USER_HOLD_EXPIRED,
  FLOW_155_THERAPY_GENERAL_SHORT,
  getClientFlowConfig,
} from './Flows';
import useScrollIntoInputs from './hooks/useScrollIntoInputs';
import appConfigs from './utils/configs';
import { trackEvent } from '@/utils/analytics/eventTracker';
import useQueryInsurancePayers from './hooks/useQueryInsurancePayers';
import usePageLoadExperimentRedirect from './hooks/usePageLoadExperimentRedirect';

const PRE_REGISTER_TOKEN_KEY = 'preRegisterToken';

function removeTokenFromQuery(search: string, keyName: string) {
  const searchParams = new URLSearchParams(search);
  searchParams.delete(keyName);
  const serialized = searchParams.toString();
  return serialized ? `?${serialized}` : '';
}

function rootRoute(
  unifiedFlowForDirectB2B: AllLaunchDarklyExperiments['unifiedFlowForDirectB2B'],
  unifiedFlowV2: AllLaunchDarklyExperiments['unifiedFlowV2'],
  insurancePayers?: PayerOptionType[]
) {
  return (
    <Route
      exact
      path="/"
      render={(props) => {
        const search = new URLSearchParams(props.location.search);

        function flowStartRedirect(flowId: string | null, otherParams: string) {
          return <Redirect to={`/flow/${flowId}${otherParams}`} />;
        }

        function transformSearchParams() {
          const flowId = search.get('flowId');
          // remove the flowId search param from the URL, because it will be sent as a route param
          search.delete('flowId');
          search.delete('override');
          const otherParams = search.toString() ? `?${search.toString()}` : '';
          return { flowId, otherParams };
        }

        function experimentalRedirect(flowId: string | null, otherParams: string) {
          const {
            experimentActive: isActiveV1,
            experimentName: experimentNameV1,
            variant: variantV1,
            interceptedFlowIDs: interceptedFlowIDsV1,
          } = unifiedFlowForDirectB2B;
          const {
            experimentActive: isActiveV2,
            experimentName: experimentNameV2,
            variant: variantV2,
            interceptedFlowIDs: interceptedFlowIDsV2,
          } = unifiedFlowV2;

          const newParams = new URLSearchParams(otherParams);
          const config = getClientFlowConfig(Number(flowId));
          const serviceKeyword = config?.serviceKeywords?.psychotherapy;
          const insurancePayer = insurancePayers?.find((i) => i.keyword === serviceKeyword) || '';

          if (isActiveV1) {
            const inInterceptedFlow = interceptedFlowIDsV1.includes(Number(flowId));

            if (inInterceptedFlow && config && insurancePayer && serviceKeyword) {
              trackEvent('TS Experiment Session', {
                experimentName: experimentNameV1,
                variantName: variantV1,
              });

              newParams.set('insurancePayer', encodeURIComponent(JSON.stringify(insurancePayer)));

              const serviceType = search.get('serviceType');
              if (serviceType) {
                newParams.set('skipServiceSelection', 'true');
              }

              if (variantV1 === 'treatment') {
                return <Redirect to={`/flow/${DEFAULT_FLOWS.CONSUMER}/step/1/?${newParams}`} />;
              }
            }
          }

          if (isActiveV2) {
            const inInterceptedFlow = interceptedFlowIDsV2.includes(Number(flowId));

            if (inInterceptedFlow && config && insurancePayer && serviceKeyword) {
              trackEvent('TS Experiment Session', {
                experimentName: experimentNameV2,
                variantName: variantV2,
              });

              newParams.set('insurancePayer', encodeURIComponent(JSON.stringify(insurancePayer)));

              if (variantV2 === 'treatment') {
                return (
                  <Redirect to={`/flow/${FLOW_155_THERAPY_GENERAL_SHORT}/step/1/?${newParams}`} />
                );
              }
            }
          }

          return flowStartRedirect(flowId, otherParams);
        }

        function isLegacyRoute() {
          return !!search.get('flowId');
        }

        function legacyRedirect() {
          const { experimentActive: isActiveV1 } = unifiedFlowForDirectB2B;
          const { experimentActive: isActiveV2 } = unifiedFlowV2;
          const { flowId, otherParams } = transformSearchParams();

          return isActiveV1 || isActiveV2
            ? experimentalRedirect(flowId, otherParams)
            : flowStartRedirect(flowId, otherParams);
        }

        function hasB2BParam() {
          return !!search.get('qmPartnerCode');
        }

        function b2bRedirect() {
          return <Redirect to={`/flow/${DEFAULT_FLOWS.B2B}/step/1${props.location.search}`} />;
        }

        function hasPreRegisteredToken() {
          return !!search.get(PRE_REGISTER_TOKEN_KEY);
        }

        function newPreRegisteredUserRedirect() {
          const queryString = removeTokenFromQuery(props.location.search, PRE_REGISTER_TOKEN_KEY);
          const {
            preRegisterDataExpired,
            email,
            referralSource,
            partnerID,
            isDirectoryIntegration,
          } = getSessionStorageValuesFromJson(SESSION_STORAGE_MEMBER_DETAILS_KEY, [
            'preRegisterDataExpired',
            'email',
            'referralSource',
            'partnerID',
            'isDirectoryIntegration',
          ]);
          if (isDirectoryIntegration) {
            if (preRegisterDataExpired) {
              return (
                <Redirect to={`/flow/${FLOW_142_NEW_USER_HOLD_EXPIRED}/step/1${queryString}`} />
              );
            }
            if (!email) {
              window.location.href = `${appConfigs.endpoints.clientWebEndpoint}/login`;
            }
            return <Redirect to={`/flow/${FLOW_141_NEW_USER_HOLD}/step/1${queryString}`} />;
          }
          if (referralSource?.value === 'manualSignUp') {
            const newParams = new URLSearchParams(queryString || undefined);
            const insurancePayer = insurancePayers?.find((i) => i.partnerID === partnerID) || '';
            newParams.set('insurancePayer', encodeURIComponent(JSON.stringify(insurancePayer)));

            const serviceType = search.get('serviceType');
            if (serviceType) {
              newParams.set('skipServiceSelection', 'true');
            }

            return <Redirect to={`/flow/${DEFAULT_FLOWS.CONSUMER}/step/1/?${newParams}`} />;
          }
          return <Redirect to={`/flow/${FLOW_90_SELECT_SERVICE}/step/1${props.location.search}`} />;
        }

        function hasCopayParam() {
          return !!search.get('cpPartnerCode');
        }

        function copayRedirect() {
          return <Redirect to={`/flow/${DEFAULT_FLOWS.COPAY}/step/1${props.location.search}`} />;
        }

        function defaultFlowRedirect() {
          return <Redirect to={`/flow/${DEFAULT_FLOWS.CONSUMER}/step/1${props.location.search}`} />;
        }

        function handleSearchParamRedirects() {
          switch (true) {
            case isLegacyRoute():
              return legacyRedirect();
            case hasB2BParam():
              return b2bRedirect();
            case hasPreRegisteredToken():
              return newPreRegisteredUserRedirect();
            case hasCopayParam():
              return copayRedirect();
            default:
              return defaultFlowRedirect();
          }
        }

        return handleSearchParamRedirects();
      }}
    />
  );
}

function flowStartRoute() {
  return (
    <Route
      exact
      path="/flow/:flowId"
      render={({
        match: {
          params: { flowId },
        },
        location: { search },
      }) => <Redirect to={`/flow/${flowId}/step/1${search}`} />}
    />
  );
}

function flowStepRoute() {
  return (
    <Route
      path="/flow/:flowId/step/:stepId"
      render={(props) => {
        const search = new URLSearchParams(props.location.search);

        if (search.get(PRE_REGISTER_TOKEN_KEY)) {
          const currentPath = props.location.pathname;
          const queryString = removeTokenFromQuery(props.location.search, PRE_REGISTER_TOKEN_KEY);
          return <Redirect to={`${currentPath}/${queryString}`} />;
        }
        return <HomePageSessionWrapper {...props} />;
      }}
    />
  );
}

function accountActivationRoute() {
  return <Route path="/users/account-activation/:userID" component={AccountActivationPage} />;
}

function dispatcherRoute() {
  return (
    <Route
      exact
      path={['/dispatcher', '/dispatcher/*', '/mk/*']}
      render={({ location: { search } }) => (
        <Redirect
          to={{
            pathname: `/flow/${FLOW_90_SELECT_SERVICE}`,
            search,
          }}
        />
      )}
    />
  );
}

function sessionRestoreRoute() {
  return (
    <Route
      exact
      path={['/recover-session']}
      render={({ location: { search } }) => (
        <Redirect
          to={{
            pathname: `/`,
            search,
          }}
        />
      )}
    />
  );
}

function notFoundRoute() {
  return <Route component={NotFoundPage} />;
}

export default function Routes() {
  useScrollIntoInputs();
  const { unifiedFlowForDirectB2B, unifiedFlowV2 } = useFlags();
  const { data: insurancePayers, isLoading: loadingInsurance } =
    useQueryInsurancePayers('psychotherapy');

  const { isLoadingRedirect } = usePageLoadExperimentRedirect();

  if (loadingInsurance || isLoadingRedirect) return <></>;
  return (
    <Switch>
      {rootRoute(unifiedFlowForDirectB2B, unifiedFlowV2, insurancePayers)}
      {flowStartRoute()}
      {flowStepRoute()}
      {accountActivationRoute()}
      {dispatcherRoute()}
      {sessionRestoreRoute()}
      {notFoundRoute()}
    </Switch>
  );
}
