import { useEffect, useState, VoidFunctionComponent, useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { CreateAccountForm, SignUpData, View, Spinner } from '@talkspace/react-toolkit';
import { getGDPRProps } from 'ts-frontend/helpers/gdpr';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { trackRegistrationErrorEvent } from 'ts-analytics/mixpanel/events';
import { trackEvent } from '@/utils/analytics/events';
import { useRecoveredSessionState } from '@/hooks/recoveredSessionContext';
import Page from '../../Page/Page';
import { RouteComponentProps } from '../../../core/routerLib';
import userQueryPublicAdminConfig from '../../../hooks/useQueryPublicAdminConfig';
import SignupFooter from '../../SignupFooter/SignupFooter';
import { onSubmit, useValuesFromAPIResponse } from './activationHelpers';
import {
  getMarketingConsentFromSessionStorage,
  getEmailFromSessionStorage,
  getPhoneNumberFromSessionStorage,
} from '../../../utils/registrationHelpers';
import { useIonicFrameKeyboardListener } from '../../../hooks';

interface AccountActivationPageProps {
  userID: string;
}

const AccountActivationPage: VoidFunctionComponent<
  RouteComponentProps<AccountActivationPageProps>
> = ({ match: { params }, location }) => {
  const userID = Number(params.userID);
  const [isLoadingRegisteredUserInfo, setIsLoadingRegisteredUserInfo] = useState(false);
  const [hasCountryAndStateInfo, setHasCountryAndStateInfo] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<string>('');
  const [consent, setConsent] = useState<boolean>(false);
  const recoveredSessionState = useRecoveredSessionState();

  const { t: tAccountActivation } = useTranslation('quickmatch.common');
  const { emailVerificationUiCopyChanges, emailVerificationOtp } = useFlags();

  const { isGDPR, consentGDPRMarketing, consentGDPRProcessing, consentGDPRTransferring } =
    getGDPRProps();

  const ldClient = useLDClient();
  const sessionParams = useMemo(() => {
    const { country: sessionCountryCode, nationalNumber: sessionPhoneNumber } =
      getPhoneNumberFromSessionStorage();
    const sessionEmail =
      getEmailFromSessionStorage() ||
      recoveredSessionState?.dispatcherState?.organizationEmail ||
      '';

    const sessionConsent = getMarketingConsentFromSessionStorage();
    return {
      phoneNumberCountryCode: sessionCountryCode,
      phoneNumber: sessionPhoneNumber,
      email: sessionEmail,
      consent: sessionConsent,
    };
  }, [recoveredSessionState?.dispatcherState?.organizationEmail]);

  useEffect(() => {
    trackEvent('TS Experiment Session', {
      experimentName: 'email-verification-ui-copy-changes',
      variantName: emailVerificationUiCopyChanges ? 'treatment' : 'control',
    });

    if (emailVerificationOtp && emailVerificationOtp !== 'disabled') {
      trackEvent('TS Experiment Session', {
        experimentName: 'email-verification-OTP',
        variantName: emailVerificationOtp,
      });
    }
  }, [emailVerificationUiCopyChanges, emailVerificationOtp]);

  useEffect(() => {
    async function mergeMixPanelIDWithUserIDForLD() {
      if (!ldClient) return;
      const prevUser = ldClient.getContext();
      const user = { ...prevUser, key: `${userID}` };
      await ldClient.identify(user);
    }
    mergeMixPanelIDWithUserIDForLD();
  }, [ldClient, userID]);

  const { hash } = location;
  const urlHashParams = new URLSearchParams(hash.replace('#', '?'));
  const updateCredentialsJWTToken = urlHashParams.get('token');
  const cognitoToken = urlHashParams.get('secondaryToken');
  const phoneParam = urlHashParams.get('phone')
    ? decodeURIComponent(urlHashParams.get('phone') || '')
    : null;
  const consentParam = urlHashParams.get('smsConsent');
  const collectReferralSourceOnSignUp = urlHashParams.get('collectReferralSourceOnSignUp');
  const { isLoading: isLoadingCognito, data: cognitoActive } =
    userQueryPublicAdminConfig('cognito_user_migration');
  const hideFooter = useIonicFrameKeyboardListener();

  const submitHandler = (formState: SignUpData, password: string) => {
    onSubmit(
      formState,
      password,
      userID,
      setRequestPending,
      setErrorMessage,
      setIsError,
      cognitoToken,
      updateCredentialsJWTToken,
      cognitoActive
    );
  };

  useValuesFromAPIResponse({
    userID,
    phoneParam,
    sessionConsent: sessionParams.consent,
    consentParam,
    setIsLoadingRegisteredUserInfo,
    setEmail,
    setPhoneNumber,
    setPhoneNumberCountryCode,
    setHasCountryAndStateInfo,
    setConsent,
  });
  return isLoadingCognito || isLoadingRegisteredUserInfo ? (
    <View style={{ height: '100vh' }}>
      <View flex={1}>
        <Spinner isLoading />
      </View>
    </View>
  ) : (
    <Page isLoggedIn={false} noFooter>
      <View style={{ alignItems: 'center', marginTop: 80 }}>
        <CreateAccountForm
          clientEmail={email}
          phoneNumber={phoneNumber}
          phoneNumberCountryCode={phoneNumberCountryCode}
          headerCopy={
            emailVerificationUiCopyChanges
              ? tAccountActivation('headerText2', 'Create your account', undefined)
              : tAccountActivation('headerText1', 'Complete your account', undefined)
          }
          bodyCopy={
            emailVerificationUiCopyChanges
              ? tAccountActivation(
                  'body2Text',
                  "We’re excited that you’ve decided to move forward with Talkspace. Now let's create your account.",
                  undefined
                )
              : tAccountActivation(
                  'body1Text',
                  'We’re excited you’ve decided to join us. Let’s complete your account.',
                  undefined
                )
          }
          minimumAge={13}
          isUpdating={requestPending}
          onSubmit={submitHandler}
          isError={isError}
          errorMessage={errorMessage}
          showPhoneNumberInput
          showEmailInput
          loginFooter={() => <SignupFooter />}
          showCountryStateDropdown={!hasCountryAndStateInfo}
          showReferralField={collectReferralSourceOnSignUp === 'true'}
          consent={consent}
          hideFooter={hideFooter}
          isGDPR={isGDPR}
          consentGDPRMarketing={consentGDPRMarketing}
          consentGDPRProcessing={consentGDPRProcessing}
          consentGDPRTransferring={consentGDPRTransferring}
          trackEvent={trackRegistrationErrorEvent}
        />
      </View>
    </Page>
  );
};

export default AccountActivationPage;
