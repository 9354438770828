/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useSetCustomAttributes } from 'launchDarkly/hooks';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { EligibilityRequest, submitEligibilityRequest } from './EligibilityRequest';
import { UseOnSubmitOptions } from './types';
import { getInsuranceVerificationFlowID } from '@/Helpers/insurancePayersHelper';

export const useOnSubmit = ({
  state,
  setState,
  service,
  insurancePayer,
  maxAttempts,
  setIsPayerOutage,
  isDiscoveryScreen,
}: UseOnSubmitOptions) => {
  const { authCodeExpirationField, registrationAckp0Copay: registrationAckp0CopayActive } =
    useFlags();

  const setCustomAttribute = useSetCustomAttributes();

  return useCallback(
    async (data) => {
      if (state.result || state.isSubmitting || state.attempts >= maxAttempts) {
        return;
      }

      let finalInsurancePayer = insurancePayer;
      if (state.attempts > 0) {
        finalInsurancePayer = data.insurance;
      }

      setState((_state) => {
        return {
          ..._state,
          attempts: _state.attempts + 1,
          isSubmitting: true,
          formattedError: null,
          error: null,
          errorType: null,
        };
      });

      const { flowID: overrideFlowID } = await getInsuranceVerificationFlowID(
        finalInsurancePayer,
        service
      );

      if (!overrideFlowID) {
        return;
      }

      try {
        const apiResult = await submitEligibilityRequest({
          memberID: data.memberID,
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: moment(data.dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          state: data.state,
          flowId: overrideFlowID,
        });

        // This event is specific to the coverage verification experiment. Be sure to change
        // if you're using this hook somewhere else.
        trackEvent('Submit Coverage Information', {
          insuranceProvider: insurancePayer?.label || '',
          outcome: apiResult.isEligible ? 'Eligible' : 'Not Eligible',
          isAuthCodeExpirationFieldEnabled: authCodeExpirationField,
          registrationAckp0CopayFF: registrationAckp0CopayActive,
          copayAmount: (apiResult.copayCents || 0) / 100,
          isDiscoveryScreen,
        });

        await setCustomAttribute({ accountType: 'bh', state: data.state });

        setState((_state) => {
          return {
            ..._state,
            result: apiResult,
            canRetry: false,
            isSubmitting: false,
            error: null,
            formattedError: null,
            errorType: null,
            done: true,
          };
        });
      } catch (err) {
        if (err?.response?.data?.isPayerOutage) {
          if (setIsPayerOutage) {
            setIsPayerOutage(true);
          }
        }

        const isNonTimeoutError = err?.response?.status >= 400;
        const done =
          // +1 because we updated state.attempts earlier in the function
          state.attempts + 1 >= maxAttempts ||
          // Disable retry for non-timeout errors
          isNonTimeoutError;
        // Only send the event once, at the end of the retries
        if (done) {
          // This event is specific to the coverage verification experiment. Be sure to change
          // if you're using this hook somewhere else.
          if (isNonTimeoutError) {
            trackEvent('Submit Coverage Information', {
              insuranceProvider: insurancePayer?.label || '',
              outcome: 'Verification Failed',
              isAuthCodeExpirationFieldEnabled: authCodeExpirationField,
              registrationAckp0CopayFF: registrationAckp0CopayActive,
              isDiscoveryScreen,
            });
          } else {
            trackEvent('Submit Coverage Information', {
              insuranceProvider: insurancePayer?.label || '',
              outcome: 'Eligible with manual service keyword',
              isAuthCodeExpirationFieldEnabled: authCodeExpirationField,
              registrationAckp0CopayFF: registrationAckp0CopayActive,
              isDiscoveryScreen,
            });
          }
        }

        const errorType = EligibilityRequest.getErrorType(err);

        setState((_state) => {
          return {
            ..._state,
            canRetry: _state.attempts < maxAttempts,
            done,
            result: null,
            isSubmitting: false,
            error: err,
            errorType,
          };
        });
      }
    },
    [
      state.result,
      state.isSubmitting,
      state.attempts,
      maxAttempts,
      setState,
      insurancePayer,
      service,
      authCodeExpirationField,
      registrationAckp0CopayActive,
      isDiscoveryScreen,
      setCustomAttribute,
      setIsPayerOutage,
    ]
  );
};
