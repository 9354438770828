import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from '@talkspace/i18n';
import {
  BannerCard,
  EmotionThemeProvider,
  TextDS,
  Tooltip,
  View,
  useEmotionTheme,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';
import Tag from '@talkspace/react-toolkit/src/designSystems/components/Tag';
import { FullWidthContainer } from 'stepWizard/components/StepWizard/StepWizardStyles';
import { QuestionMagnifyingGlass as QuestionMagnifyingGlassIllustration } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import moment from 'moment';
import { CircleInfo } from '@talkspace/react-toolkit/src/designSystems/icons';
import { AxiosError } from 'axios';
import { DoubleCheckInsuranceInfoProps } from '../QuickEligibility/types';
import convertUSStateAbbreviationToName from '../../../Helpers/stateCodeToNameHelper';
import { QuickEligibilityInfo } from '../../HomePage/types';
import { trackBHEligibilityRejectionReason } from '@/utils/analytics/events';
import bhInsuranceEligibilityErrorParser from '../../../Helpers/bhEligibilityErrorHelper';

const { space0, space075, space150, space200, space400 } = spacing;

const InsuranceInfoRow: FunctionComponent<{
  insuranceInfoCategory: string | string[];
  insuranceInfoResponse: string;
  isLastRow?: boolean;
  hasTooltip?: boolean;
  isInsuranceInNetworkRow?: boolean;
}> = ({
  insuranceInfoCategory,
  insuranceInfoResponse,
  isLastRow,
  hasTooltip,
  isInsuranceInNetworkRow,
}) => {
  const { colorRoles } = useEmotionTheme();
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  return (
    <View
      row
      justify="space-between"
      style={{
        padding: `${space200}px ${space0}px`,
        borderBottom: isLastRow ? 'none' : `1px solid ${colorRoles.borders.borderDefault}`,
        alignItems: insuranceInfoCategory !== 'Insurance' ? 'center' : undefined, // only the Insurance row has a different vertical alignment
      }}
    >
      <View row>
        {Array.isArray(insuranceInfoCategory) ? (
          <View style={{ display: 'block', textAlign: 'left' }}>
            <TextDS variant="body">{insuranceInfoCategory[0]}</TextDS>
            <TextDS variant="body">{insuranceInfoCategory[1]}</TextDS>
          </View>
        ) : (
          <TextDS variant="body">{insuranceInfoCategory}</TextDS>
        )}
        {hasTooltip && (
          <Tooltip
            tip={tQuickmatchFlow132(
              'step108.toolTipText',
              `Type the full ID, including letters and numbers. Your Member ID is usually printed on the front of your health insurance card. Look for a number that’s labeled "Member ID" or "Subscriber ID." You can also find your Member ID by logging into your health insurance provider’s website or mobile app. Once logged in, go to your profile or coverage details.`,
              undefined
            )}
            questionMarkSize={16}
            buttonStyle={{ display: 'flex', marginLeft: 6, marginTop: 'auto' }}
          />
        )}
      </View>
      <TextDS variant="headingMd" style={{ textAlign: 'end' }}>
        {insuranceInfoResponse}
        {insuranceInfoResponse.length > 16 && <div style={{ marginBottom: space075 }} />}
        {isInsuranceInNetworkRow && (
          <Tag
            color="successDefault"
            text={tQuickmatchFlow132('step108.badgeText', 'In network', undefined)}
            style={{ height: '16px', marginLeft: space075 }}
            inline
            icon
          />
        )}
      </TextDS>
    </View>
  );
};

const InsuranceInfoSummary: FunctionComponent<{
  insuranceInfoCategoryInsuranceCompanyName: string;
  insuranceInfoCategoryMemberName: string;
  insuranceInfoCategoryMemberDOB: string;
  insuranceInfoCategoryMemberState: string;
  insuranceInfoCategoryMemberSubscriberID: string;
  isInsuranceInNetwork: boolean;
}> = ({
  insuranceInfoCategoryInsuranceCompanyName,
  insuranceInfoCategoryMemberName,
  insuranceInfoCategoryMemberDOB,
  insuranceInfoCategoryMemberState,
  insuranceInfoCategoryMemberSubscriberID,
  isInsuranceInNetwork,
}) => {
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  const { isMobile } = useWindowWidthState();

  return (
    <FullWidthContainer
      align="center"
      justify="center"
      tabIndex={-1}
      style={{ marginTop: -space200, maxWidth: '343px' }}
    >
      <View
        align="center"
        rowGap={space150}
        style={{
          marginBottom: space400,
        }}
      >
        <QuestionMagnifyingGlassIllustration />
        <TextDS variant="headingXl">
          {tQuickmatchFlow132(
            'step108.titleText',
            `We're having trouble finding your insurance`,
            undefined
          )}
        </TextDS>
        <TextDS variant="body">
          {tQuickmatchFlow132(
            'step108.subtitleText',
            `Please double-check that the information matches what’s listed on your insurance card`,
            undefined
          )}
          <View />
        </TextDS>
      </View>
      <View>
        <BannerCard
          title=""
          subtitle={tQuickmatchFlow132(
            'step108.errorBanner',
            'Name, date of birth, or member/subscriber ID is invalid',
            undefined
          )}
          icon={<CircleInfo colorType="warning" />}
          variant="warning"
          isMobile={isMobile}
          style={{ textAlign: 'left', paddingBottom: 0 }}
        />
        <InsuranceInfoRow
          insuranceInfoCategory={tQuickmatchFlow132(
            'step108.insuranceInfoCategoryInsuranceCompanyName',
            'Insurance',
            undefined
          )}
          insuranceInfoResponse={insuranceInfoCategoryInsuranceCompanyName}
          isInsuranceInNetworkRow={isInsuranceInNetwork}
        />
        <InsuranceInfoRow
          insuranceInfoCategory={tQuickmatchFlow132(
            'step108.insuranceInfoCategoryMemberName',
            'Name',
            undefined
          )}
          insuranceInfoResponse={insuranceInfoCategoryMemberName}
        />
        <InsuranceInfoRow
          insuranceInfoCategory={tQuickmatchFlow132(
            'step108.insuranceInfoCategoryMemberDOB',
            'Date of birth',
            undefined
          )}
          insuranceInfoResponse={insuranceInfoCategoryMemberDOB}
        />
        <InsuranceInfoRow
          insuranceInfoCategory={tQuickmatchFlow132(
            'step108.insuranceInfoCategoryMemberState',
            'Location',
            undefined
          )}
          insuranceInfoResponse={insuranceInfoCategoryMemberState}
        />
        <InsuranceInfoRow
          insuranceInfoCategory={[
            tQuickmatchFlow132('step108.insuranceInfoCategoryMemberID', 'Member/', undefined),
            tQuickmatchFlow132(
              'step108.insuranceInfoCategorySubscriberID',
              'Subscriber ID',
              undefined
            ),
          ]}
          insuranceInfoResponse={insuranceInfoCategoryMemberSubscriberID}
          isLastRow
          hasTooltip
        />
      </View>
    </FullWidthContainer>
  );
};

const DoubleCheckInsuranceInfo = ({ quickEligibilityInfo }: DoubleCheckInsuranceInfoProps) => {
  const {
    insurancePayer: insurancePayerInfo,
    firstName,
    lastName,
    dob, // 08/19/1995
    state: stateAbbrev, // NY
    memberID,
    error,
  } = quickEligibilityInfo as QuickEligibilityInfo;
  const dateFormat = 'MMMM Do, YYYY';
  const formatDate = (date) => moment(date).format(dateFormat);

  const insuranceInfoCategoryInsuranceCompanyName = insurancePayerInfo?.label || '';
  const insuranceInfoCategoryMemberName = firstName && lastName ? `${firstName} ${lastName}` : '';
  const insuranceInfoCategoryMemberDOB = formatDate(dob);
  const insuranceInfoCategoryMemberState = convertUSStateAbbreviationToName(
    stateAbbrev && stateAbbrev?.length > 0 ? stateAbbrev : ''
  );
  const insuranceInfoCategoryMemberSubscriberID = memberID && memberID.length > 0 ? memberID : '';

  const eligibilityRejectionReason = bhInsuranceEligibilityErrorParser(error as AxiosError);

  useEffect(() => {
    if (eligibilityRejectionReason) {
      trackBHEligibilityRejectionReason(
        "Can't find insurance Seen",
        'cant-find-insurance',
        eligibilityRejectionReason,
        !insurancePayerInfo?.isOutOfNetwork
      );
    }
  }, [eligibilityRejectionReason, insurancePayerInfo?.isOutOfNetwork]);

  return (
    <EmotionThemeProvider version="2.0.0">
      <InsuranceInfoSummary
        insuranceInfoCategoryInsuranceCompanyName={insuranceInfoCategoryInsuranceCompanyName}
        insuranceInfoCategoryMemberName={insuranceInfoCategoryMemberName}
        insuranceInfoCategoryMemberDOB={insuranceInfoCategoryMemberDOB}
        insuranceInfoCategoryMemberState={insuranceInfoCategoryMemberState}
        insuranceInfoCategoryMemberSubscriberID={insuranceInfoCategoryMemberSubscriberID}
        isInsuranceInNetwork={!insurancePayerInfo?.isOutOfNetwork}
      />
    </EmotionThemeProvider>
  );
};

export default DoubleCheckInsuranceInfo;
