import { useMemo } from 'react';

import { SpacingView, TextDS, View, styled } from '@talkspace/react-toolkit';
import { getSessionLiveModalitiesDisplayString } from 'ts-frontend/utils/displayStrings';
import { HomePageState, useHomePageState } from '../../HomePage';
import { PartnerBadge } from '../../PartnerBranding';

const Styled = {
  Container: styled(View)(({ theme: { spacing: s } }) => {
    return {
      alignSelf: 'center',
      padding: `${s('space400')}px ${s('space200')}px`,
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      maxWidth: 390,
    };
  }),
  Title: styled(TextDS)(({ theme: { spacing: s } }) => {
    return {
      marginBottom: s('space400'),
    };
  }),
  BadgeContainer: styled(View)(() => {}),
  HeaderContainer: styled(View)(({ theme: { spacing: s } }) => {
    return {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: s('space400'),
    };
  }),
  HeaderSubtitle: styled(TextDS)(({ theme: { spacing: s } }) => {
    return {
      marginTop: s('space150'),
      textAlign: 'center',
    };
  }),
};

const getPlanDetailsItems = ({
  allowedModalities,
  totalSessions,
  creditMinutes,
  includesTalkspaceGo,
}: Pick<
  NonNullable<HomePageState['eligiblePlan']>,
  'allowedModalities' | 'totalSessions' | 'creditMinutes' | 'includesTalkspaceGo'
>) => {
  const hasLiveModalities = allowedModalities?.some((modality) =>
    ['video', 'audio', 'chat'].includes(modality.name)
  );
  const hasMessaging = allowedModalities?.some((modality) => modality.name === 'messaging');

  const sessionModalitiesString = getSessionLiveModalitiesDisplayString({
    modalities: allowedModalities.map((modality) => modality.name),
  });
  const sessionCountString =
    { 1: 'One', 2: 'Two', 3: 'Three', 4: 'Four', 5: 'Five', 6: 'Six' }[totalSessions] || 'Many';
  const sessionPluralizedString = totalSessions > 1 ? 'sessions' : 'session';

  return [
    {
      title: 'A dedicated therapist to support you',
      description:
        'We’ll ask you some questions to help match you with the right licensed therapist for you',
    },
    ...(hasLiveModalities
      ? [
          {
            title: `${sessionCountString} monthly live ${sessionPluralizedString}`,
            description: `Connect with your therapist with ${sessionCountString.toLowerCase()} free ${creditMinutes} minute ${sessionModalitiesString} ${sessionPluralizedString} every month.`,
          },
        ]
      : []),
    ...(hasMessaging
      ? [
          {
            title: 'Messaging with your therapist',
            description:
              'You can message your therapist any time and get daily responses in your confidential chat room',
          },
        ]
      : []),
    ...(includesTalkspaceGo
      ? [
          {
            title: 'Self-guided therapy exercises',
            description:
              'In our free Talkspace Go app, you’ll have access to bite-sized exercises, daily reflection prompts, and interactive classes, led by licensed therapists.',
          },
        ]
      : []),
  ];
};

const FreeTherapyPlanDetails = () => {
  const {
    eligiblePlan: {
      partnerID,
      partnerName,
      includesTalkspaceGo,
      creditMinutes,
      allowedModalities,
      totalSessions = 1,
    } = {},
  } = useHomePageState();
  const planDetailsItems = useMemo(() => {
    if (
      allowedModalities === undefined ||
      creditMinutes === undefined ||
      includesTalkspaceGo === undefined
    )
      return [];
    return getPlanDetailsItems({
      allowedModalities,
      creditMinutes,
      totalSessions,
      includesTalkspaceGo,
    });
  }, [allowedModalities, creditMinutes, includesTalkspaceGo, totalSessions]);
  return (
    <Styled.Container align="stretch" justify="center">
      <Styled.HeaderContainer>
        <Styled.Title variant="heading2xl">Your free therapy plan</Styled.Title>
        <Styled.BadgeContainer align="center">
          {partnerID && <PartnerBadge partnerID={partnerID} />}
          <Styled.HeaderSubtitle variant="bodyXs" colorRole="textSubtlest">
            Provided by the {partnerName}
          </Styled.HeaderSubtitle>
        </Styled.BadgeContainer>
      </Styled.HeaderContainer>
      <SpacingView itemSpacing="space300">
        {planDetailsItems.map(({ title, description }) => (
          <SpacingView key={title} itemSpacing="space050">
            <TextDS variant="headingMd">{title}</TextDS>
            <TextDS variant="bodySm" colorRole="textSubtle">
              {description}
            </TextDS>
          </SpacingView>
        ))}
      </SpacingView>
    </Styled.Container>
  );
};

export default FreeTherapyPlanDetails;
