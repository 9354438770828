/* eslint-disable import/prefer-default-export */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';
import { InsuranceEligibilityPayer } from 'ts-frontend/types';
import sessionStorage from '@/core/storage/sessionStorage';
import { formSchema, bhEligibilityFormSchema, discoveryFormSchema } from '../util';

export const useQuickEligibilityForm = (
  partnerFlowID?: number,
  isDiscovery?: boolean,
  insurancePayer?: InsuranceEligibilityPayer
) => {
  const { bhInsuranceEligibilityError } = useFlags();
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  const defaultFormValues = (() => {
    try {
      const basicInformation = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
      const { firstName, lastName, dateOfBirth, state, country, gender } = basicInformation;
      const momentDoB = moment(dateOfBirth);
      const formattedDob = dateOfBirth && momentDoB.isValid() && momentDoB.format('MM/DD/YYYY');

      const memberDetails = JSON.parse(sessionStorage.getItem('TSQM_MemberDetails') || '{}');
      const { memberId: memberID } = memberDetails;

      return {
        memberID: memberID || '',
        firstName: firstName || '',
        lastName: lastName || '',
        state: state || '',
        country: country || '',
        dob: formattedDob || '',
        gender: gender || '',
        insurance: insurancePayer || null,
      };
    } catch (err) {
      return {
        memberID: '',
        firstName: '',
        lastName: '',
        state: '',
        country: '',
        dob: '',
        gender: '',
        insurance: null,
      };
    }
  })();

  let schema;
  if (isDiscovery) {
    schema = discoveryFormSchema;
  } else if (bhInsuranceEligibilityError) {
    schema = bhEligibilityFormSchema(tQuickmatchFlow132);
  } else {
    schema = formSchema;
  }

  return useForm({
    resolver: yupResolver(schema),
    context: { flowId: partnerFlowID },
    defaultValues: defaultFormValues,
  });
};
