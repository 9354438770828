import { FunctionComponent, CSSProperties } from 'react';
import { EmergencyDisclaimer, useWindowWidthState, View, TextDS } from '@talkspace/react-toolkit';
import { jsx } from '@emotion/core';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import styled from '../../core/styled';
import { isNonPsychiatry } from '../../utils/reviewPlan';

/** @jsxRuntime classic */
/** @jsx jsx */

const StyledFooter = styled(View)({
  zIndex: 20,
  textAlign: 'center',
  '& a': {
    transition: 'all 200ms ease-in-out',
  },
});

interface FooterProps {
  isLoggedIn?: boolean;
  stepCategory?: string;
  isPositionAbsolute?: boolean;
  flowId?: number;
  isUpdateCoverageFlow?: boolean | null;
}

const Footer: FunctionComponent<FooterProps> = ({
  isLoggedIn,
  flowId,
  stepCategory,
  isPositionAbsolute,
  isUpdateCoverageFlow,
}) => {
  // Handles special case of scroll-able element on matches step
  // casting as CSSProperties necessary to prevent nonsensical ts error: "Type 'string' is not assignable to type 'Position | undefined'."
  const footerStyle = isPositionAbsolute
    ? ({ position: 'fixed', bottom: 0, width: '100%' } as CSSProperties)
    : {};
  const { isMobile } = useWindowWidthState();
  const { t: tUpdateCoverage } = useTranslation('updateCoverage');

  const footerMargin =
    isMobile && flowId && isNonPsychiatry(flowId)
      ? { margin: '0px auto 20px auto' }
      : { margin: '10px auto 20px auto' };

  const { insuranceConfirmationStep } = useFlags();

  if (
    stepCategory === 'insuranceConfirmation' &&
    isUpdateCoverageFlow &&
    insuranceConfirmationStep.flowId === flowId &&
    insuranceConfirmationStep.variant === 'treatment'
  ) {
    return (
      <StyledFooter
        as="footer"
        style={footerStyle && { maxWidth: isMobile ? 340 : '100%', ...footerMargin }}
      >
        <TextDS variant="bodyXs">
          <TextDS variant="headingXs" as="span" style={{ display: 'inline' }}>
            {tUpdateCoverage('branchingScreen.footerNote', 'Note:', undefined)}
          </TextDS>{' '}
          {tUpdateCoverage(
            'branchingScreen.footerText',
            "While it's uncommon, we may need to match you with a new therapist if they don`t accept your new form of payment.",
            undefined
          )}
        </TextDS>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter style={footerStyle && { maxWidth: isMobile ? 340 : '100%', ...footerMargin }}>
      <EmergencyDisclaimer
        isLoggedIn={isLoggedIn}
        dataQa={`${stepCategory || ''}EmergencyResourcesLink`}
        style={{
          width: '100%',
          marginTop: 16,
          fontWeight: 400,
          fontSize: 11,
        }}
      />
    </StyledFooter>
  );
};

export default Footer;
