import { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useTranslation } from '@talkspace/i18n';
import register from '@talkspace/auth/register';
import { CreateAccountForm, SignUpData, View } from '@talkspace/react-toolkit';
import moment from 'moment-timezone';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import formatPhoneNumber from 'ts-frontend/utils/phoneNumbers';
import { getGDPRProps } from 'ts-frontend/helpers/gdpr';
import { useRecoveredSessionState } from '@/hooks/recoveredSessionContext';
import {
  getEmailFromSessionStorage,
  getPhoneNumberFromSessionStorage,
  getCustomerInformationFromSessionStorage,
  getMarketingConsentFromSessionStorage,
} from '@/utils/registrationHelpers';
import { HomePageState, Appointment, GenderType, Response, RoomType } from '../HomePage/types';
import {
  QuickmatchRegistrationErrorResponse,
  registerUserWithVoucherCognito,
  registerUserWithVoucher,
} from '../../Helpers/apiService';
import {
  trackEvent,
  trackGTMEvent,
  identify,
  VWO,
  setPeopleLogin,
  getPlatform,
} from '../../utils/analytics/events';
import { minimumAge } from '../../Helpers/ageHelper';
import appConfigs from '../../utils/configs';
import { redirectToRoom, RegistrationErrors } from '../../Helpers/registrationHelpers';
import SignupFooter from '../SignupFooter/SignupFooter';
import { FLOW_133_ISRAEL } from '../../Flows';
import { useIonicFrameKeyboardListener } from '../../hooks';

interface RegisterWithVoucherProps {
  flowId: number;
  funnelVariation: HomePageState['funnelVariation'];
  qmPartnerCode: string;
  responses: Response[];
  therapistId: number;
  clientMatchPresentingProblems: ClientMatchPresentingProblem[];
  redirectFrom: number;
  setQMFlowDone: () => void;
  clientCountry?: string;
  clientState?: string;
  appointment?: Appointment;
  roomType?: RoomType;
  clientDateOfBirth?: string;
  clientGender?: GenderType;
  isNoMatches?: boolean;
  isNYCTeen?: boolean;
  cognitoActive: boolean;
  referralSource: HomePageState['referralSource'];
}

const RegisterWithVoucher: FunctionComponent<RegisterWithVoucherProps> = ({
  flowId,
  funnelVariation,
  qmPartnerCode,
  responses,
  therapistId,
  clientMatchPresentingProblems,
  redirectFrom,
  setQMFlowDone,
  clientCountry,
  clientState,
  appointment,
  roomType,
  clientDateOfBirth,
  isNoMatches,
  isNYCTeen,
  clientGender = undefined,
  cognitoActive,
  referralSource,
}) => {
  const recoveredSessionState = useRecoveredSessionState();
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const sessionParams = useMemo(() => {
    const { country: phoneNumberCountryCode, nationalNumber: phoneNumber } =
      getPhoneNumberFromSessionStorage();
    const email =
      getEmailFromSessionStorage() ||
      recoveredSessionState?.dispatcherState?.organizationEmail ||
      '';

    const consent = getMarketingConsentFromSessionStorage();
    return { phoneNumberCountryCode, phoneNumber, email, consent };
  }, [recoveredSessionState?.dispatcherState?.organizationEmail]);

  const hideFooter = useIonicFrameKeyboardListener();

  const { emailVerificationUiCopyChanges, emailVerificationOtp } = useFlags();

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  useEffect(() => {
    trackEvent('TS Experiment Session', {
      experimentName: 'email-verification-ui-copy-changes',
      variantName: emailVerificationUiCopyChanges ? 'treatment' : 'control',
    });

    if (emailVerificationOtp && emailVerificationOtp !== 'disabled') {
      trackEvent('TS Experiment Session', {
        experimentName: 'email-verification-OTP',
        variantName: emailVerificationOtp,
      });
    }
  }, [emailVerificationUiCopyChanges, emailVerificationOtp]);

  const invalidVoucherError = tQuickmatchCommon(
    'registerWithVoucherStep.invalidVoucherError',
    'Voucher code is invalid',
    undefined
  );

  const headerCopy = emailVerificationUiCopyChanges
    ? tQuickmatchCommon('accountActivation.headerText2', 'Create your account', undefined)
    : tQuickmatchCommon('registerWithVoucherStep.titleText', 'Complete your account', undefined);

  const bodyCopy = emailVerificationUiCopyChanges
    ? tQuickmatchCommon(
        'accountActivation.body2Text',
        "We’re excited that you’ve decided to move forward with Talkspace. Now let's create your account.",
        undefined
      )
    : tQuickmatchCommon(
        'registerWithVoucherStep.bodyText',
        'We’re excited that you’ve decided to move forward with Talkspace',
        undefined
      );

  const sourceFlowID = redirectFrom || flowId;

  const onSubmit = async (formState: SignUpData, password: string) => {
    let url: string;
    let userId: number;
    let authToken: string;
    setRequestPending(true);

    const shouldSendPhoneNumber = cognitoActive || !!formState.phoneNumber;

    const sessionCustomerInfo = getCustomerInformationFromSessionStorage();

    const customerInformation = {
      ...sessionCustomerInfo,
      phone: sessionCustomerInfo.phone || formState.phoneNumber,
    };

    const params = {
      appointment,
      clientDateOfBirth,
      funnelVariation,
      roomType,
      therapistId,
      email: formState.email,
      nickname: formState.nickname,
      voucherCode: qmPartnerCode,
      timezone: moment.tz.guess(),
      registerUrl: window.location.href,
      attribution: {
        referrerUrl: document.referrer,
        registrationUrl: window.location.href,
      },
      quickmatchResponses: responses.filter(
        (qmResponse) => qmResponse.payfirst_step_prompt || qmResponse.response_category_id
      ),
      presentingProblems: clientMatchPresentingProblems,
      gender: clientGender,
      isPendingMatch: !!isNoMatches,
      flowID: sourceFlowID,
      ...(shouldSendPhoneNumber && {
        phoneNumber: formState.phoneNumber,
        phoneNumberCountryCode: formState.phoneNumberCountryCode,
        acceptSMSMarketing: formState.isChecked,
      }),
      ...(cognitoActive
        ? {
            customerInformation: {
              ...(formState.country &&
                appConfigs.featureFlags.countryStateDropDown && { country: formState.country }),
              ...(formState.country === 'US' && { state: formState.state }),
            },
          }
        : { password, customerInformation }),
    };

    try {
      if (cognitoActive) {
        ({ url, userId, authToken } = await registerUserWithVoucherCognito(params));
        const cognitoPhoneNumber = formState.phoneNumber
          ? formatPhoneNumber(formState.phoneNumber, formState.phoneNumberCountryCode)
          : undefined;
        await register({
          password,
          authToken,
          email: formState.email as string,
          phoneNumber: cognitoPhoneNumber,
        });
      } else {
        ({ url, userId } = await registerUserWithVoucher(params));
      }

      if (!url) throw new Error('no room url');

      setQMFlowDone();
      sessionStorage.clear();

      identify(userId);
      setPeopleLogin(userId);

      trackEvent('Register', {
        'User ID': userId,
      });

      trackEvent('User Referral Source', {
        referralSource,
      });

      // GTM QM Payment Event
      trackGTMEvent('qmPaymentComplete', {
        result: 'success',
        platform: getPlatform(),
        tspl: roomType === 'psychiatryRoom' ? 2 : 1,
      });

      VWO.trackPurchaseGoal();
      VWO.trackQMPurchaseGoal();

      setTimeout(() => redirectToRoom(url), 1000);
    } catch (err) {
      const error = err as QuickmatchRegistrationErrorResponse;
      const errors = error?.response?.data?.errors || [];
      setRequestPending(false);

      if (errors.length && errors[0].code !== 'paramsError') {
        errors.forEach(({ code, message }) => {
          if (code === 'serverError') {
            setIsError(true);
            setErrorMessage(message);
          }
          if (code === 'voucherError') {
            setIsError(true);
            setErrorMessage(invalidVoucherError);
          }
          if (code === 'insuranceInfoError') {
            setIsError(true);
            setErrorMessage(RegistrationErrors[message]);
          }
        });
        return;
      }
      setIsError(true);
      setErrorMessage(RegistrationErrors.duplicateAccount);
    }
  };

  const phoneNumberOptional = !!isNYCTeen;
  const canChangeEmail = isNYCTeen;
  const loginFooter = isNYCTeen
    ? () => <SignupFooter containerStyle={{ marginTop: 17 }} />
    : undefined;

  const { isGDPR, consentGDPRMarketing, consentGDPRProcessing, consentGDPRTransferring } =
    getGDPRProps();
  return (
    <View align="center" style={{ marginTop: -36 }}>
      <CreateAccountForm
        clientCountry={clientCountry}
        clientState={clientState}
        bodyCopy={bodyCopy}
        headerCopy={headerCopy}
        minimumAge={minimumAge(sourceFlowID)}
        isUpdating={requestPending}
        onSubmit={onSubmit}
        isError={isError}
        errorMessage={errorMessage}
        showCountryStateDropdown={appConfigs.featureFlags.countryStateDropDown && cognitoActive}
        phoneNumber={sessionParams.phoneNumber}
        phoneNumberCountryCode={
          flowId === FLOW_133_ISRAEL ? 'IL' : sessionParams.phoneNumberCountryCode
        }
        clientEmail={sessionParams.email}
        canChangeEmail={canChangeEmail}
        phoneNumberOptional={phoneNumberOptional}
        loginFooter={loginFooter}
        consent={!!sessionParams.phoneNumber && !!sessionParams.email && !!sessionParams.consent}
        hideFooter={hideFooter}
        isGDPR={isGDPR}
        isNYCTeen={isNYCTeen}
        consentGDPRMarketing={consentGDPRMarketing}
        consentGDPRProcessing={consentGDPRProcessing}
        consentGDPRTransferring={consentGDPRTransferring}
      />
    </View>
  );
};

export default RegisterWithVoucher;
