import styled from '@emotion/styled';
import { Small, Link, useEmotionTheme } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isTeensFlow } from '../../Helpers/flowsHelper';
import AlertBox from '../AlertBox';

const StyledAlertBox = styled(AlertBox)({
  width: '100%',
  maxWidth: 335,
  alignSelf: 'center',
});

const DateOfBirthUnderageAlert = ({ flowId }: { flowId: number }) => {
  const { colors, colorRoles } = useEmotionTheme();
  const { ageLocationEligibility } = useFlags();
  return (
    <StyledAlertBox dataQa="underageError" style={{ marginTop: 0, marginBottom: 20, fontSize: 14 }}>
      Talkspace cannot provide service to individuals under the age of 13 at this time. If you or
      anyone you know are in a crisis or may be in danger, please use
      <Link
        dataQa="helpResourcesLink"
        style={{ textDecoration: ageLocationEligibility ? 'underline' : 'none' }}
        roundedFocusStyle
        target="_blank"
        primaryColor={
          ageLocationEligibility ? colorRoles.typography.textBrandDefault : colors.permaErrorRed
        }
        href={`https://helpnow.talkspace.com/under-${isTeensFlow(flowId) ? '13' : '18'}`}
      >
        <Small
          variant="smallBolderGrey"
          inline
          style={{
            color: ageLocationEligibility
              ? colorRoles.typography.textBrandDefault
              : colors.permaErrorRed,
          }}
        >
          these resources
        </Small>
      </Link>
      to get immediate help.
    </StyledAlertBox>
  );
};

export default DateOfBirthUnderageAlert;
