import { isGDPRCountry } from 'ts-frontend/helpers/gdpr';
import { HomePageState } from '../Components/HomePage';
import { InternalTarget, RedirectRule } from '../Flows';

/*
  Defines various redirect conditions based on HomePageState
*/
const redirectConditions: Record<RedirectRule, (state: HomePageState) => boolean> = {
  under18GDPR: ({ clientCountry, clientAgeNumber }) =>
    Boolean(
      clientCountry &&
        isGDPRCountry({ countryCode: clientCountry }) &&
        clientAgeNumber &&
        clientAgeNumber < 18
    ),
};

/*
  Returns the redirect target ID based on the redirect rules
  NOTE: flowConfig must contain redirectRules mapping rule name to target step id
*/
const getRedirectTargetID = (state: HomePageState): InternalTarget | undefined => {
  const { flowConfig: { redirectRules } = {} } = state;
  if (!redirectRules) return undefined;

  for (const [rule, redirectTargetID] of Object.entries(redirectRules)) {
    if (redirectConditions[rule](state)) {
      return redirectTargetID;
    }
  }

  return undefined;
};

export default getRedirectTargetID;
