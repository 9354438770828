import { FunctionComponent } from 'react';
import {
  TextWithHighlightedMatches,
  View,
  Tiny,
  useWindowWidthState,
  CalendarCheck,
  Image,
  Heart,
  Globe,
} from '@talkspace/react-toolkit';
import { mapSimplifiedToOriginalPresentingProblems } from 'ts-frontend/helpers/presentingProblems';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import styled from '@/core/styled';
import joinedTalkspaceIcon from '../../Assets/icon_joined_talkspace.svg';

interface ProviderInfoProps {
  yearsExperience: number;
  yearsWithTalkspace: number | null;
  allFieldsOfExpertise: string[];
  mostRelevantExpertise?: string[];
  moreExpertise: boolean;
  additionalLanguages: string[];
  presentingProblems: ClientMatchPresentingProblem[];
}

const Focus: FunctionComponent<
  Pick<
    ProviderInfoProps,
    'allFieldsOfExpertise' | 'mostRelevantExpertise' | 'moreExpertise' | 'presentingProblems'
  >
> = ({ allFieldsOfExpertise, mostRelevantExpertise, moreExpertise, presentingProblems }) => {
  const mappedPresentingProblems = mapSimplifiedToOriginalPresentingProblems(presentingProblems);
  let providerExpertise = allFieldsOfExpertise;
  let slice = Math.min(allFieldsOfExpertise.length - 1, 3);
  if (moreExpertise) {
    slice = providerExpertise.length;
  }
  if (mostRelevantExpertise && mostRelevantExpertise.length > 0 && !moreExpertise) {
    providerExpertise = mostRelevantExpertise;
    slice = Math.min(mostRelevantExpertise.length - 1, 3);
  }
  return (
    <TextWithHighlightedMatches
      text={providerExpertise}
      highlightTargets={mappedPresentingProblems}
      isCollapsed={!moreExpertise}
      slice={slice}
      targetKey="expertiseName"
    />
  );
};

const AttributeTitleText = styled(Tiny)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    color: colors.osloGrey,
    fontWeight: 500,
    letterSpacing: 0.23,
  };
});
const AttributeText = styled(Tiny)(({ theme: { colors } }) => {
  return { fontSize: 15, textAlign: 'left', marginLeft: 30, maxWidth: 250, color: colors.black };
});

const ProviderInfo: FunctionComponent<ProviderInfoProps> = ({
  yearsExperience,
  yearsWithTalkspace = 5,
  allFieldsOfExpertise,
  mostRelevantExpertise,
  moreExpertise,
  additionalLanguages,
  presentingProblems,
}) => {
  const { isMobile } = useWindowWidthState();
  let finalYearsWithTalkspaceText =
    yearsWithTalkspace && yearsWithTalkspace > 1 ? `${yearsWithTalkspace} years ago` : undefined;
  if (yearsWithTalkspace === 1) finalYearsWithTalkspaceText = '1 year ago';
  if (yearsWithTalkspace === 0) finalYearsWithTalkspaceText = 'less than a year ago';

  return (
    <View style={{ marginTop: isMobile ? 50 : -20, width: '100%' }}>
      <View row>
        <CalendarCheck style={{ marginRight: 13 }} />
        <AttributeText style={{ marginLeft: 0 }}>
          {yearsExperience > 1 && `${yearsExperience} years in practice`}
          {yearsExperience === 1 && '1 year in practice'}
          {yearsExperience === 0 && 'less than 1 year in practice'}
        </AttributeText>
      </View>
      {yearsWithTalkspace && (
        <View style={{ marginTop: 10 }}>
          <View row>
            <Image
              src={joinedTalkspaceIcon}
              style={{ width: 17, height: 18, marginRight: 13 }}
              alt="suitcase icon"
              role="presentation"
            />
            <AttributeTitleText>Joined Talkspace</AttributeTitleText>
          </View>
          <AttributeText>{finalYearsWithTalkspaceText}</AttributeText>
        </View>
      )}
      <View style={{ marginTop: 10 }}>
        <View row>
          <Heart style={{ marginRight: 13 }} />
          <AttributeTitleText>Focus</AttributeTitleText>
        </View>
        <View style={{ marginLeft: 30, marginRight: 15, maxWidth: 250 }}>
          <Focus
            allFieldsOfExpertise={allFieldsOfExpertise}
            mostRelevantExpertise={mostRelevantExpertise}
            moreExpertise={moreExpertise}
            presentingProblems={presentingProblems}
          />
        </View>
      </View>
      {additionalLanguages.length > 0 && (
        <View style={{ marginTop: 10 }}>
          <View row>
            <Globe style={{ marginRight: 13 }} />
            <AttributeTitleText>Additional Languages</AttributeTitleText>
          </View>
          <AttributeText>{additionalLanguages.join(', ')}</AttributeText>
        </View>
      )}
    </View>
  );
};

export default ProviderInfo;
