import { isZipValid } from '@talkspace/react-toolkit';
import * as yup from 'yup';
import { ageErrorCopy } from '../../../Helpers/ageHelper';

export interface DTEZipcodeEligibilityFields {
  age: number;
  zipcode: string;
  country: string;
  state: string;
}

export interface DTEZipcodeEligibilityYupContext {
  flowId?: number;
}

export const ZIPCODE_ERROR = 'Zip code is required';
const INVALID_ZIPCODE = 'This is not a valid zip code';
const AGE_MISSING_ERROR = 'Age is required';
export const MAX_AGE_ERROR = 'Please double-check your age';

const dteZipCodeEligibilitySchema = yup.object().shape({
  age: yup
    .number()
    .required(AGE_MISSING_ERROR)
    .typeError(AGE_MISSING_ERROR)
    .test(
      'is-under-age',
      (userInput?: number, testContext?: yup.TestContext<DTEZipcodeEligibilityYupContext>) => {
        if (!userInput) return false;
        if (testContext && userInput && userInput < 13) {
          return testContext.createError({
            message: ageErrorCopy(testContext.options?.context?.flowId, true),
          });
        }

        return true;
      }
    )
    .test(
      'is-over-age',
      (userInput?: number, testContext?: yup.TestContext<DTEZipcodeEligibilityYupContext>) => {
        if (!userInput) return false;
        if (testContext && userInput && userInput > 120) {
          return testContext.createError({
            message: MAX_AGE_ERROR,
          });
        }

        return true;
      }
    ),
  zipcode: yup
    .string()
    .test('is-required-zip-code', ZIPCODE_ERROR, (zipCodeValue) => {
      if (zipCodeValue && zipCodeValue.length > 0) {
        return true;
      }
      return false;
    })
    .test('min-length', INVALID_ZIPCODE, (value) => {
      if (!value) return true; // Skip validation if empty (handled by required test)
      return value.length >= 5;
    })
    .test(
      'is-valid-zip-code',
      ZIPCODE_ERROR,
      (zipCodeValue, context) =>
        zipCodeValue && context.parent.country && isZipValid(zipCodeValue, context.parent.country)
    ),
  state: yup.string().required('State is required'),
});

export default dteZipCodeEligibilitySchema;
