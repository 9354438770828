/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import moment from 'moment';

import { states } from '@/Helpers/locales';
import { isUnderage, isFutureDate, isOverAge120 } from '../../../Helpers/ageHelper';

export const stateOptions = [
  {
    value: 'other',
    label: 'I live outside of the US',
  },
  ...states,
];

export const genderOptions = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'U',
    label: 'Other',
  },
];

export interface EligibilityYupContext {
  flowId?: number;
}

export const formSchema = yup.object().shape({
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  memberID: yup.string().required('Member ID is required.'),
  state: yup.string().required('Location is required.'),
  country: yup.string().when('state', {
    is: 'other',
    then: (schema) => schema.required('Country is required.'),
    otherwise: (schema) => schema.optional(),
  }),
  dob: yup
    .string()
    .test('invalidDate', 'This is not a valid date.', (value) => {
      if (!value) {
        return true;
      }
      if (!value.match(/\d\d\/\d\d\/\d\d\d\d/gi)) {
        return false;
      }
      const parsed = moment(value, 'MM/DD/YYYY');
      if (!parsed.isValid()) {
        return false;
      }
      return true;
    })
    .test(
      'under13',
      'Age too young.',
      (userInput?: string, testContext?: yup.TestContext<EligibilityYupContext>) => {
        if (!userInput) {
          return true;
        }

        const flowID = testContext?.options?.context?.flowId;
        const isUnderAge = flowID && isUnderage(userInput, flowID);

        return !isUnderAge;
      }
    )
    .required('Date of Birth is required.'),
});

// if edit, require insurance payer, else make it nullable, react-hook-form yup context (.when conditional, heardAbout on OneFormElig)
export const bhEligibilityFormSchema = (
  tQuickmatchFlow132: (key: string, fallback: string, options?: Record<string, string>) => string
) =>
  yup.object().shape({
    insurance: yup
      .object({
        id: yup.number().default(null).required(),
        value: yup.string().default(null).required(),
        label: yup.string().default(null).required(),
      })
      .default(null)
      .nullable()
      .notRequired(),
    firstName: yup
      .string()
      .required(
        tQuickmatchFlow132(
          'step101.formSchemaPatientFirstName.required',
          'First name is required.',
          undefined
        )
      ),
    lastName: yup
      .string()
      .required(
        tQuickmatchFlow132('step101.formSchemaPatientLastName', 'Last name is required.', undefined)
      ),
    memberID: yup
      .string()
      .required(
        tQuickmatchFlow132(
          'step101.formSchemaMemberSubscriberID',
          'Member / Subscriber ID is required.',
          undefined
        )
      ),
    state: yup
      .string()
      .required(tQuickmatchFlow132('formSchemaLocation', 'Location is required.', undefined)),
    country: yup.string().when('state', {
      is: 'other',
      then: (schema) =>
        schema.required(tQuickmatchFlow132('formSchemaCountry', 'Country is required.', undefined)),
      otherwise: (schema) => schema.optional(),
    }),
    dob: yup
      .string()
      .test(
        'invalidDate',
        tQuickmatchFlow132(
          'step101.formSchemaDOB.invalidDate',
          'This is not a valid date.',
          undefined
        ),
        (value) => {
          if (!value?.match(/\d\d\/\d\d\/\d\d\d\d/gi)) {
            return false;
          }
          const parsed = moment(value, 'MM/DD/YYYY');
          if (!parsed.isValid()) {
            return false;
          }
          return true;
        }
      )
      .test(
        'dobFuture',
        tQuickmatchFlow132(
          'step101.formSchemaDOB.dobFuture',
          'Date of Birth cannot be a future date.',
          undefined
        ),
        (userInput?: string) => {
          if (!userInput) {
            return true;
          }

          const futureDate = isFutureDate(userInput);

          return !futureDate;
        }
      )
      .test(
        'under13',
        tQuickmatchFlow132(
          'step101.formSchemaDOB.under13',
          'Unfortunately, Talkspace does not provide therapy for children under 13.',
          undefined
        ),
        (userInput?: string, testContext?: yup.TestContext<EligibilityYupContext>) => {
          if (!userInput) {
            return true;
          }

          const flowID = testContext?.options?.context?.flowId;
          const isUnderAge = flowID && isUnderage(userInput, flowID);

          return !isUnderAge;
        }
      )
      .test(
        'over120',
        tQuickmatchFlow132(
          'step101.formSchemaDOB.over120',
          'Please check that your Date of Birth is accurate.',
          undefined
        ),
        (userInput?: string) => {
          if (!userInput) {
            return true;
          }

          const overAge120 = isOverAge120(userInput);

          return !overAge120;
        }
      )
      .required(
        tQuickmatchFlow132(
          'step101.formSchemaDOB.required',
          'Date of Birth is required.',
          undefined
        )
      ),
  });

export const discoveryFormSchema = formSchema.shape({
  memberID: yup.string().optional(),
  gender: yup.string().required('Sex is required.'),
});
