import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { upsertSession } from 'ts-frontend/utils';
import { ageGroup } from '@/Helpers/ageHelper';

// eslint-disable-next-line import/prefer-default-export
export const useFormSaver = () => {
  const values = useWatch() as Record<string, any>;
  const formContext = useFormContext();

  useEffect(() => {
    upsertSession('TSQM_BasicInformation', {
      memberID: values.memberID,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dob,
      state: values.state,
      country: values.state ? values.country || 'US' : undefined,
      gender: values.gender,
    });

    upsertSession('TSQM_MemberDetails', {
      memberId: values.memberID,
    });

    upsertSession('TSQM_InsuranceInformation', {
      insurance: values.insurance,
    });

    const { dateOfBirth, invalidDOB, missingDOB, age } = (() => {
      const result = {
        dateOfBirth: '',
        invalidDOB: true,
        missingDOB: true,
        age: null,
      };
      if (!values.dob?.match(/\d\d\/\d\d\/\d\d\d\d/gi)) {
        return result;
      }
      const now = moment();
      const dobMoment = moment(values.dob, 'MM/DD/YYYY');
      if (dobMoment.isValid()) {
        Object.assign(result, {
          dateOfBirth: values.dob,
          invalidDOB: false,
          missingDOB: false,
          age: now.diff(dobMoment, 'years'),
        });
      }
      return result;
    })();

    const dobData = {
      dateOfBirth,
      invalidDOB,
      missingDOB,
      missingOrInvalidFields: missingDOB || invalidDOB,
      isUnderage: formContext.formState.errors.dob?.type === 'under13',
      ageRange: ageGroup(age),
    };

    upsertSession('TSQM_DateOfBirth', dobData);
  }, [values, formContext]);
};
