import {
  View,
  Image,
  LastingFromTSLogoBlue,
  Large,
  Button,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { LastingPromotionStep, ExternalTarget, InternalTarget, UpdateStepObj } from '@/Flows/types';
import lastingAppPreview from '@/Assets/lasting_app_preview_oval.png';

interface LastingPromotionProps {
  step: LastingPromotionStep;
  updateStep: (
    target: ExternalTarget | InternalTarget,
    objectArg: UpdateStepObj
  ) => Promise<unknown>;
}

const LastingPromotion = ({ step, updateStep }: LastingPromotionProps) => {
  const { isSmall } = useWindowWidthState();

  return (
    <View
      align="center"
      style={{ minWidth: isSmall ? undefined : 375, maxWidth: 440, alignSelf: 'center' }}
    >
      <Image source={lastingAppPreview} width={281} />

      <LastingFromTSLogoBlue style={{ marginTop: 7 }} />

      <Large
        variant="largeDarkGrey"
        style={{ marginTop: 10, marginBottom: 20, maxWidth: isSmall ? 328 : undefined }}
      >
        Your plan includes free access to Lasting, the #1 couples therapy program. Enter your{' '}
        <Large variant="largeBoldWide" inline>
          organization keyword
        </Large>{' '}
        to access hundreds of sessions and couples workshops!
      </Large>

      <Button
        style={{ marginTop: 15, width: 320 }}
        onPress={() => {
          updateStep(`${step.externalTarget}`, {
            payfirst_step_id: step.analyticsId,
            payfirst_step_prompt: step.prompt,
            response_category_id: step.response_category_id,
            response_prompt: 'Try Lasting',
          });
        }}
      >
        Try Lasting
      </Button>

      <Button
        style={{ marginTop: 15, width: 320 }}
        onPress={() => {
          updateStep(`${step.internalTarget}`, {
            payfirst_step_id: step.analyticsId,
            payfirst_step_prompt: step.prompt,
            response_category_id: step.response_category_id,
            response_prompt: 'Maybe later',
          });
        }}
      >
        Maybe later
      </Button>
    </View>
  );
};

export default LastingPromotion;
