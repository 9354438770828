import { states } from '../Helpers/locales';
import { QM_COPY } from '../utils/qmCopy';
import { QMFlow } from './types';
import {
  initFlowConfig,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';

/** Flow redirected from survey wizard assessment */
export const FLOW_63_CONSUMER_POST_SURVEY_WIZARD = 63; // Once we have another use-case, we should rename this variable into a more generic B2C flow

const flow: QMFlow = {
  flowId: FLOW_63_CONSUMER_POST_SURVEY_WIZARD,
  flowConfig: {
    ...initFlowConfig,
    isB2B: false,
    isTeen: true, // to support prompt for consent for teen
    version: 4,
  },
  steps: [
    {
      id: 1,
      prompt: 'What is your age?',
      analyticsId: 61,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      response_category_id: 5,
      doNotAllowSearch: true,
      progressCurrent: 1,
      progressMax: 6,
      targets: {
        adult: 2, // NOTE: Same as adult, teen-specific step is removed
        teen: 2,
        under13: 'https://helpnow.talkspace.com/under-13',
        under18GDPR: 42,
      },
    },
    {
      id: 42,
      category: 'notAvailableInYourRegion',
    },
    {
      id: 2,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      category: 'dropdown',
      doNotAllowSearch: false,
      response_category_id: 6,
      progressCurrent: 2,
      progressMax: 6,
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.submit,
      buttonTarget: 3,
    },
    {
      id: 3,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      category: 'presentingProblems',
      heading2:
        'What led you to seek help today? And by the way, “I’m not sure” is a perfectly adequate response.',
      response_category_id: 2,
      internalTarget: 135,
      skipText: "I'm not sure",
      skipInternalTarget: 135,
      progressCurrent: 3,
      progressMax: 6,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'Select your preferred times for therapy',
      heading2: 'Select your preferred times for therapy',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(4),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 4,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 4,
      progressCurrent: 3.5,
      progressMax: 6,
    },
    {
      id: 4,
      prompt: 'Describe your goals',
      analyticsId: 86,
      heading2: 'Describe your goals in your own words',
      category: 'freeText',
      textAreaPlaceholder: 'Example: Feel less anxiety in social settings',
      response_category_id: 7,
      internalTarget: 5,
      submitText: 'Continue',
      skipText: 'Skip',
      skipInternalTarget: 5,
      progressCurrent: 4,
      progressMax: 6,
    },
    {
      id: 5,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      heading2: 'What gender would you prefer in a provider?',
      category: 'select1',
      response_category_id: 3,
      progressCurrent: 5,
      progressMax: 6,
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 2,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 6,
          answerValue: undefined,
        },
      ],
      selfServe: {
        field: 'therapistGender',
      },
    },
    {
      id: 6,
      prompt: 'Loading',
      category: 'loading1',
      buttonTarget: 7,
      hideBackButton: true,
    },
    {
      id: 7,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 8,
      zeroMatchTarget: 10,
    },
    {
      id: 8,
      category: 'matchConfirmation',
      buttonTarget: 9,
    },
    {
      id: 9,
      prompt: 'Scheduler',
      category: 'scheduler',
      buttonTarget: 10,
    },
    {
      id: 10,
      category: 'chooseAndPayClientWeb',
      offerID: 62,
      previousStepsUntilMatches: 3,
    },
  ],
};

export default flow;
