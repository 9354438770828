import { useTranslation } from '@talkspace/i18n';
import {
  AllLaunchDarklyExperimentNames,
  AllLaunchDarklyExperiments,
  LDFlags,
} from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isLDExperimentTreatment } from 'launchDarkly/util';
import {
  isBHCopayStep,
  isChooseAndPayClientWebStep,
  isRegisterWithVoucherStep,
} from '../Helpers/stepTypeHelper';
import {
  FlowConfig,
  FlowStep,
  ChooseAndPayClientWebStep,
  RegisterWithVoucherStep,
  BHCopayStep,
  QMFlow,
  EligibilityType,
  OneFormEligibilityStep,
  NextQuestionAction,
  UpdateStepObj,
  SelfServeField,
  SelectButton,
} from './types';
import {
  HomePageState,
  AnswerValue,
  DynamicValueHomePageState,
  DynamicValueTranslators,
  MapSelfServeFieldsState,
} from '../Components/HomePage/types';
// eslint-disable-next-line import/no-cycle
import { trackEvent } from '../utils/analytics/events';
import { LANGUAGE_ID_ENGLISH } from '../Helpers/matchingLanguages';
import {
  FLOW_128_NYC_TEENS,
  FLOW_147_TEENSPACE_OPTIMIZED,
  FLOW_138_B2B_VOUCHER_GENERIC_V2,
} from '@/Flows';

export const initFlowConfig: Pick<FlowConfig, 'version' | 'disabled'> = {
  version: 1,
  disabled: false,
};

export const ineligiblePromo = {
  ineligiblePromoCouponCode: 'INS221',
  ineligiblePromo: 80,
  ineligiblePromoWeeks: 4,
};

export const b2bOutOfNetworkFlowConfig: FlowConfig = {
  isB2B: true,
  eligibilityType: EligibilityType.trizetto,
  version: 0,
};

export const b2bOutOfNetworkEligibilityConfig: OneFormEligibilityStep = {
  id: 100,
  category: 'oneFormEligibility',

  response_category_id: 9,
  removePhoneNumber: true,
  removeReferralSource: true,
  removeEmployeeRelation: true,
  removeEmail: true,
  removeOrganization: true,
  removeAddress: true,
  ...ineligiblePromo,
};

// TODO: @Luis - improve autocompletion later on when flows / steps are strongly typed
export const findAndModifyStep = (
  steps: QMFlow['steps'],
  searchFieldAndValue: [keyof FlowStep, FlowStep[keyof FlowStep]],
  options: {
    updatedStep?: Partial<FlowStep> | ((step: FlowStep) => FlowStep);
    deleteStep?: boolean;
    returnAllSteps?: boolean;
  }
): QMFlow['steps'] => {
  const { updatedStep, deleteStep, returnAllSteps = true } = options;
  if (!updatedStep && !deleteStep) return steps;
  const [stepField, stepFieldValue] = searchFieldAndValue;
  const stepIndex = steps.findIndex((step) => step[stepField] === stepFieldValue);
  if (stepIndex !== -1) {
    if (returnAllSteps)
      return [
        ...steps.slice(0, stepIndex),
        ...(deleteStep
          ? ([] as FlowStep[])
          : [
              typeof updatedStep === 'function'
                ? updatedStep(steps[stepIndex])
                : ({ ...steps[stepIndex], ...updatedStep } as FlowStep),
            ]),
        ...steps.slice(stepIndex + 1),
      ];
    return [{ ...steps[stepIndex], ...updatedStep } as FlowStep];
  }
  return steps;
};

export const supportUKFlow = (flow: QMFlow): QMFlow => {
  if (navigator.language.toLocaleLowerCase() !== 'en-gb') return flow;

  let ukFlow = flow;
  try {
    let strFlow = JSON.stringify(flow);
    strFlow = strFlow.replace(/therapy/g, 'counseling');
    strFlow = strFlow.replace(/Therapy/g, 'Counseling');
    ukFlow = JSON.parse(strFlow);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('This flow is not serializable');
  }
  return ukFlow;
};

export const registrationStepsValidation = [
  isChooseAndPayClientWebStep,
  isRegisterWithVoucherStep,
  isBHCopayStep,
];

// The order of these registration steps and registrationStepValidation must match
export const allRegistrationPagesSteps = ({
  initialStepID,
  offersPageSettings,
  registerWithVoucherSettings,
}: {
  initialStepID: number;
  offersPageSettings?: Partial<Omit<ChooseAndPayClientWebStep, 'stepId'>>;
  registerWithVoucherSettings?: { isNYCTeen?: boolean };
}): [ChooseAndPayClientWebStep, RegisterWithVoucherStep, BHCopayStep] => [
  {
    id: initialStepID,
    category: 'chooseAndPayClientWeb',
    // Default values
    offerID: 62,
    previousStepsUntilMatches: 3,
    hideProviderInfo: false,
    ...offersPageSettings,
  },
  {
    id: initialStepID + 1,
    category: 'registerWithVoucher1',
    hideBackButton: true,
    ...registerWithVoucherSettings,
  },
  {
    id: initialStepID + 2,
    category: 'BHCopay',
    hideBackButton: true,
  },
];

export const skipToStepIfAnswerValue =
  (nextStepId: number, matchAnswerValue?: string) =>
  (state: HomePageState, answer: AnswerValue) => {
    if (matchAnswerValue === `${answer.response_value}`) {
      return nextStepId;
    }
    return undefined;
  };

export const skipToStepIfClientAgeIsTeen =
  (nextStepId: number, matchAnswerValue?: string) =>
  (state: HomePageState, answer: AnswerValue) => {
    if (!matchAnswerValue || matchAnswerValue === answer.response_value) {
      // teen (age 13-17) is age group 5
      if (state.clientAge === 5 || state.clientAgeTitle === '13-17') {
        return nextStepId;
      }
    }
    return undefined;
  };

export const EXCLUDED_STATES = ['WA', 'NY'];

export const isDteStateRestricted = (state: HomePageState): boolean =>
  !!(
    state.accountType === 'dte' &&
    state.clientAgeTitle !== '13-17' &&
    state.clientState &&
    EXCLUDED_STATES.includes(state.clientState)
  );

export const skipToStepIfClientAgeExists =
  (nextStepId: number, matchAnswerValue?: string, teenStepId?: number) =>
  (state: HomePageState, answer: AnswerValue) => {
    // We do not want to skip this step for DTE clients who are 18+ in certain states
    if (isDteStateRestricted(state)) {
      return undefined;
    }
    if (!matchAnswerValue || matchAnswerValue === answer.response_value) {
      // skip if value has been set (from another flow or from URL params)
      if (state.clientAge) {
        if (teenStepId && (state.clientAge === 5 || state.clientAgeTitle === '13-17'))
          return teenStepId;
        return nextStepId;
      }
    }
    return undefined;
  };

export const skipToStepIfClientAgeAndStateExists =
  (nextStepId: number, matchAnswerValue?: string) =>
  (state: HomePageState, answer: AnswerValue) => {
    if (!matchAnswerValue || matchAnswerValue === answer.response_value) {
      const { clientState, clientCountry, livesInTheUS } = state;
      // skip if value has been set (from another flow or from URL params)
      if (state.clientAge && ((livesInTheUS && clientState) || (!livesInTheUS && clientCountry)))
        return nextStepId;
    }
    return undefined;
  };

const matchesVariant = (
  currentExperimentVariant: string | boolean | undefined,
  variantToMatch: boolean | string | Array<string>
) => {
  if (currentExperimentVariant === undefined) return false;
  if (typeof variantToMatch === 'boolean' || typeof currentExperimentVariant === 'boolean')
    return variantToMatch === currentExperimentVariant;
  if (Array.isArray(variantToMatch)) {
    return variantToMatch.includes(currentExperimentVariant);
  }
  return currentExperimentVariant === variantToMatch;
};

export const skipIfExperiment =
  <
    TName extends AllLaunchDarklyExperimentNames,
    TExperiment extends AllLaunchDarklyExperiments[TName]
  >(config: {
    experimentName: TName;
    variant: TExperiment extends Record<string, any>
      ? TExperiment['variant'] | Array<TExperiment['variant']>
      : boolean;
    /**
     * If the variants match, go to nextStepId
     */
    nextStepId: number;
    /**
     * If true (default), check for experimentActive being true, or for boolean experiments,
     * checks if they are strictly boolean. If this is false, boolean checks allow null or undefined */
    checkExperimentActive?: boolean;
    /** If true (default false), send TS Experiment Session event */
    shouldTrack?: boolean;
  }): NextQuestionAction =>
  (_state, _answer, flags) => {
    const {
      experimentName,
      variant,
      nextStepId,
      checkExperimentActive = true,
      shouldTrack = false,
    } = config;

    if (
      flags &&
      // Allow undefined + null for experiment if checkExperimentActive is false
      (!checkExperimentActive ||
        (flags[experimentName] !== undefined && flags[experimentName] !== null))
    ) {
      const experiment = flags[experimentName] as TExperiment;

      // For inactive experiments, experimentVariant could be null or undefined.
      // In that case, both experimentVariant and experiment must be falsy to skip
      if (
        typeof experiment === 'boolean' ||
        typeof experiment === 'string' ||
        (!checkExperimentActive && !experiment)
      ) {
        if (
          shouldTrack &&
          experiment !== undefined &&
          experiment !== null &&
          experiment !== 'disabled'
        ) {
          trackEvent('TS Experiment Session', {
            experimentName,
            variantName: experiment.toString(),
          });
        }

        if (experiment === variant || (!checkExperimentActive && !variant && !experiment))
          return nextStepId;
        return undefined;
      }

      if (shouldTrack && experiment?.experimentActive) {
        trackEvent('TS Experiment Session', {
          experimentName: experiment.experimentName,
          variantName: experiment.variant,
        });
      }
      if (
        matchesVariant(experiment?.variant, variant) ||
        (!experiment?.experimentActive && variant === 'control')
      ) {
        return nextStepId;
      }
    }
    return undefined;
  };

export const languageMatchingButtons =
  (nextStep: number) =>
  async (state: DynamicValueHomePageState, i18nTranslators: DynamicValueTranslators) => {
    const { matchingLanguages } = state;
    const { tQuickmatchCommon } = i18nTranslators;
    if (!matchingLanguages || !matchingLanguages.length) {
      return [];
    }
    const buttons = matchingLanguages.map((language) => {
      return {
        text: tQuickmatchCommon(`languages.${language.id}`, language.name) || language.name,
        externalTarget: null,
        internalTarget: nextStep,
        answerValue: language.id,
      };
    });
    const englishButton = {
      // @ts-ignore
      text: tQuickmatchCommon('languages.15', 'English') || 'English',
      externalTarget: null,
      internalTarget: nextStep,
      answerValue: LANGUAGE_ID_ENGLISH,
    };
    return [
      englishButton,
      ...buttons.filter((button) => button.answerValue !== LANGUAGE_ID_ENGLISH),
    ];
  };

export const routeToLanguageMatchingIfExperiment =
  (languageStep: number, fallbackStep: number) =>
  (state: HomePageState, answer: UpdateStepObj, flags: LDFlags): number => {
    const { service, flowId } = state;
    const {
      languageMatchingTherapyBh,
      languageMatchingTherapyB2C,
      languageMatchingTeenFlow128,
      languageMatchingDirectOrgFlows,
    } = flags;
    if (flowId === FLOW_128_NYC_TEENS || flowId === FLOW_147_TEENSPACE_OPTIMIZED) {
      return languageMatchingTeenFlow128 ? languageStep : fallbackStep;
    }
    if (flowId === FLOW_138_B2B_VOUCHER_GENERIC_V2) {
      return languageMatchingDirectOrgFlows ? languageStep : fallbackStep;
    }
    if (service === 'psychotherapy' && (languageMatchingTherapyBh || languageMatchingTherapyB2C)) {
      const { b2bForkResult, insurancePayer } = state;
      if (b2bForkResult === 'b2c') {
        return languageMatchingTherapyB2C ? languageStep : fallbackStep;
      }
      if (b2bForkResult === 'b2b' && insurancePayer) {
        return languageMatchingTherapyBh ? languageStep : fallbackStep;
      }
      return fallbackStep;
    }
    return fallbackStep;
  };

export const routeToModalityIfOrgFlow =
  (orgFlowStep: number) =>
  (state: HomePageState, answer: UpdateStepObj, flags?: LDFlags): number | undefined => {
    if (!flags) {
      return undefined;
    }
    const { service } = state;
    const { languageMatchingTherapyBh, languageMatchingTherapyB2C, languageMatchingDefault } =
      flags;
    if (languageMatchingTherapyBh || languageMatchingTherapyB2C || languageMatchingDefault) {
      const { b2bForkResult, insurancePayer } = state;
      if (b2bForkResult === 'b2c' && languageMatchingTherapyB2C && service === 'psychotherapy') {
        return undefined;
      }
      if (
        b2bForkResult === 'b2b' &&
        insurancePayer &&
        languageMatchingTherapyBh &&
        service === 'psychotherapy'
      ) {
        return undefined;
      }
      return languageMatchingDefault ? orgFlowStep : undefined;
    }
    return undefined;
  };
export const languageMatchingSkipIfNoLanguages =
  (nextStep: number): NextQuestionAction =>
  async (_state, _answer, flags): Promise<number | undefined> => {
    const { matchingLanguages } = _state;
    if (matchingLanguages?.length) {
      return undefined;
    }
    return nextStep;
  };
export const languageMatchingSelfServe =
  (languageListStepID: number, filterTypeStepID: number) =>
  (state: MapSelfServeFieldsState): SelfServeField => {
    const { responses } = state;
    const languageAnswer = responses.find((answer) => answer.stepId === languageListStepID);
    const filterTypeAnswer = responses.find((answer) => answer.stepId === filterTypeStepID);
    if (languageAnswer?.response_value && filterTypeAnswer?.response_value) {
      const languageAnswerValue = languageAnswer.response_value;
      const filterTypeAnswerValue = filterTypeAnswer.response_value;
      return {
        field: filterTypeAnswerValue || 'null',
        answerValue: languageAnswerValue || 'null',
      };
    }
    return {
      field: 'null',
      answerValue: 'null',
    };
  };
export const languageMatchingFilterHeading1 =
  (languageListStepID: number) =>
  (state: DynamicValueHomePageState, i18nTranslators: DynamicValueTranslators) => {
    const { stepAnswers } = state;
    const { tQuickmatchCommon } = i18nTranslators;
    const answerValue = stepAnswers.find(
      (answer) => answer.stepId === languageListStepID
    )?.answerValue;
    const languageAnswer = answerValue ? answerValue.response_prompt : 'English';
    const defaultCopy = `Are you willing to wait for a ${languageAnswer}-speaking provider?`;
    const heading =
      tQuickmatchCommon('languageMatchingFilter.heading1Text', defaultCopy, {
        language: languageAnswer,
      }) || defaultCopy;
    return heading;
  };
export const languageMatchingFilterHeading2 =
  (languageListStepID: number) =>
  (state: DynamicValueHomePageState, i18nTranslators: DynamicValueTranslators) => {
    const { stepAnswers } = state;
    const { tQuickmatchCommon } = i18nTranslators;
    const answerValue = stepAnswers.find(
      (answer) => answer.stepId === languageListStepID
    )?.answerValue;
    const languageAnswer = answerValue ? answerValue.response_prompt : 'English';
    const defaultCopy = `The majority of our providers speak English, which means it may take longer to connect with a ${languageAnswer}-speaking provider`;
    const heading =
      tQuickmatchCommon('languageMatchingFilter.heading2Text', defaultCopy, {
        language: languageAnswer,
      }) || defaultCopy;
    return heading;
  };

export const languageMatchingFilterButtons = (nextStepID: number): SelectButton[] => [
  {
    text: 'Yes, I’ll wait for my preferred language',
    externalTarget: null,
    internalTarget: nextStepID,
    answerValue: 'languages',
    translationKey: 'quickmatch.common:languageMatchingFilter.buttonYesText',
  },
  {
    text: 'No, I want to connect with a provider sooner, even if they speak English',
    externalTarget: null,
    internalTarget: nextStepID,
    answerValue: 'languagesSoft',
    translationKey: 'quickmatch.common:languageMatchingFilter.buttonNoText',
  },
];

export const moveCoverageSkipQuestion =
  (moveCoverageStepID: number = 100): NextQuestionAction =>
  (state, answer, flags, extraParams) => {
    // We do not want to skip this step for DTE clients who are 18+ in certain states
    if (isDteStateRestricted(state)) {
      return undefined;
    }
    const { urlFlowID = null } = extraParams || {};
    // Redirect to Step 100 when landing on flow (if !state.answer).
    if (
      !state.isCouldNotBeVerified &&
      !state.responses?.filter((response) => response.flowId === urlFlowID)?.length
    ) {
      return moveCoverageStepID;
    }
    return undefined;
  };

export const skipToStepIfDoesntMeetAvailabilityPreferencesRequirements =
  (nextStepId: number) => (state, answer, flags) => {
    if (!flags?.clientAvailabilityPreferencesExperiment?.experimentActive) {
      return nextStepId;
    }

    if (state.service !== 'psychotherapy') {
      // psych or couples
      return nextStepId;
    }

    if (
      ((state.flowID === 7 || state.partnerFlowID) && state.insuranceEligibility?.isEligible) ||
      state.accountType === 'bh'
    ) {
      // should cover all bh flows
      return undefined;
    }

    return nextStepId;
  };

export const skipToStepIfUnifiedFlowExperiment = (nextStepId: number) => (state, answer, flags) => {
  if (state.insurancePayer?.id) {
    return skipIfExperiment({
      experimentName: 'unifiedFlowForDirectB2B',
      variant: 'treatment',
      shouldTrack: false,
      nextStepId,
    })(state, answer, flags);
  }
  return undefined;
};

const skipToStepIfIsSkipDirectToBHInner = (
  state: HomePageState,
  answer,
  flags,
  nextStepId: number
) => {
  if (state.isSkipDirectToBH) {
    return nextStepId;
  }
  return undefined;
};

export const skipToStepIfIsSkipDirectToBH = (nextStepId: number) => (state, answer, flags) =>
  skipToStepIfIsSkipDirectToBHInner(state, answer, flags, nextStepId);

const skipIfOnOutageInner = (state, answer, flags, nextStepId: number) => {
  if (state.isPayerOutage) {
    return nextStepId;
  }
  return undefined;
};

export const skipIfOnOutage = (nextStepId: number) => (state, answer, flags) =>
  skipIfOnOutageInner(state, answer, flags, nextStepId);

export type insuranceConfirmationOption = {
  label: string;
  subheading?: string;
  value: string;
  target: number;
};

export const useInsuranceCoverageProps = (
  flowId: number,
  isUpdateCoverageFlow: boolean,
  isLoggedInUser: boolean
): { options: insuranceConfirmationOption[]; title: string } => {
  const flags = useFlags();
  const { t: tUpdateCoverage } = useTranslation('updateCoverage');
  const { insuranceConfirmationStep } = flags;
  const isInsuranceConfirmationStepTreatment = isLDExperimentTreatment(
    flags,
    'insuranceConfirmationStep',
    {
      isUpdateCoverage: isUpdateCoverageFlow,
    }
  );

  const defaultInsuranceCoverageProps: { options: insuranceConfirmationOption[]; title: string } = {
    options: [
      {
        label: tUpdateCoverage(
          'branchingScreen.checkCoverageLabel',
          "Yes, let's check my coverage",
          undefined
        ),
        subheading: tUpdateCoverage(
          'branchingScreen.addOrChangeInsuranceSubheading',
          'Most insured people pay a copay of $25 or less',
          undefined
        ),
        value: 'check',
        target: 15,
      },
      {
        label: tUpdateCoverage(
          'branchingScreen.payOutOfPocketLabel',
          "I'll pay out-of-pocket",
          undefined
        ),
        subheading: tUpdateCoverage(
          'branchingScreen.outOfPocketSubheading',
          'Talkspace is less expensive on average than in-person therapy',
          undefined
        ),
        value: 'direct',
        target: 1,
      },
      {
        label: tUpdateCoverage(
          'branchingScreen.useBenefitsLabel',
          "I'll use my benefit through my EAP, employer, school, or organization",
          undefined
        ),
        value: 'orgEap',
        target: 1,
      },
    ],
    title: 'Want to check how much insurance will pay for?',
  };

  const props = defaultInsuranceCoverageProps;

  // Remove EAP option for new QM users only
  if (!isLoggedInUser) {
    props.options = props.options.filter((option) => option.value !== 'orgEap');
  }

  if (isInsuranceConfirmationStepTreatment) {
    switch (flowId) {
      case insuranceConfirmationStep.flowId:
        props.options = [
          {
            label: tUpdateCoverage(
              'branchingScreen.addOrChangeInsuranceLabel',
              'Add or change insurance',
              undefined
            ),
            subheading: tUpdateCoverage(
              'branchingScreen.addOrChangeInsuranceSubheading',
              'Most insured people pay a copay of $25 or less',
              undefined
            ),
            value: 'check',
            target: 15,
          },
          {
            label: tUpdateCoverage(
              'branchingScreen.outOfPocketLabel',
              'Switch to out-of-pocket',
              undefined
            ),
            subheading: tUpdateCoverage(
              'branchingScreen.outOfPocketSubheading',
              'Talkspace is less expensive on average than in-person therapy',
              undefined
            ),
            value: 'direct',
            target: 1,
          },
          {
            label: tUpdateCoverage(
              'branchingScreen.addOrUpdateCoverage',
              'Add or update other form of coverage',
              undefined
            ),
            subheading: tUpdateCoverage(
              'branchingScreen.addOrUpdateCoverageSubheading',
              'Including EAP, employer, or organization benefits',
              undefined
            ),
            value: 'orgEap',
            target: 1,
          },
        ];

        props.title = insuranceConfirmationStep.title;

        break;
      default:
        break;
    }
  }

  return props;
};
