/**
 * Asks the user the bare minimum questions in order to determine their insurance coverage status.
 *
 * @todo - Field error states
 */
import compose from 'lodash/fp/compose';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router';
import {
  EmotionThemeProvider,
  FillContainerBanner,
  StickyContainer,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import Check from '@talkspace/react-toolkit/src/designSystems/icons/Check';
import { useTranslation } from '@talkspace/i18n';
import { useEffect, useRef } from 'react';
import useQueryPublicAdminConfigJson from '@/hooks/useQueryPublicAdminConfigJson';
import { useQuickEligibilityForm } from './hooks';
import { DiscoveryAllowedPayers, QuickEligibilityProps } from './types';
import DiscoveryEligibility from './DiscoveryEligibility';
import MemberIdEligibility from './MemberIdEligibility';
import EditInsuranceInfo from '../EditInsuranceInfo';
import {
  trackBHEligibilityCTAClick,
  trackBHEligibilityErrorSeen,
} from '../../../utils/analytics/events';

const QuickEligibility = (props: QuickEligibilityProps) => {
  const { isDiscoveryScreen = false, isEditInsuranceInfoScreen = false } = props;
  if (isDiscoveryScreen) {
    return <DiscoveryEligibility {...props} />;
  }

  if (isEditInsuranceInfoScreen) {
    return <EditInsuranceInfo {...props} isEditInsuranceInfoScreen />;
  }

  return <MemberIdEligibility {...props} />;
};

export default compose((Component) => (props: QuickEligibilityProps) => {
  const fillContainerBannerRef = useRef<HTMLDivElement>(null);
  const hasPageRendered = useRef(false);
  const { width } = useWindowWidthState();

  const location = useLocation();
  const { noMemberId, bhInsuranceEligibilityError } = useFlags();
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  const { data: allowedDiscoveryPayers } = useQueryPublicAdminConfigJson(
    'trizetto_discovery_allowed_payers',
    true
  );
  const { insurancePayer, quickEligibilityInfo, partnerFlowID, headerRef } = props;
  const discoveryPayers = (allowedDiscoveryPayers as DiscoveryAllowedPayers)?.fromDispatcher
    ? Object.keys((allowedDiscoveryPayers as DiscoveryAllowedPayers).fromDispatcher)
    : [];
  const isNoMemberIDPayer = discoveryPayers.includes(insurancePayer?.value || '');
  const urlParams = new URLSearchParams(location.search);
  const utmSource = urlParams.get('utm_source');
  const isManualSignUp = utmSource === 'manual-sign-up';
  const shouldShowDiscoveryScreen =
    !isManualSignUp &&
    noMemberId &&
    isNoMemberIDPayer &&
    !quickEligibilityInfo?.memberID &&
    !bhInsuranceEligibilityError;
  const shouldShowEditInsuranceInfoScreen =
    bhInsuranceEligibilityError && !!quickEligibilityInfo?.memberID;
  const shouldShowInNetworkBanner = bhInsuranceEligibilityError && !quickEligibilityInfo?.memberID;

  const methods = useQuickEligibilityForm(partnerFlowID, shouldShowDiscoveryScreen, insurancePayer);

  useEffect(() => {
    const formErrors = Object.keys(methods.formState.errors);
    const formErrorReasons = [] as string[];
    if (
      hasPageRendered.current &&
      !shouldShowDiscoveryScreen &&
      !shouldShowEditInsuranceInfoScreen
    ) {
      for (const [key, value] of Object.entries(methods.formState.errors)) {
        if (key === 'dob') {
          switch (value.type) {
            case 'over120':
              formErrorReasons.push('Age is over 120');
              break;
            case 'under13':
              formErrorReasons.push('Age is under 13');
              break;
            case 'dobFuture':
              formErrorReasons.push('DOB is in the future');
              break;
            default:
              formErrorReasons.push(`DOB is ${value.type}`);
              break;
          }
        } else {
          formErrorReasons.push(`${value.message}`);
        }
      }
      // fires when Continue button on BHEligibilityError Check Your Coverage screen is clicked
      trackBHEligibilityCTAClick('Continue', 'check-your-coverage', {
        errorSeen: formErrors.length > 0,
        errorReason: formErrorReasons,
      });
    }
    hasPageRendered.current = true;
  }, [methods.formState.errors, shouldShowDiscoveryScreen, shouldShowEditInsuranceInfoScreen]);

  useEffect(() => {
    let dobFormErrorReasons = '' as string;
    const dobFormError = methods.formState.errors?.dob;
    const dobFormErrorType = ['over120', 'under13', 'dobFuture'].includes(dobFormError?.type);
    if (!shouldShowDiscoveryScreen && !shouldShowEditInsuranceInfoScreen && dobFormErrorType) {
      switch (dobFormError?.type) {
        case 'over120':
          dobFormErrorReasons = 'Age is over 120';
          break;
        case 'under13':
          dobFormErrorReasons = 'Age is under 13';
          break;
        case 'dobFuture':
          dobFormErrorReasons = 'DOB is in the future';
          break;
      }
      // fires when user sees 1 of 3 specific types of dob input error on BHEligibilityError Check Your Coverage screen
      dobFormErrorReasons.length && trackBHEligibilityErrorSeen('Error Seen', dobFormErrorReasons);
    }
  }, [methods.formState.errors.dob, shouldShowDiscoveryScreen, shouldShowEditInsuranceInfoScreen]);

  return (
    <>
      {shouldShowInNetworkBanner && (
        <EmotionThemeProvider version="2.0.0">
          <StickyContainer
            ref={fillContainerBannerRef}
            topRef={headerRef}
            style={{ width: '100%', alignSelf: 'center' }}
            headerTopClipping={width > 1000 ? -18 : 0}
          >
            <FillContainerBanner
              variant="success"
              Icon={Check}
              alignCenter
              text={tQuickmatchFlow132(
                'step101.bannerText',
                'Good news! Talkspace is in-network with ',
                undefined
              ).concat(`${insurancePayer?.label}.`)}
            />
          </StickyContainer>
        </EmotionThemeProvider>
      )}
      <FormProvider {...methods}>
        <Component
          {...props}
          fillContainerBannerHeight={fillContainerBannerRef.current?.clientHeight}
          isDiscoveryScreen={shouldShowDiscoveryScreen}
          isEditInsuranceInfoScreen={shouldShowEditInsuranceInfoScreen}
        />
      </FormProvider>
    </>
  );
})(QuickEligibility) as typeof QuickEligibility;
