/**
 * Displays a screen that asks the user if they want to quickly check and see
 * if insurance will cover them.
 */
import { useEffect } from 'react';
import { ExtraHuge, StepTip, View } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isLDExperimentTreatment } from 'launchDarkly/util';
import { fromPageSource, fromPageToUpdateCoverageSource } from 'ts-analytics/types';
import { trackUpdateCoverageSourceEvent } from 'ts-analytics/mixpanel/events';
import { useLocation } from 'react-router';
import styled from '@/core/styled';
import { Questions } from './Questions';
import { WantInsuranceCheckProps } from './types';
import { useOnSelect } from './hooks';
import { useInsuranceCoverageProps } from '../../Flows/utils';

const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 35,
    };
  }),
  Container: styled(View)({
    maxWidth: 430,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 40,
  }),
  TipContainer: styled(View)({
    marginTop: 40,
    paddingLeft: 10,
    paddingRight: 10,
  }),
};

const InsuranceCheckConfirmation = ({
  step: { flowId },
  updateStep,
  isUpdateCoverageFlow,
  isLoggedInUser,
  updateCoverageRoomID,
}: WantInsuranceCheckProps) => {
  const onSelect = useOnSelect({
    updateStep,
  });
  const location = useLocation();

  const flags = useFlags();
  const { insuranceConfirmationStep } = flags;
  const isInsuranceConfirmationStepTreatment = isLDExperimentTreatment(
    flags,
    'insuranceConfirmationStep',
    {
      isUpdateCoverage: isUpdateCoverageFlow,
    }
  );
  const insuranceConfirmationProps = useInsuranceCoverageProps(
    flowId,
    isUpdateCoverageFlow,
    isLoggedInUser
  );

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const fromPage = (search.get('fromPage') as fromPageSource) || '';
    if (updateCoverageRoomID && fromPage) {
      const source = fromPageToUpdateCoverageSource[fromPage];
      trackUpdateCoverageSourceEvent(updateCoverageRoomID, source);
    }
  }, [location.search, updateCoverageRoomID]);

  if (isInsuranceConfirmationStepTreatment) {
    switch (flowId) {
      case insuranceConfirmationStep.flowId:
        return (
          <Styled.Container>
            <Styled.Heading>{insuranceConfirmationProps.title}</Styled.Heading>
            <Questions questions={insuranceConfirmationProps.options} onSelect={onSelect} />
          </Styled.Container>
        );
      default:
        break;
    }
  }

  return (
    <Styled.Container>
      <Styled.Heading>{insuranceConfirmationProps.title}</Styled.Heading>
      <Questions questions={insuranceConfirmationProps.options} onSelect={onSelect} />
      <Styled.TipContainer>
        <StepTip tip="didYouKnow" tipTextContainerStyle={{ marginLeft: 6 }} />
      </Styled.TipContainer>
    </Styled.Container>
  );
};

export default InsuranceCheckConfirmation;
