import { FunctionComponent, useState } from 'react';
import { StripeProvider } from 'stripe/stripeContext';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { PaymentDetails as PaymentDetailsInterface, SessionModality } from 'ts-frontend/types';
import RegisterWithBHCopayView from './RegisterWithBHCopayView';
import { RegisterWithBHCopayProps } from './types';
import { getSetupIntent } from '@/Helpers/apiService';
import { getUseStripeLinkFlagValue } from '@/Helpers/adminConfig';
import registerWithBHCopayHandler, {
  useEmailFromSessionStorage,
} from './registerWithBHCopayHelpers';
import useBackButton from '../../StepLayout/hooks/useBackButton';
import { useHomePageState } from '@/Components/HomePage';

const RegisterWithBHCopay: FunctionComponent<RegisterWithBHCopayProps> = (props) => {
  const {
    copayCents = 0,
    service,
    redirectFrom,
    existingUserEmail,
    isNoMatches,
    organizationFlowID,
    partnerFlowID,
    step,
    isVideoOnlyPlan,
    insurancePayer,
    collectReferralSourceOnSignUp,
    allowedModalities,
    responses,
    flowId,
    headerRef,
    memberAvailability,
    skipCreditCard,
  } = props;
  const { returningUserData } = useHomePageState();
  const { onPress: onBackButtonPress } = useBackButton(step);
  const sessionModality: SessionModality | undefined = responses.find(
    (response) => response.response_self_serve?.field === 'sessionModality'
  )?.response_value as SessionModality | undefined;

  const copayCentsAsNumber = Number(copayCents);
  const [email, setEmail] = useState(existingUserEmail || '');
  const [requestPending, setRequestPending] = useState(false);
  const [subStep, setSubStep] = useState(service !== 'psychiatry' ? 1 : 0);
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState('');
  const {
    clientAvailabilityPreferencesExperiment: { variant: memberAvailabilityVariant },
  } = useFlags();

  // despite redirectFrom being of type number, ts evaluates finalFlowID: number | undefined which is just flat out wrong hence the need to wrap in Number()
  const finalFlowID = Number(redirectFrom || organizationFlowID || partnerFlowID);

  const onLoadStripeLink = async () => {
    const res = await getSetupIntent({ currency: 'USD' });
    return res;
  };

  const onStripeLinkError = (error: any) => setCheckoutErrorMessage(error.message);

  const resetError = () => setCheckoutErrorMessage('');

  const handleSubmit = ({ token, userEmail }: { token?: string; userEmail: string }) => {
    setCheckoutErrorMessage('');
    setRequestPending(true);
    registerWithBHCopayHandler({
      token,
      stepProps: props,
      setRequestPending,
      setCheckoutErrorMessage,
      finalFlowID,
      email: userEmail,
      memberAvailabilityVariant,
      collectReferralSourceOnSignUp,
      returningUserData,
    });
  };

  const handleSubmitPayment = (
    token: string,
    paymentDetails: PaymentDetailsInterface | undefined,
    userEmail: string
  ) => {
    handleSubmit({ token, userEmail });
  };

  const handleSubmitSkipPayment = (userEmail: string) => {
    handleSubmit({ userEmail });
  };

  const handleBackButtonPress = () => {
    if (subStep === 0 || service !== 'psychiatry') {
      onBackButtonPress();
    } else {
      setSubStep(subStep - 1);
    }
  };

  useEmailFromSessionStorage(setEmail);

  return (
    <StripeProvider getUseStripeLinkFlag={getUseStripeLinkFlagValue}>
      <RegisterWithBHCopayView
        isNoMatches={isNoMatches}
        copayCents={copayCentsAsNumber}
        email={email}
        requestPending={requestPending}
        checkoutErrorMessage={checkoutErrorMessage}
        finalFlowID={finalFlowID}
        subStep={subStep}
        setSubStep={setSubStep}
        handleBackButtonPress={handleBackButtonPress}
        handleSubmitPayment={handleSubmitPayment}
        onLoadStripeLink={onLoadStripeLink}
        onStripeLinkError={onStripeLinkError}
        resetError={resetError}
        isVideoOnlyPlan={isVideoOnlyPlan}
        insurancePayer={insurancePayer}
        sessionModality={sessionModality}
        allowedModalities={allowedModalities}
        flowId={flowId}
        service={service}
        headerRef={headerRef}
        memberAvailability={memberAvailability}
        skipCreditCard={skipCreditCard}
        handleSubmitSkipPayment={handleSubmitSkipPayment}
      />
    </StripeProvider>
  );
};

export default RegisterWithBHCopay;
