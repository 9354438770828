import { useEffect, useState } from 'react';
import { Button, ExtraHuge, Standard, View, Link, CheckList } from '@talkspace/react-toolkit';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/events';
import { getBHPlanReviewByPlan, parseTextInBullets } from '@/utils/reviewPlan';
import { CoveredDetailsModal } from './CoveredDetailsModal';
import { CoveredProps } from './types';
import { useHomePageState } from '@/Components/HomePage';
import BHPayerDetails from './BHPayerDetails';
import { useEstimatedCost } from '../../../hooks';
import ReactFrameService from '@/utils/reactFrame/ReactFrameService';

const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 25,
    };
  }),
  View: styled(View)(() => {
    return {
      maxWidth: 430,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'left',
      paddingTop: 48,
    };
  }),
  Divider: styled(View)(({ theme: { colors } }) => {
    return {
      borderColor: colors.permaMystic,
      borderStyle: 'solid',
      borderTop: 1,
      borderWidth: 1,
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
    };
  }),
  MainContainer: styled(View)({
    textAlign: 'left',
    display: 'block',
  }),
  Button: styled(Button)({
    width: '100%',
    fontWeight: 'bold',
  }),
  DetailsLink: styled(Link)(({ theme: { colors } }) => {
    return {
      color: colors.permaBlueStoneNew,
      marginTop: 16,
      marginBottom: 16,
      fontSize: 14,
      fontWeight: 'bold',
      display: 'block',
    };
  }),
  EstimatedCost: styled(ExtraHuge)(({ theme: { colors } }) => {
    return {
      color: colors.permaBlueStoneNew,
      marginTop: 10,
      fontSize: 22,
    };
  }),
  Disclaimer: styled(Standard)(() => {
    return {
      marginTop: 16,
      fontWeight: 400,
      fontSize: 13,
    };
  }),
  SubTitle: styled(Standard)(() => {
    return {
      variant: 'standardBoldTSBlack',
      marginTop: 16,
      marginBottom: 16,
      fontWeight: 'bold',
    };
  }),
  Standard: styled(Standard)(() => {
    return {
      marginTop: 16,
      fontWeight: 400,
    };
  }),
};

const Covered = ({
  insuranceDetails,
  step,
  updateStep,
  onClick,
  isDirectFlow = false,
}: CoveredProps) => {
  const { maxCost } = useEstimatedCost();
  const { isReturningUserFlow } = useHomePageState();

  useEffect(() => {
    trackEvent('Covered by Health Insurance Seen', {
      stepId: step.id,
      stepName: 'Covered by Health insurance',
      funnelName: 'QuickMatch',
      category: '',
    });
  }, [step.id]);

  const [detailsModalIsVisible, setDetailsModalIsVisible] = useState(false);
  const { isVideoOnlyPlan } = useHomePageState();

  const showDetailsModal = () => {
    ReactFrameService.instance().hideFrameHeader();
    setDetailsModalIsVisible(true);
  };
  const hideDetailsModal = () => {
    ReactFrameService.instance().showFrameHeader();
    setDetailsModalIsVisible(false);
  };

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const planReview = getBHPlanReviewByPlan(isVideoOnlyPlan);
  const bullets =
    parseTextInBullets({
      bullets: planReview.bullets,
      bulletsTranslationKeys: planReview.bulletsTranslationKeys,
      tQuickmatchCommon,
    }) || [];
  const importantToKnow = `Total cost may reach up to $${maxCost}/session depending on your deductible status. Visit your insurer's website or call the number on your insurance card to confirm.`;

  const quickEligibilityContinue = () => {
    trackEvent('Covered by Health Insurance CTA', {
      stepId: step.id,
      stepName: 'Covered by Health insurance',
      funnelName: 'QuickMatch',
      category: '',
    });

    const quickStatusAction = isReturningUserFlow ? 'returningUser' : 'continueWithInsurance';
    updateStep(step.id, {
      quickStatusAction,
    });
  };

  return (
    <>
      <Styled.View>
        <Styled.Heading>{planReview.titleText}</Styled.Heading>
        <BHPayerDetails insuranceDetails={insuranceDetails} isDirectFlow={isDirectFlow} />
        <Styled.MainContainer>
          <Styled.SubTitle>What you get</Styled.SubTitle>
          <CheckList isBH={planReview.isBH} items={bullets} />
          {!!planReview.importantToKnow && (
            <>
              <Styled.SubTitle>Important to know</Styled.SubTitle>
              <Styled.Standard>{importantToKnow}</Styled.Standard>
            </>
          )}
          {!!planReview.disclaimerText && (
            <Styled.Disclaimer>{planReview.disclaimerText}</Styled.Disclaimer>
          )}
          <Styled.DetailsLink onClick={showDetailsModal}>More details</Styled.DetailsLink>
        </Styled.MainContainer>
        <Styled.Button dataQa="continueWithInsurance" onClick={onClick || quickEligibilityContinue}>
          Continue
        </Styled.Button>
      </Styled.View>
      <CoveredDetailsModal
        hideDetailsModal={hideDetailsModal}
        isVisible={detailsModalIsVisible}
        modalDetails={planReview.modalDetails}
      />
    </>
  );
};

export default Covered;
