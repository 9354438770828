import sessionStorage from '@/core/storage/sessionStorage';

export const SESSION_STORAGE_MEMBER_DETAILS_KEY = 'TSQM_MemberDetails';

export const getSessionStorageValueFromJson = (storageKey: string, itemKey: string) => {
  const obj = sessionStorage.getItem(storageKey);
  if (!obj) return null;
  const parsed = JSON.parse(obj);
  if (!parsed) return null;
  return parsed[itemKey];
};

export const getSessionStorageValuesFromJson = (
  storageKey: string,
  itemKeyArray: string[]
): any => {
  const obj = sessionStorage.getItem(storageKey);
  if (!obj) {
    return [];
  }
  const parsed = JSON.parse(obj);
  if (!parsed) {
    return [];
  }
  const res = {};
  for (const itemKey of itemKeyArray) {
    const value = parsed[itemKey];
    if (value) {
      res[itemKey] = value;
    }
  }
  return res;
};

// Only updates the session storage if the value is different from the current value and new value isn't null or undefined
export const upsertSession = (
  key:
    | 'TSQM_MemberDetails'
    | 'TSQM_BasicInformation'
    | 'TSQM_DateOfBirth'
    | 'TSQM_RegistrationInformation'
    | 'TSQM_InsuranceInformation',
  update: Record<string, any>,
  allowNull: boolean = false
) => {
  const existingValues = JSON.parse(sessionStorage.getItem(key) || '{}');
  const newValues = {
    ...existingValues,
    ...Object.entries(update).reduce((acc, [k, v]) => {
      if (allowNull || (v !== null && v !== undefined)) acc[k] = v;
      return acc;
    }, {}),
  };
  sessionStorage.setItem(key, JSON.stringify(newValues));
};
